<template>
  <div class="aimain">
    <!-- <div class="apptype">
      <div class="typetitle"><img src="@/assets/images/aicenter/apptype.png" />应用列表</div>
      <div class="typeitem"><img src="@/assets/images/aicenter/wacz.png" />文案创作</div>
      <div class="typeitem"><img src="@/assets/images/aicenter/wars.png" />文章润色</div>
      <div class="typeitem"><img src="@/assets/images/aicenter/yxch.png" />营销策划</div>
      <div class="typeitem"><img src="@/assets/images/aicenter/xlzx.png" />心理咨询</div>
    </div> -->
    <div class="backcenterbtn" v-if="indaiflag != 'center'" @click="backcenter"><el-button type="primary"
        icon="el-icon-arrow-left">返回应用库</el-button>
    </div>
    <div class="aimainbox" v-if="indaiflag == 'center'">
      <div class="appmain" v-if="aitype == 'furniture'">
        <div class="furinbox" @click="changefaiflag">
          <img src="./img/web/lb-aitxt.png" />
        </div>
        <div class="furinbox aiyy" @click="gogyAI"></div>
      </div>
      <div class="appmain" v-else-if="aitype == 'xzy'">
        <!-- <div class="furinbox" @click="changefaiflag"><img src="@/assets/images/juicon.png" /></div> -->
        <div class="aibottxt">
          <div class="txttit">晓知因，产业智能化的专家，新质生产力的引擎！</div>
          <!-- <div class="txtcont">您也可以上传素材资料，创建与众不同的专属助理机器人</div> -->
          <!-- <div class="btnitem">
            <img src="@/assets/images/aicenter/xtalk.png" />&nbsp;&nbsp;立即对话
          </div>
          <div class="btnitem">
            创建机器人
          </div> -->
        </div>
        <div class="typetop">
          <div :class="type == 'all' ? 'typeitem checked' : 'typeitem'" @click="typeclick('all')">
            全部
          </div>
          <div :class="type == 'zhuanye' ? 'typeitem checked' : 'typeitem'" @click="typeclick('zhuanye')">
            产业应用
          </div>
          <div :class="type == 'tongyong' ? 'typeitem checked' : 'typeitem'" @click="typeclick('tongyong')">
            通用应用
          </div>
        </div>

        <div class="cjbox" v-if="type == 'zhuanye' || type == 'all'">
          <div class="stitle">
            <div class="titlepoint"></div>
            产业应用
          </div>
          <div class="webitem" v-for="item in Object.keys(aiboxs)" :key="item" v-if="item != 'xzy'">
            <div class="witemtop">
              <div class="tleft">
                <img :src="aiboxs[item].gifimg" alt="" />
              </div>
              <div class="tright">
                <div class="rtitle">{{ aiboxs[item].name }}</div>
                <div class="rcont">{{ aiboxs[item].hitxttitle }}</div>
              </div>
            </div>
            <div class="witembtm">
              <!-- <div class="wbtnitem">
              <img src="@/assets/images/addsame.png" alt="">创建同款
            </div> -->
              <div class="wbtnitem" v-if="aiboxs[item].subscribe && aiboxs[item].name != '晓知因'"
                @click="gotalk(item, aiboxs[item].name)">使用
              </div>
              <!-- <div class="wbtnitem" @click="click(item.writetype)">使用</div> -->
              <div class="wbtnitem" v-if="!aiboxs[item].subscribe && aiboxs[item].name != '晓知因'"
                @click="ding(aiboxs[item].name)">订阅</div>
            </div>
          </div>
          <div class="webitem">
            <div class="witemtop">
              <div class="tleft">
                <img src="https://www.airer.com/hcQybOssFile/2024/06/28/e45d5cdcce874ed3bfbfde06e1db1a38.png" alt="" />
              </div>
              <div class="tright">
                <div class="rtitle">襄城化工园区</div>
                <div class="rcont">我是襄城化工园区AI机器人</div>
              </div>
            </div>
            <div class="witembtm">
              <div class="wbtnitem" @click="
      opentab('http://xiangchengai.web.guanyingis.com/#/home')
      ">
                使用
              </div>
            </div>
          </div>
          <div class="webitem">
            <div class="witemtop">
              <div class="tleft"> <img
                  src="https://www.airer.com/hcQybOssFile/2024/06/28/20de1378e1684a3ca5dc674de35e1454.png" alt="" />
              </div>
              <div class="tright">
                <div class="rtitle">产业大脑</div>
                <div class="rcont">我是产业大脑AI机器人</div>
              </div>
            </div>
            <div class="witembtm">
              <div class="wbtnitem" @click="opentab('http://industry-ai.web.shuzhiren.com')">
                使用
              </div>
            </div>
          </div>
          <div class="webitem">
            <div class="witemtop">
              <div class="tleft">
                <img src="https://www.airer.com/hcQybOssFile/2024/06/28/20de1378e1684a3ca5dc674de35e1454.png" alt="" />
              </div>
              <div class="tright">
                <div class="rtitle">电子信息</div>
                <div class="rcont">我是电子信息AI机器人</div>
              </div>
            </div>
            <div class="witembtm">
              <div class="wbtnitem" @click="gogyAI">使用</div>
              <!-- <div class="wbtnitem" @click="click(item.writetype)">使用</div> -->
              <!-- <div class="wbtnitem" @click="ding">订阅</div> -->
            </div>
          </div>
        </div>
        <div class="cjbox" v-if="type == 'tongyong' || type == 'all'">
          <div class="stitle">
            <div class="titlepoint"></div>
            通用应用
          </div>
          <div class="webitem" v-for="item in tylist" :key="item">
            <div class="witemtop">
              <div class="tleft tyitem">
                <img :src="item.headimg" alt="" />
              </div>
              <div class="tright">
                <div class="rtitle">{{ item.name }}</div>
                <div class="rcont">{{ item.sname }}</div>
              </div>
            </div>
            <div class="witembtm">
              <!-- <div class="wbtnitem">
              <img src="@/assets/images/addsame.png" alt="">创建同款
            </div> -->
              <div class="wbtnitem" @click="click(item.writetype)">使用</div>
              <!-- <div class="wbtnitem" @click="ding">订阅</div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="nodata" v-else>
        <AiBot :aiboxitem="aiboxitem" :basetalklist="basetalklist" :newinput="newinput" @talklistchange="talklistchange"
          @clear="clearNewInput" />
      </div>
    </div>
    <Industry v-else-if="indaiflag == 'ind'" />
    <Write v-else-if="indaiflag == 'write'" />
    <Len v-else-if="indaiflag == 'len'" />

    <el-dialog title="订单详情" :modal-append-to-body="false" :visible="payVisible" :before-close="handlePayClose"
      width="861px">
      <div class="pay-content">
        <div class="titlebox">
          <div class="tleft">{{ orderData.title }}产业机器人</div>
          <div class="tright">
            <div class="ordertime">
              <span>下单时间</span>{{ getformattime }}
            </div>
            <!-- <div class="ordertime">
              <span>订单编号</span>{{ getformattime }}
            </div> -->
          </div>
        </div>
        <div class="selection-wrapper">
          <div class="oditembox">
            <div class="cleft">方案名称</div>
            <div class="cright">产业机器人及对应知识库</div>
          </div>
          <div class="oditembox">
            <div class="cleft">使用期限</div>
            <div class="cright">1年</div>
          </div>
          <div class="oditembox">
            <div class="cleft">方案到期时间</div>
            <div class="cright">支付成功日起365天</div>
          </div>
          <div class="orderpricebox">
            <div class="cleft">订单总额</div>
            <div class="cright">￥{{ getTotal }}</div>
          </div>
        </div>
        <div class="pay-detail">
          <div class="qrcode-wrapper">
            <div class="pay-type-item wxpay" :class="[payType == 'wx' && 'active']" @click="payTypeChange('wx')">
              <span class="pay-icon"></span>
              <div class="text">微信支付</div>
            </div>
            <div class="pay-type-item alipay" :class="[payType == 'ali' && 'active']" @click="payTypeChange('ali')">
              <span class="pay-icon"></span>
              <div class="text">支付宝支付</div>
            </div>
          </div>
          <div class="qrcode-detail">
            <div class="left">
              <div class="title">应付金额</div>
              <div class="money-num">￥{{ getTotal }}</div>
              <div class="tip">支付即视为您同意《晓知因服务协议》和《晓知因授权许可协议》</div>
            </div>
            <div class="right">
              <div class="top-tip"><i class="el-icon-warning"></i>购买须知</div>
              <div class="pay-button" @click="handlePay()">立即支付</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import Industry from './aiapp/industry.vue'
import AiBot from './aibotLxy.vue'
import Len from './aicenter/newfx.vue'
import Write from './aicenter/write.vue'
import { baseUrl } from '@/axios/baseUrl'
import { dyrobootPriceInfo, aliPayPage, wxPay, getWxPayStatus } from '@/api/pay'
import { formatTime } from '@/utils/format'
export default {
  name: '',
  components: { Industry, AiBot, Write, Len },
  data(props) {
    return {
      aiboxs: props.aibox || null,
      aitype: '',
      resultdata: '',
      resultdata2: '',
      userInfo: [],
      type: 'all',
      timer: null,
      payVisible: false,
      orderData: {
        robotId: null,
        title: '',
        robotPrice: 1,
        robotNum: 1
      },
      payType: 'wx',
      payingOrderNo: null,
      tylist: [{
        headimg: require('./img/aicenter/hyjy.png'),
        name: '帮你写',
        sname: '你的高效协作助手',
        writetype: 'hy'
      }, {
        headimg: require('./img/aicenter/znxz.png'),
        name: '帮你学',
        sname: '你的专属学习伙伴',
        writetype: 'xz'

      }, {
        headimg: require('./img/aicenter/yxhb.png'),
        name: '营销海报',
        sname: '让创意超越期待',
        writetype: 'hb'

      }, {
        headimg: require('./img/aicenter/ppt.png'),
        name: '智能PPT写作',
        sname: '让汇报变得更简单一点',
        writetype: 'ppt'

      }],
      indaiflag: 'center'
    }
  },

  props: {
    aibox: { type: Object, default: () => [] },
    dingsuccess: { type: Function },
    //绿小影传值
    aiboxitem: { type: Object, default: () => [] },
    talklistchange: { type: Function },
    basetalklist: { type: Object, },
    newinput: { type: Object, }
    //
  },
  onLoad(options) {
    console.log(options, this.aiboxs)
  },
  computed: {
    getformattime() {
      return formatTime(new Date())
    },
    getTotal() {
      const sum = this.orderData.robotPrice
      return String((sum / 100)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (this.userInfo.phone == '18970800394') {
      this.indaiflag = 'ind'
    } else {
      this.aitype = this.$route.query.type ? this.$route.query.type : 'xzy';
      this.indaiflag = this.aitype == 'tombarthite' ? 'ind' : 'center'


      this.fetchdata()
      console.log(this.aiboxs)
    }

  },
  watch: {
    aibox(n) {
      console.log(n, 'aiboxdata')
      this.aiboxs = n;
    },
    // '$route': {
    //   handler: function (to, from) {
    //     // 路由发生变化时的处理逻辑
    //     // this.aitype = this.$route.query.type ? this.$route.query.type : 'xzy';
    //     // this.indaiflag = this.aitype == 'tombarthite'
    //   },
    //   // 设置为深度监听
    //   deep: true
    // }
  },
  methods: {
    gogyAI() {
      this.indaiflag = 'ind'
    },
    typeclick(e) {
      this.type = e
    },
    changefaiflag() {
      window.open('http://jiaju-ai.web.shuzhiren.com/#/furniture')
    },
    gotalk(e, name) {
      console.log(e, name)
      if (name == '南康鲁小班') {
        window.open('http://lxb2.web.shuzhiren.com/#/home')
      } else {
        this.$router.push('/home?type=' + e)
        setTimeout(() => {
          history.go(0)
        }, 20);
      }

    },
    async ding(name) {
      // 去支付 获取机器人数量
      const res = await dyrobootPriceInfo("晓知因-产业机器人", name)
      if (res.code === 200) {
        const robotData = res.data
        this.orderData.robotId = robotData.id
        this.orderData.title = robotData.title
        this.orderData.robotPrice = robotData.price
        this.payVisible = true
      }
      console.log(this.orderData)
    },
    opentab(e) {
      window.open(e)
    },
    backcenter() {
      this.indaiflag = 'center'
    },
    fetchdata() {

      const that = this;

      that.resultdata = `会议纪要
        我将给你提供一段会议纪要，帮我整理成更规范的形式，包括会议的基本信息、目的和议题、内容摘要、决议和行动事项、附件和参考信息
        以下是会议纪要的内容!
        【会议主题:月度销售策略会
        时间:5月20日，14:30-15:30
        参与者:苗总、黄先生、郑小姐、徐先生
        内容简述:
        研讨下个季度销售计划。
        分析上月销售数据，提出改进措施
        讨论客户反馈，制定客户服务优化方案。
        待办事项:
        黄先生:下周一前完成新推广计划草案。
        郑小姐:整理客户反馈，提出具体改善建议。】`
      that.resultdata2 = `Ai写作
        请根据我给出的故事设定完成一篇[引人入胜]、[奇幻生动]的[超短篇小说],，要求情节[跌宕起伏]，有明显的冲突我的设定是:一千年以后，地球资源日渐枯竭，正当科学家们以为研制的星际版诺亚方舟能带领人类突破困局时，他们意外发现所在的地球只不过是高等生物的一颗玩具星球，这时一部分科学家产生了自我怀疑`

    },
    click(e) {
      if (e === 'hy') {
        this.indaiflag = 'write'
      } else if (e == 'xz') {
        this.indaiflag = 'len'
      } else {
        this.$message.info('正在开发')
      }
    },

    hy() {
      this.$emit('listenToChildEvent', this.resultdata)
    },
    xz() {
      this.$emit('listenToChildEvent2', this.resultdata2)
    },
    handlePayClose() {
      this.payVisible = false;
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      if (this.payingOrderNo) this.payingOrderNo = null
    },
    payTypeChange(type) {
      this.payType = type;
    },
    async handlePay() {
      const dz_url = window.location.href.split('#')[0]
      const products = `${this.orderData.robotId},${this.orderData.robotNum}`
      if (this.payType === 'wx') {
        const res = await wxPay({ products: products })
        if (res.code === 200) {
          this.payingOrderNo = res.data.orderNo
          const orderNo = res.data.orderNo
          const qrUrl = res.data.codeUrl
          if (this.payingOrderNo) this.getPayStatus()
          window.open(`${dz_url}#/wxpay?orderNo=${orderNo}&amount=${this.getTotal}&qrUrl=${encodeURIComponent(qrUrl)}`)
        }

      } else {
        let params = {
          products: products,
          return_url: `${dz_url}#/payResult?payType=ali`
        }
        aliPayPage(params).then(res => {
          console.log(res)
          if (res.code === 200) {
            const { data } = res
            this.payingOrderNo = data.orderNo
            if (this.payingOrderNo) this.getPayStatus()
            // document.write(data.formStr)
            let divForm = document.getElementsByTagName('divform')
            if (divForm.length) {
              document.body.removeChild(divForm[0])
            }
            const div = document.createElement('divform');
            div.innerHTML = data.formStr;
            document.body.appendChild(div);
            document.forms[0].setAttribute('target', '_blank')
            document.forms[0].submit();
          }
        })
      }
    },
    getPayStatus() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.getOrderStatus()
      }, 3 * 1000)
    },
    async getOrderStatus() {
      const res = await getWxPayStatus(this.payingOrderNo)
      if (res.code === 200 && res.success) {
        this.$message({
          type: 'success',
          message: '支付成功!'
        });
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        this.handlePayClose()
        this.$emit('dingsuccess')
      } else {
        this.getPayStatus()
      }
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
      this.payingOrderNo = null
    }
  }


}
</script>
<style lang='less' scoped>
.aimain {
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  display: flex;
  position: relative;

  .backcenterbtn {
    position: fixed;
    z-index: 3;
    left: 319px;
    top: 10px;
  }

  .apptype {
    width: 250px;
    background-color: white;
    margin-left: -20px;
    padding: 20px 20px 0 40px;
    box-shadow: 5px 0px 14px 0px rgba(28, 34, 54, 0.06);

    .typetitle {
      display: flex;
      font-size: 20px;
      padding-bottom: 10px;
      border-bottom: 2px solid #1c223610;
      margin-bottom: 20px;
      align-items: center;

      img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
    }

    .typeitem {
      color: #1c2236;
      font-size: 18px;
      display: flex;
      align-items: center;
      margin: 20px 0;

      letter-spacing: 0px;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }

  .aimainbox {
    width: 100%;
    height: 100%;
  }

  .appmain {
    padding: 40px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    .aibottxt {
      padding: 20px 50px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .txttit {
        font-size: 42px;
        font-family: YouSheBiaoTiHei;
        color: #0389f8;
        line-height: 45px;
      }

      .txtcont {
        font-size: 18px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #fff;
        line-height: 25px;
        margin-top: 20px;
        width: 100%;
      }

      .btnitem {
        margin-top: 20px;
        width: 136px;
        height: 40px;
        background: linear-gradient(270deg, #9953ff 0%, #53a9ff 100%);
        box-shadow: 0px 7px 10px 0px rgba(28, 34, 54, 0.06);
        border-radius: 5px;
        backdrop-filter: blur(10px);
        display: flex;
        font-size: 16px;
        color: white;
        justify-content: center;
        align-items: center;
        margin-right: 20px;

        img {
          width: 30px;
          height: 30px;
          margin: 5px;
        }
      }
    }

    .typetop {
      width: 100%;
      height: 50px;
      background: #fff;
      border-radius: 7px;
      display: flex;
      margin: 15px 20px;

      .typeitem {
        height: 42px;
        width: 150px;
        border-radius: 5px;
        line-height: 42px;
        text-align: center;
        cursor: pointer;
        margin: 4px;
        font-size: 18px;
        background: #fff;
        color: #727295;
      }

      .checked {
        text-decoration: underline;
        text-underline-offset: 8px;
        color: #0389f8;
        font-weight: bold;
      }
    }

    .cjbox {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .stitle {
        width: 100%;
        padding-left: 25px;
        margin-top: 10px;
        font-size: 20px;
        color: #333;
        display: flex;

        .titlepoint {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #0389f8;
          margin: 8px;
        }
      }

      .webitem {
        width: calc(33% - 40px);
        min-width: 300px;
        height: 150px;
        margin: 20px;
        padding: 10px;
        background: #fff;
        border-radius: 6px;
        backdrop-filter: blur(10px);

        .witemtop {
          width: 100%;
          height: 100%;
          display: flex;

          .tleft {
            height: 100%;

            img {
              /*background: linear-gradient(to bottom, #9953FF, #53A9FF);
              border-radius: 50%;*/
              height: calc(100% - 20px);
              margin: 10px;
              padding: 2px 6px;
            }
          }

          .tyitem {
            img {
              height: 80px;
            }
          }

          .tright {
            width: calc(100% - 60px);
            padding: 10px 20px;

            .rtitle {
              font-size: 26px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              color: #333;
            }

            .rcont {
              font-size: 16px;
              color: #abafdb;
              margin-top: 5px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }

        .witembtm {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          padding: 10px 20px;
          position: absolute;
          right: 0px;
          bottom: 10px;

          .wbtnitem {
            display: flex;
            font-size: 14px;
            color: #a3a4a8;
            margin-right: 20px;
            cursor: pointer;
            border: 1px solid #0389f8;
            border-radius: 10px;
            padding: 4px 10px;
            color: #0389f8;

            img {
              height: 20px;
              margin-right: 10px;
            }
          }
        }
      }
    }

    .furinbox {
      height: 150px;
      width: 30%;
      margin: 20px;
      cursor: pointer;
      background-image: url('./img/web/lb-aibg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 50%;
      }
    }

    .aiyy {
      background-image: url('./img/web/gyai.png');
    }
  }

  .nodata {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #acacac;
    letter-spacing: 4px;
  }
}

/deep/ .el-dialog {
  background: linear-gradient(275deg, #181B40 0%, #181A37 100%);
  box-shadow: 3px 3px 3px rgba(17, 19, 38, 0.82);
  border-radius: 13px;

  .pay-content {
    width: 100%;
    height: 100%;
    padding: 0 40px;

    .titlebox {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .tleft {
        font-size: 24px;
        color: #C3C7F5
      }

      .tright {
        font-size: 16px;
        color: #D6D6D6;

        .ordertime {
          margin: 10px 0;
        }

        span {
          color: #8286AF;
          padding-right: 10px;
        }
      }
    }

    .selection-wrapper {
      background-color: #121443;
      gap: 40px;
      margin-top: 10px;
      padding: 20px;

      .oditembox,
      .orderpricebox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        margin-bottom: 30px;

        .cleft {
          color: #999EC9;
          text-align: left;
        }

        .cright {
          color: #ff009d;
          text-align: right;
        }
      }

      .orderpricebox {
        margin-top: 30px;
        padding: 20px 0;
        border-top: 1px solid #283472;
        margin-bottom: 0;

      }


    }

    .pay-detail {
      width: 100%;
      height: 200px;
      background: linear-gradient(275deg, #181B40 0%, #181A37 100%);
      box-shadow: -1px -1px 2px #1E213B, 3px 3px 3px #13162D;
      border-radius: 5px;
      padding: 20px 16px;
      display: flex;
      align-items: center;

      .qrcode-wrapper {
        flex-shrink: 0;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;

        .pay-type-item {
          width: 170px;
          height: 64px;
          padding: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: -1px -1px 2px #1E213B, 3px 3px 3px #13162D;
          cursor: pointer;
          border-radius: 10px;

          .pay-icon {
            width: 38px;
            height: 38px;
            flex-shrink: 0;
            margin-right: 10px;
          }

          .text {
            display: inline-block;
            width: 98px;
            flex-shrink: 0;
            text-align: center;
            font-family: Alibaba PuHuiTi;
            font-weight: 500;
            font-size: 20px;
            color: #898DB6;
            white-space: nowrap;
          }

          &.active {
            border: 1px solid #FC009B;

            .text {
              color: #FC009B;
            }
          }

          &.wxpay {
            .pay-icon {
              background: url('./img/pay/wx.png') no-repeat center/cover;
            }
          }

          &.alipay {
            .pay-icon {
              background: url('./img/pay/alipay.png') no-repeat center/cover;
            }
          }
        }

      }

      .qrcode-detail {
        position: relative;
        width: calc(100% - 137px - 20px);
        height: 100%;
        display: flex;
        justify-content: space-between;

        .left {
          width: calc(100% - 180px);
          position: relative;

          .tip {
            font-family: Alibaba PuHuiTi;
            font-size: 16px;
            color: #3E426C;
          }
        }

        .right {
          position: relative;
          width: 160px;
          height: 100%;
          margin-left: 20px;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          .top-tip {
            width: 100%;
            height: 20px;
            text-align: right;
            font-family: Alibaba PuHuiTi;
            font-size: 16px;
            color: #3E426C;
            position: absolute;
            top: 0;
            right: 0px;
          }

          .pay-button {
            width: 160px;
            height: 54px;
            background: linear-gradient(275deg, #AE0EF0 0%, #F81B9A 100%);
            box-shadow: -3px -3px 3px #1E213B;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Alibaba PuHuiTi;
            font-weight: 500;
            font-size: 18px;
            color: #FFFFFF;
          }
        }

        .title {
          font-family: Alibaba PuHuiTi;
          font-weight: 500;
          font-size: 18px;
          color: #898DB6;
          margin-bottom: 10px;
        }

        .money-num {
          font-family: Alibaba PuHuiTi;
          font-weight: 500;
          font-size: 44px;
          color: #FF009D;
          margin-bottom: 10px;
        }

      }
    }
  }
}

/deep/ .el-dialog__title,
/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #FF009D !important;
  font-size: 22px
}
</style>
