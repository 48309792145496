<template>
  <div class="caimain">
    <div class="headnamebox">
      <div class="head">
        <div class="addicon">
          <img :src="headimg == 'default.png' ? require('./img/morenhead.png') : headimg" />
        </div>
      </div>
      <div class="zdy">
        <p>自定义头像<span>*</span></p>
        <div class="cbox">
          <div class="changebtn" @click="triggerUpload">更换<img src="./img/reload.png" /></div>
          <input type="file" style="display: none" ref="fileInput" @change="handleFileChange" />
          <!-- <div class="aibtn">AI生成<img src="./img/ai.png" /></div> -->
        </div>

      </div>
      <div class="name">
        <p>名称<span>*</span></p>
        <el-input v-model="botname" placeholder="请输入你的小助手名称~" />
      </div>
    </div>
    <div class="botitembox">
      <div class="titlebox">
        <div class="title">机器人设定<span>*</span></div>
        <div class="contents">
          设定后提供用户自定义AI机器人角色的能力，使其具备独特的个性、功能定位和交互风格，回答更加精准。
          <p>例：机器人设定为专业客服，则机器人更会从专业客服的角度回答用户提出的问题。</p>
        </div>
        <!-- <div class="aibtn">AI生成<img src="./img/ai.png" /></div> -->
      </div>
      <el-input type="textarea" v-model="botsd" rows="4" placeholder="请输入你的小助手角色设定~" />
    </div>
    <div class="botitembox">
      <div class="titlebox">
        <div class="title">机器人介绍<span>*</span></div>
        <div class="contents">
          设定后用户在首次进入产品智问界面显示角色介绍
        </div>
        <!-- <div class="aibtn">AI生成<img src="./img/ai.png" /></div> -->
      </div>
      <el-input type="textarea" rows="4" v-model="botjs" placeholder="请输入你的小助手介绍~" />
    </div>
    <div class="botitembox">
      <div class="titlebox">
        <div class="title">对话衔接轮数</div>
        <div class="contents">
          根据设置的衔接规则，机器人进行上下文衔接对话
        </div>
        <div class="aibtn"></div>
      </div>
      <el-input-number v-model="num" @change="handleChange" :min="1" :max="10" label="文字衔接次数"></el-input-number>
    </div>
    <div class="btngroup">
      <div class="btnitem back" @click="skip">返 回</div>
      <div class="btnitem save" @click="create">保 存</div>
    </div>
    <el-dialog :title="botdetail.name ? '修改完成' : '创建完成'" :modal-append-to-body="false" :visible="successflag"
      :before-close="handleClose" width="500px">
      <div class="textbox">
        恭喜{{ botdetail.name ? '修改' : '创建' }}完成！现在就去训推中心开启专属知识库训练吧~
      </div>
      <div class="btnbox">
        <div class="btnitem goxt" @click="goxt">前去上传</div>
        <div class="btnitem skip" @click="skip">跳过</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { robootCreate, robootEdit } from '@/api/bot'
export default {
  name: '',
  components: {},
  data(props) {
    return {
      num: 1,
      headimg: 'default.png',
      botname: '',
      botsd: '',
      botjs: '',
      botdetail: props.botdetail || [],
      successflag: false

    }
  },
  mounted() {

    if (this.botdetail.profilePicture) {
      this.headimg = this.botdetail.profilePicture
      this.botname = this.botdetail.name
      this.botsd = this.botdetail.setting
      this.botjs = this.botdetail.introduce
      this.num = this.botdetail.talkConnectNumber
    }
  },
  props: {
    botdetail: { type: Object, default: () => [] },
  },
  watch: {
    botdetail(n) {
      console.log(n, 'aiboxdata')
      this.botdetail = n;
    }
  },
  methods: {
    handleChange(e) {
      this.num = e
    },
    create() {
      let params = []
      if (this.botdetail.id) {
        params = {
          id: this.botdetail.id,
          profilePicture: this.headimg,
          introduce: this.botjs,
          name: this.botname,
          setting: this.botsd,
          talkConnectNumber: this.num,
        }
        robootEdit(params).then(res => {
          console.log(res)
          if (res.code == 200) {
            this.successflag = true;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
      } else {
        params = {
          profilePicture: this.headimg,
          introduce: this.botjs,
          name: this.botname,
          setting: this.botsd,
          talkConnectNumber: this.num,
        }
        robootCreate(params).then(res => {
          console.log(res)
          if (res.code == 200) {
            this.successflag = true;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
      }


    },
    goxt() {
      this.$emit('goxt', '')
    },
    skip() {
      this.$emit('back', '')
    },
    handleClose() {
      this.successflag = false
    },
    triggerUpload() {
      this.$refs.fileInput.click(); // 触发input的点击事件
    },
    handleFileChange(event) {
      const file = event.target.files[0]; // 获取选中的文件
      // 处理文件上传的逻辑...
      let xhr = new XMLHttpRequest();
      const formdata = new FormData();
      formdata.append('file', file)
      xhr.open('POST', '/api/common/uploadImage', true);
      xhr.send(formdata);
      const that = this;
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          this.$message({
            type: 'success',
            message: '上传成功!'
          });
          const res = JSON.parse(xhr.responseText);
          that.headimg = res.data
          console.log(res)
        }
      }
    }
  }
}
</script>
<style lang='less' scoped>
.caimain {
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  padding: 50px;
  position: relative;

  .headnamebox {
    width: 100%;
    display: flex;
    padding: 20px;

    .head {
      .addicon {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        img {
          color: #ABAFDB;
          width: 50px;
          height: 50px;
        }
      }
    }

    .zdy {
      margin: 10px 20px;
      font-size: 18px;
      color: #333;

      span {
        color: #FF009D;
      }

      .cbox {
        display: flex;
        align-items: center;
        justify-content: center;

        .changebtn {
          background: #fff;
          border: 1px solid #0389f8;
          color: #333;
          margin-top: 10px;
          font-weight: bold;
          padding: 4px 20px;
          border-radius: 6px;
          text-align: center;
          cursor: pointer;
          display: flex;

          img {
            width: 15px;
            height: 15px;
            margin: 5px;
            margin-left: 15px;
          }
        }

        .aibtn {
          margin-top: 10px;
          padding: 8px;
          color: #333;
          display: flex;
          cursor: pointer;

          img {
            width: 15px;
            height: 15px;
            margin: 5px;
            margin-left: 15px;
          }
        }
      }

    }

    .name {
      margin: 10px 20px;
      font-size: 18px;
      color: #333;

      span {
        color: #FF009D;
      }

      p {
        margin-bottom: 10px;
      }

      /deep/ .el-input__inner {
        background: #fff;
        width: 300px;
        border-radius: 9px;
        border: 0;
        font-size: 18px;
        color: #333;
      }
    }
  }

  .botitembox {
    margin: 20px 20px;
    font-size: 18px;
    color: #333;

    .titlebox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;

      .title {
        width: 150px;

        span {
          color: #FF009D;
        }
      }

      .contents {
        font-size: 14px;
        color: #ffffff60;
        text-align: left;
        width: calc(80% - 150px);
      }

      .aibtn {
        width: 20%;
        margin-top: 10px;
        padding: 8px;
        color: #FF009D;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;

        img {
          width: 15px;
          height: 15px;
          margin: 5px;
          margin-left: 15px;
        }
      }


    }



    /deep/ .el-textarea__inner {
      background: #fff;
      width: 100%;
      margin-top: 10px;
      border-radius: 9px;
      border: 0;
      padding: 10px 20px;
      font-size: 18px;
      color: #333
    }

    /deep/ .el-input__inner {
      background: #fff;
      border-radius: 9px;
      border: 0;
      margin: 10px;
      padding: 10px 20px;
      color: #333;
      width: 180px;
      margin-left: 60px;
      font-size: 30px;
      height: 50px
    }

    /deep/ .el-input-number__increase,
    /deep/ .el-input-number__decrease {
      background: #fff;
      margin: 10px 0;
      border: 0;
      color: #333;
      height: 50px;
      line-height: 50px;
      font-size: 24px;
      width: 50px;
      border-radius: 10px;
    }

    /deep/ .el-input-number {
      width: 300px;
    }
  }

  .btngroup {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 20px;
    right: 50px;

    .btnitem {
      width: 170px;
      height: 50px;
      color: #333;
      font-size: 22px;
      text-align: center;
      line-height: 50px;
      background: #fff;
      border-radius: 9px;
      margin: 0 20px;
      cursor: pointer;
    }

    .back {
      color: #747474;
    }
  }

  /deep/ .el-dialog {
    background: #fff;
    box-shadow: 3px 3px 3px rgba(17, 19, 38, 0.82);
    border-radius: 13px;

    .textbox {
      width: 100%;
      height: 100px;
      line-height: 100px;
      font-size: 16px;
      color: #FF009D;
      background-color: #121443;
      justify-content: space-between;
      text-align: center
    }

    .btnbox {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;

      .btnitem {
        width: 100px;
        height: 38px;
        color: #FF009D;
        border: 1px solid #FF009D;
        font-size: 16px;
        text-align: center;
        line-height: 35px;
        background: linear-gradient(275deg, #181B40 0%, #181A37 100%);
        border-radius: 3px;
        margin: 0 20px;
        cursor: pointer;
      }

      .skip {
        color: #0e6dee;
        border: 1px solid #0e6dee;
      }
    }

  }

  /deep/ .el-dialog__title,
  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: #FF009D !important;
    font-size: 22px
  }
}
</style>
