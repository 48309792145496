// 接口
import axios from '@/axios'
import { baseUrl } from '@/axios/baseUrl'
// 创建一个axios实例
axios.defaults.timeout = 600000;
// 新增问题
export const addQuestion = (data) => axios.post(baseUrl + '/api/trainingPromotionCenter/reviewQABatch', data)

// 修改问题
export const editQuestion = (data) => axios.put(baseUrl + '/queapi/question', data)

// 问题列表
export const getQuestion = (data) => axios.get(baseUrl + '/queapi/question/page', data)

// 文件列表
export const getFilelist = (data) => axios.get(baseUrl + '/queapi/question_tmp/file', data)

//上传文本到文档集
export const upTxttoFilelist = (data) => axios.post(baseUrl + '/queapi/file/add_text_to_doc?' + data)

//上传文本到文档集
export const upWebtoFilelist = (data) => axios.post(baseUrl + '/queapi/file/add_html_to_doc?' + data)

//批量转存
export const saveQuestions = (data) => axios.post(baseUrl + '/queapi/question_tmp/save_questions?' + data)

//获取微信公众平台文
export const saveWxQuestions = (data) => axios.post(baseUrl + '/queapi/file/select_wechat_official_account_article?' + data)

// 知识库列表
// export const getKlfList = () => axios.post(baseUrl + '/api/trainingPromotionCenter/knowledgeBaseList')
export const getKlfLists = (data) => axios.post(baseUrl + '/api/trainingPromotionCenter/knowledgeBaseCategoryList', data)


// 所有知识库统计
export const getAllKlgCount = (data) => axios.post(baseUrl + '/api/trainingPromotionCenter/statKnowledgeAllData', data)

//新建知识库
export const createKlg = (data) => axios.post(baseUrl + '/api/trainingPromotionCenter/createKnowledgeBase', data)

//统计指定知识库
export const getKlgCountbyname = (data) => axios.post(baseUrl + '/api/trainingPromotionCenter/statKnowledgeData', data)

//自建问答集分页
export const getQuelist = (data) => axios.post(baseUrl + '/api/trainingPromotionCenter/questionAnswerPage', data)
    
//自建问答集新增
export const addzjQuestion = (data) => axios.post(baseUrl + '/api/trainingPromotionCenter/createQuestionAnswer', data)

//自建问答集修改
export const editzjQuestion = (data) => axios.post(baseUrl + '/api/trainingPromotionCenter/editQuestionAnswer', data)

//自建问答集删除
export const delzjQuestion = (data) => axios.post(baseUrl + '/api/trainingPromotionCenter/deleteQuestionAnswerBatch', data)

    
//自建文档分页
export const getzjFilelist = (data) => axios.post(baseUrl + '/api/trainingPromotionCenter/documentPage', data)

//自建新增文档
export const savezjFile = (data) => axios.post(baseUrl + '/api/trainingPromotionCenter/createDocument', data)

    //自建生成问答列表
export const createFileQuelist = (data) => axios.post(baseUrl + '/api/trainingPromotionCenter/createDocumentQAList', data, {timeout: 1000 * 600})

    //自建新增文档
export const delzjFile = (data) => axios.post(baseUrl + '/api/trainingPromotionCenter/deleteDocumentBatch', data)

//获取生成的问答列表
export const getCreateFileQueList = (data) => axios.post(baseUrl + '/api/trainingPromotionCenter/documentReviewQAList', data)

//修改知识库
export const editKlg = (data) => axios.post(baseUrl + '/api/trainingPromotionCenter/editKnowledgeBase', data)
//修改知识库
export const delKlg = (data) => axios.post(baseUrl + '/api/trainingPromotionCenter/deleteKnowledgeBase', data)
    //修改知识库
export const addtxttodoc = (data) => axios.post(baseUrl + '/api/trainingPromotionCenter/add_text_to_doc', data)
 //修改知识库
export const addhemltodoc = (data) => axios.post(baseUrl + '/api/trainingPromotionCenter/add_html_to_doc', data)
    //修改知识库
export const getwxdatalist = (data) => axios.post(baseUrl + '/api/trainingPromotionCenter/select_wechat_official_account_article', data)
//自建知识库排序
export const saveuserklgsort = (data) => axios.post(baseUrl + '/api/trainingPromotionCenter/knowledgeBaseSort', data)


//下载分析结果
export const downloadfxfile = (data) => axios.get(baseUrl + '/queapi/robot_writing/pdf/' + data)
//获取机器人提示词
export const gethicontent = (data) => axios.get(baseUrl + '/queapi/robot_tip?'+ data)
///robot_tip
//获取机器人提示词
export const edithicontent = (data) => axios.put(baseUrl + '/queapi/robot_tip', data)
//下脑图
export const getxmind = (data) => axios.get(baseUrl + '/queapi/robot_writing/xmind/' + data)
//下脑图
export const getxmindbyurl = (data) => axios.get(baseUrl + '/queapi/robot_writing/xmind_by_url?' + data)
//下ppt
export const getppt = (data) => axios.get(baseUrl + '/queapi/robot_writing/ppt/' + data)
//获取帮我写列表
export const getlearnlist = (data) => axios.get(baseUrl + '/queapi/file/oss?' + data)
//删除帮我写列表
export const dellearnlist = (data) => axios.delete(baseUrl + '/queapi/file/oss/'+ data)

//追问
export const getzwlist = (data) => axios.post(baseUrl + '/klgapi/tool/get_related_question', data)






