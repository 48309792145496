<template>
  <div class="writebox">
    <div class="left" v-if="writetype" :style="{ width: writetype ? '100%' : 'calc(100% - 275px)' }">
      <!-- <div class="wtopbox">
        <div :class="type == item.type ? 'topitem ted' : 'topitem'" @click="typeclick(item)"
          v-for="(item, i) in level1list" :key="i">
          <img :src="type == item.type ? item.imgs[0] : item.imgs[1]" />
          {{ item.text }}
        </div>

      </div> -->
      <div class="detailbox">
        <!-- levellist[type] -->
        <div class="level1box" v-for="(bitem, bi) in level1list" :key="bi">
          <p>{{ bitem.text }}</p>
          <div :class="stype == item ? 'vitem ved' : 'vitem'" v-for="(item, i) in levellist[bitem.type]" :key="i"
            :style="{ backgroundImage: stype == item ? `url(${levelbglist[bitem.type][1]})` : `url(${levelbglist[bitem.type][0]})` }"
            @click="stypeclick(item, bitem)">
            <div class="vtop">
              <img
                :src="stype == item ? require('@/assets/images/write/top.png') : require('@/assets/images/write/toped.png')" />
              {{ item }}
            </div>
            <div class="gobox" @click="godetail(item)">
              <img src="@/assets/images/write/go.png" alt="" srcset="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="leftbox" v-else>
      <div class="left1">
        <div class="backbox" @click="backlist">
          <img src="@/assets/images/write/backgzt.png" />
        </div>
        <div class="mainbox">
          <el-collapse accordion v-model="cctype" @change="tabchange">
            <el-collapse-item :name="bi + 1" v-for="(bitem, bi) in level1list" :key="bi">
              <template slot="title">
                <div :class="type == bitem.type ? 'topitem ted' : 'topitem'" @click="typeclick(bitem)">

                  {{ bitem.text }}
                </div>
              </template>
              <div :class="stype == item ? 'mitem med' : 'mitem'" v-for="(item, i) in levellist[bitem.type]" :key="i"
                @click="stypeclick(item, bitem)">{{ item }}
                <img v-if="stype == item" src="@/assets/images/write/leftchecked.png" alt="" srcset="">
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div class="left2" v-if="type == 'jg'">
        <div class="l2left">
          <div class="wtopbox">
            <div class="topitem ted">
              <img :src="level1list.filter(item => { return item.type == type })[0].imgs[0]" />
              {{ level1list.filter(item => { return item.type == type })[0].text }}
            </div>
            <el-button type="text" icon="el-icon-refresh" @click="reset('公文')">重置</el-button>
          </div>
          <!-- <div class="l2item">
            <div class="itemtiitle">机关公文类型</div>
            <el-select v-model="cdata.type" placeholder="请选择公文类型">
              <el-option v-for="item in levellist[type]" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div> -->
          <div class="l2item">
            <div class="itemtiitle">标题</div>
            <el-input placeholder="示例 召开年度总结大会" type="textarea" v-model="cdata.title">
            </el-input>
          </div>
          <div class="l2item">
            <div class="itemtiitle">事由</div>
            <el-input placeholder="示例 为规范重大行政决定程序，推进科学民主依法决策" type="textarea" v-model="cdata.detail">
            </el-input>
          </div>
          <div class="l2item">
            <div class="itemtiitle">
              <div>AI生成</div>
              <div><el-button @click="createwrite" type="primary" size="small">立即生成</el-button></div>
            </div>
            <el-input placeholder="AI生成内容" type="textarea" rows="8" v-model="resultdatalist">
            </el-input>
          </div>
          <div class="l2item">
            <div class="itemtiitle">文件号</div>
            <el-input placeholder="示例 853" type="number" v-model="cdata.code">
            </el-input>
          </div>
          <div class="l2item">
            <div class="itemtiitle">发文单位</div>
            <el-input placeholder="示例 应急管理厅" type="textarea" v-model="cdata.send">
            </el-input>
          </div>
          <div class="l2item">
            <div class="itemtiitle">主送机关</div>
            <el-input placeholder="示例 厅机关各处室，厅属各单位" type="textarea" v-model="cdata.receive">
            </el-input>
          </div>
          <div class="l2item">
            <div class="itemtiitle">印发时间</div>
            <el-date-picker clearable v-model="cdata.date" value-format="yyyy-MM-dd" type="date" placeholder="请选择日期">
            </el-date-picker>
          </div>
          <div class="l2item" v-if="stype == '通知'">
            <div class="itemtiitle">附注</div>
            <el-input placeholder="示例 本通知请各单位认真执行，并提交相关报告" type="textarea" v-model="cdata.note">
            </el-input>
          </div>
          <div class="l2item" v-if="stype == '通知'">
            <div class="itemtiitle">承办室处</div>
            <el-input placeholder="示例 河南省体育厅" type="text" v-model="cdata.undertake">
            </el-input>
          </div>
          <div class="l2item" v-if="stype == '通知'">
            <div class="itemtiitle">经办人</div>
            <el-input placeholder="示例 经办人" type="text" v-model="cdata.operator">
            </el-input>
          </div>
          <div class="l2item">
            <div class="itemtiitle">参考文件</div>
            <el-upload class="upload-demo" action="" :before-upload="filechange" :file-list="fileList">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </div>
          <div class="l2item end"> <el-button style="width: 100%;" :disabled="isloading" @click="createfile"
              type="primary">立即生成</el-button>
          </div>

        </div>
        <div class="l2right">
          <div class="loadingbox" v-if="!iswrite">
            <img src="@/assets/images/write/writingbg.png" alt="">
            <div class="createbtn">←开始生成</div>
          </div>
          <div class="redfilebox" id="redfilebox" v-else ref="pdf">
            <div class="redtitlebox">
              <div class="rtleft">
                <div class="rtt1">
                  <div v-for="item in (cdata.send)" :key="item">
                    {{ item }}
                  </div>
                </div>
                <!-- <div class="rtt2">河南省体育局</div> -->
              </div>
            </div>
            <div class="rednumbox">豫应急办〔2024〕{{ cdata.code }}号</div>
            <div class="redline">
              <div class="leftline" />
              <!-- <div class="ricon"></div> -->
              <div class="rightline" />
            </div>
            <div class="contenttitle">{{ listinfo ? loglist[listinfo].title : cdata.title }}</div>
            <!-- <div class="contentsend">{{ cdata.receive }}：</div> -->
            <vue-markdown id="resultbox" :render="customRender" :source="resultdatalist"
              class="custom-markdown markdown2"></vue-markdown>
            <div class="contentbottom">
              <div class="cbleft">
                {{ listinfo ? loglist[listinfo].main_organ : cdata.receive }}
              </div>
              <div class="cbright">
                {{ listinfo ? getformatTime(loglist[listinfo].create_time.substr(0, 10)) : getformatTime(new Date())
                }}印发
              </div>
            </div>
          </div>
          <div class="aitipbox" v-if="iswrite">当前为预览格式，可编辑，下载文档查看最终效果</div>
          <div class="downloadpdf" v-if="iswrite" @click="downloadpdf">下载Word</div>
        </div>
      </div>
      <div class="left2" v-else-if="type == 'gw'">
        <div class="l2left">
          <div class="wtopbox">
            <div class="topitem ted">
              <img :src="level1list.filter(item => { return item.type == type })[0].imgs[0]" />
              {{ level1list.filter(item => { return item.type == type })[0].text }}
            </div>
            <el-button type="text" icon="el-icon-refresh" @click="reset('公文')">重置</el-button>
          </div>
          <!-- <div class="l2item">
            <div class="itemtiitle">常用公文类型</div>
            <el-select v-model="cdata.type" placeholder="请选择公文类型">
              <el-option v-for="item in levellist[type]" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div> -->
          <div class="l2item">
            <div class="itemtiitle">发言标题</div>
            <el-input placeholder="示例 尧贤厅长在安全生产视频会议中的发言提纲" type="textarea" v-model="cdata.title">
            </el-input>
          </div>
          <div class="l2item">
            <div class="itemtiitle">发言对象</div>
            <el-input placeholder="示例 调查统计司" type="textarea" v-model="cdata.receive">
            </el-input>
          </div>
          <div class="l2item">
            <div class="itemtiitle">发言概述</div>
            <el-input placeholder="示例 按照会议安排，我就安全生产十五条措施进行简要汇报..." type="textarea" v-model="cdata.detail">
            </el-input>
          </div>
          <div class="l2item">
            <div class="itemtiitle">
              <div>AI生成</div>
              <div><el-button @click="createwrite2" type="primary" size="small">{{ resultdatalist.length == 0 ? '立即生成' :
      '重新生成' }}</el-button></div>
            </div>
            <el-input placeholder="AI生成内容" type="textarea" rows="8" v-model="resultdatalist">
            </el-input>
          </div>
          <div class="l2item">
            <div class="itemtiitle">参考文件</div>
            <el-upload class="upload-demo" action="" :before-upload="filechange" :file-list="fileList">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </div>
          <div class="l2item end"> <el-button style="width: 100%;" :disabled="isloading" @click="createfile"
              type="primary">立即生成</el-button>
          </div>

        </div>
        <div class="l2right">
          <div class="loadingbox" v-if="!iswrite">
            <img src="@/assets/images/write/writingbg.png" alt="">
            <div class="createbtn">←开始生成</div>
          </div>
          <div v-else class="resultbox">
            <div class="title">{{ this.cdata.title }}</div>
            <vue-markdown id="resultbox" :source="resultdatalist" class="custom-markdown"></vue-markdown>
          </div>

          <!-- <div class="wordcopybtn" v-if="iswrite" @click="copytxt">复制</div> -->
          <div class="aitipbox" v-if="iswrite">当前为预览格式，可编辑，下载文档查看最终效果</div>
          <div class="downloadpdf" v-if="iswrite" @click="downloadpdf">下载Word</div>
        </div>
      </div>
      <div class="left2" v-else-if="type == 'zf'">
        <div class="l2left">
          <div class="wtopbox">
            <div class="topitem ted">
              <img :src="level1list.filter(item => { return item.type == type })[0].imgs[0]" />
              {{ level1list.filter(item => { return item.type == type })[0].text }}
            </div>
            <el-button type="text" icon="el-icon-refresh" @click="reset('公文')">重置</el-button>
          </div>
          <!-- <div class="l2item">
            <div class="itemtiitle">执法文书类型</div>
            <el-select v-model="cdata.type" placeholder="请选择公文类型">
              <el-option v-for="item in levellist[type]" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div> -->
          <div class="xzcfbox">
            <div class="l2item" v-for="(item, i) in xzcf" :key=i>
              <div class="itemtiitle">{{ item.name }} <span style="color: red;">*</span></div>
              <el-input :placeholder="item.plat" type="textarea" v-model="cdata[item.value]">
              </el-input>
            </div>

            <div class="l2item">
              <div class="itemtiitle">参考文件</div>
              <el-upload class="upload-demo" action="" :before-upload="filechange" :file-list="fileList">
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </div>
            <div class="l2item end"> <el-button style="width: 100%;" :disabled="isloading" @click="createwritexz"
                type="primary">立即生成</el-button>
            </div>
          </div>
        </div>
        <div class="l2right">
          <div class="loadingbox" v-if="!iswrite">
            <img src="@/assets/images/write/writingbg.png" alt="">
            <div class="createbtn">←开始生成</div>
          </div>
          <vue-markdown id="resultbox" v-else :source="resultdatalist" class="custom-markdown"></vue-markdown>
          <div class="wordcopybtn" v-if="iswrite" @click="copytxt">复制</div>
        </div>
      </div>
      <div class="left2" v-else-if="type == 'ai'">
        <div class="l2left">
          <div class="wtopbox">
            <div class="topitem ted">
              <img :src="level1list.filter(item => { return item.type == type })[0].imgs[0]" />
              {{ level1list.filter(item => { return item.type == type })[0].text }}
            </div>
            <el-button type="text" icon="el-icon-refresh" @click="reset('ppt')">重置</el-button>
          </div>
          <div class="l2item">
            <div class="itemtiitle">标题</div>
            <el-input placeholder="输入标题我们会根据输入的标题生成一份大纲" type="textarea" v-model="cdata.ppttitle">
            </el-input>
          </div>
          <div class="l2item" style="text-align: right;"> <el-button size="small" type="primary"
              @click="outlineCreate">生成大纲</el-button>
          </div>
          <div class="l2item">
            <div class="reppt"><el-button type="text" icon="el-icon-refresh"
                @click="outlineCreate(true)">重新生成</el-button>
            </div>
            <div class="itemtiitle">大纲</div>
            <el-input id="dgid" placeholder="输入标题生成大纲~" type="textarea" :autosize="{ minRows: 8, maxRows: 17 }"
              v-model="cdata.pptcontent">
            </el-input>
          </div>
          <div class="l2item">
            <div class="itemtiitle">参考文件</div>
            <el-upload class="upload-demo" action="" :before-upload="filechange" :file-list="fileList">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </div>
          <div class="l2item end"> <el-button style="width: 100%;" :disabled="pptCreating" @click="createppt"
              type="primary">{{ pptCreating ? '正在生成中...' : '立即生成' }}</el-button>
          </div>

        </div>
        <div class="l2right">
          <div class="loadingbox pptloading" v-if="!iswrite">
            <img src="../img/pptbg.png" alt="">
          </div>
          <div class="pptbox" v-if="iswrite && pptFileItem.ppt_url">
            <iframe class='myiframe' :src="pptFileItem.ppt_url" frameborder="">
            </iframe>
          </div>
          <el-button type="text" v-else icon="el-icon-loading">文件加载中..</el-button>
          <div class="pptdownloadbtn" v-if="pptFileItem.ppt_url && roleid != 3"
            @click="downloadFile(pptFileItem.ysppturl)">下载
          </div>
        </div>
      </div>
    </div>
    <div class="right" v-if="stype && !writetype">
      <div class="rtop">
        <img src="@/assets/images/write/rtop.png" />
      </div>
      <div class="rlistbox" v-if="loglist.length > 0">
        <div :class="listinfo == i.toString() ? 'ritem red' : 'ritem'" v-for="(item, i) in loglist" :key="i"
          @click="detailgo(item, i)">
          <div class="textbox"> {{ item.title || item.query }}</div>
          <div class="gobox" v-if="roleid == 1">
            <el-tooltip placement="left" effect="light">
              <div slot="content"><el-button type="text" size="mini" icon="el-icon-delete-solid"
                  @click="dellog(item.id)">删除</el-button></div>
              <span>···</span>
            </el-tooltip>
          </div>
        </div>
      </div>
      <div class="listnodata" v-else>暂无记录</div>
    </div>
  </div>
</template>
<script>
import storage from '@/utils/localStorage'
import VueMarkdown from 'vue-markdown'
import { getlist, getppt, delwritelog, ppt2pdf, getwortebyid } from '@/api/write'
import { baseUrl } from '@/axios/baseUrl'
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
import qs from 'qs'
export default {
  name: '',
  components: { VueMarkdown },
  data(props) {
    return {
      type: 'jg',
      roleid: storage.getItem('userInfo'),
      ptypeindex: props.index || 0,
      cctype: 1,
      stype: '请示',
      fileList: [],
      iswrite: false,
      writetype: false,
      level1list: [{
        type: 'jg',
        text: '常用公文',
        imgs: [require('@/assets/images/write/jged.png'), require('@/assets/images/write/jg.png')]
      }, {
        type: 'gw',
        text: '规范性文件',
        imgs: [require('@/assets/images/write/gwed.png'), require('@/assets/images/write/gw.png')]
      }
        //   , {
        //   type: 'zf',
        //   text: '执法文书',
        //   imgs: [require('@/assets/images/write/zfed.png'), require('@/assets/images/write/zf.png')]
        // }, {
        //   type: 'yj',
        //   text: '应急演练',
        //   imgs: [require('@/assets/images/write/yjed.png'), require('@/assets/images/write/yj.png')]
        // }, {
        //   type: 'ai',
        //   text: 'AI工具',
        //   imgs: [require('@/assets/images/write/gjed.png'), require('@/assets/images/write/gj.png')]
        //   }
      ],
      levellist: {
        jg: ['请示', '报告', '通知', '会议纪要', '意见', '函'],
        gw: ['领导讲话', '情况报告', '工作总结', '述职报告', '调研报告', '简报', '典型材料', '会议须知', '活动方案', '接待安排', '个人总结'],
        zf: ['询问笔录', '现场检查勘验笔录', '案件调查总结报告', '行政处罚事先告知书', '行政处罚听证公告', '行政处罚听证报告', '行政处罚决定书', '行政处罚文书送达回证', '行政处罚案件结案审批'],
        yj: ['演练脚本'],
        ai: ['PPT制作'],
      },
      newlevellist: ['领导讲话', '情况报告', '工作总结', '述职报告', '调研报告', '简报', '典型材料', '会议须知', '活动方案', '接待安排', '个人总结'],
      levelbglist: {
        jg: [require('@/assets/images/write/jgbg.png'), require('@/assets/images/write/jgbged.png')],
        gw: [require('@/assets/images/write/gwbg.png'), require('@/assets/images/write/gwbged.png')],
        zf: [require('@/assets/images/write/zfbg.png'), require('@/assets/images/write/zfbged.png')],
        yj: [require('@/assets/images/write/yjbg.png'), require('@/assets/images/write/yjbged.png')],
        ai: [require('@/assets/images/write/gjbg.png'), require('@/assets/images/write/gjbged.png')]
      },
      resultdatalist: '',
      eventSource: null,
      cdata: {
        type: '',
        title: '',
        detail: '',
        send: '',
        receive: '',
        pptcontent: '',
        ppttitle: '',
        post: '',
        work: '',
        source: '',
        pptContentId: '',
        operator: '',
        undertake: '',
        note: '',
        date: '',
        code: ''
      },
      xzcf: [{
        name: '文号',
        plat: '示例 XX(XX)行政决书[2024]00230号',
        type: 'textarea',
        value: 'xznum'
      }, {
        name: '行政执法单位名称',
        plat: '示例 XX市公安局',
        type: 'textarea',
        value: 'xzname'
      }, {
        name: '案由',
        plat: '示例 扰乱公共场所秩序',
        type: 'textarea',
        value: 'xzay'
      }, {
        name: '违法事实描述',
        plat: '示例 XX日XX时 殴打他人',
        type: 'textarea',
        value: 'xzms'
      }, {
        name: '法律依据',
        plat: '示例 治安处罚法XX条',
        type: 'textarea',
        value: 'xzflyj'
      }, {
        name: '证据形式与证明内容',
        plat: '示例 群众举报殴打他人',
        type: 'textarea',
        value: 'xzzjxs'
      }, {
        name: '行政处罚裁量基准的具体内容',
        plat: '示例 从轻处罚',
        type: 'textarea',
        value: 'xzcf'
      }, {
        name: '违法行为严重程度',
        plat: '示例 轻微',
        type: 'textarea',
        value: 'xzwfxw'
      }, {
        name: '处罚决定',
        plat: '示例 行政拘留七日并处罚500元',
        type: 'textarea',
        value: 'xzcfjd'
      }, {
        name: '罚款缴纳方式',
        plat: '示例 现金',
        type: 'textarea',
        value: 'xzfkfs'
      }, {
        name: '逾期不缴纳罚款的处理',
        plat: '示例 双倍罚金',
        type: 'textarea',
        value: 'xzyqcl'
      }],
      isloading: false,
      listinfo: '',
      level1txt: '常用公文',
      loglist: [],
      showloglist: [],
      pptFileItem: {},
      pptCreating: false,
      ppturltopdfurl: null,
      talkid: null,
    }
  },
  mounted() {
    this.getloglist()
    this.type = this.level1list[this.ptypeindex].type
    this.xzcf.forEach(item => {
      this.$set(this.cdata, item.value, '');
    })
  },
  props: {
    index: { type: [Number, String] },
  },
  computed: {

  },
  watch: {
    type(newData, oldData) {
      if (newData != oldData) {
        if (this.cdata.ppttitle) this.cdata.ppttitle = ''
        if (this.cdata.pptcontent) this.cdata.pptcontent = ''
        if (this.cdata.pptContentId) this.cdata.pptContentId = ''
        if (this.pptFileItem.id) this.pptFileItem = {}
      }
    },
    writetype(newData, oldData) {
      if (newData != oldData) {
        if (this.cdata.ppttitle) this.cdata.ppttitle = ''
        if (this.cdata.pptcontent) this.cdata.pptcontent = ''
        if (this.cdata.pptContentId) this.cdata.pptContentId = ''
        if (this.pptFileItem.id) this.pptFileItem = {}
      }
    }
  },
  methods: {
    backlist() {
      this.writetype = true
    },

    godetail(e) {
      this.stype = e
      let num = 0;
      this.level1list.forEach((item, i) => {
        if (item.type == this.type) {
          num = i + 1
        }
      })
      this.cctype = [num]
      this.writetype = false
      setTimeout(() => {
        this.getloglist()
      }, 200);

    },
    tabchange(e) {
      console.log(e)
      this.level1txt = this.level1list[e - 1].text
      this.type = this.level1list[e - 1].type
      this.stypeclick(this.levellist[this.level1list[e - 1].type][0], this.level1list[e - 1])
      // thi
    },
    copytxt() {
      const textArea = document.createElement('textarea');
      setTimeout(() => {
        textArea.value = this.resultdatalist;
        document.body.appendChild(textArea);
        textArea.select();

        try {
          const successful = document.execCommand('copy');
          if (successful) {
            this.$message.success('复制成功');
          } else {
            this.$message.error('复制失败');
          }
        } catch (err) {
          this.$message.error('复制出现错误', err);
        }

        document.body.removeChild(textArea);
      }, 300);
    },
    reset(type) {
      this.listinfo = null
      if (type == '公文') {
        this.cdata = {
          type: '',
          title: '',
          detail: '',
          send: '',
          receive: '',
          pptcontent: '',
          ppttitle: '',
          post: '',
          work: '',
          source: '',
          pptContentId: '',
          operator: '',
          undertake: '',
          note: '',
          date: '',
          code: ''
        }
        this.fileList = []
        this.iswrite = false;
        this.resultdatalist = ''
      } else if (type == 'ppt') {
        this.type = 'ai'
        this.stype = 'PPT写作'
        this.cdata.ppttitle = ''
        this.cdata.pptcontent = ''
        this.fileList = []
        this.iswrite = false;
        this.pptFileItem = {}
      }
    },
    detailgo(item, i) {
      console.log(item)
      this.listinfo = i
      if (item.source == '公文') {
        if (item.type.includes('执法文书')) {
          if (item.query && item.query.includes(';')) {
            item.query.split(';').forEach((sitem, i) => {
              if (item.query.split(';').length - 1 == i) {
                return
              }
              const data = sitem.split(': ')
              this.cdata[this.xzcf[i].value] = data[1]
            })
          } else {
            this.xzcf.forEach(item => {
              this.$set(this.cdata, item.value, '');
            })
          }
          this.fileList = [{
            name: '文件',
            url: item.url
          }]
          this.iswrite = true;
          this.writetype = false
          this.resultdatalist = item.content
        } else {
          this.cdata = {
            type: item.type,
            title: item.title,
            detail: item.subject,
            send: item.issuing_unit,
            receive: item.main_organ,
            operator: item.operator,
            undertake: item.undertake,
            note: item.note,
            date: item.date,
            year: item.date.substr(0, 4),
            code: item.code
          }
          if (item.url) {
            this.fileList = [{
              name: '文件',
              url: item.url
            }]
          } else {
            this.fileList = null
          }

          this.iswrite = true;
          this.writetype = false
          this.resultdatalist = item.content
        }

      } else if (item.source == 'PPT大纲') {
        console.log(item)
        this.cdata.ppttitle = item.query
        this.cdata.pptcontent = item.content.replace(/\\n/g, '\n').replace(/\*/g, '\n').replace(/\#/g, '\n').replace(/\`/g, '\n')
        this.pptFileItem = {}
        ppt2pdf(qs.stringify({ file: item.ppt_url })).then(pdfres => {
          console.log('转换过的pdf', pdfres)
          if (pdfres.code == 200 && pdfres.success) {
            this.fileList = [{
              name: '文件',
              url: pdfres.data.pdf
            }]
            this.iswrite = true;
            this.pptFileItem = {
              ysppturl: item.ppt_url,
              ppt_url: pdfres.data.pdf
            }
          }
        })

      } else if (item.source == '领导讲话') {
        console.log(item)
        if (item.query && item.query.includes(';')) {
          const query = item.query.split(';')
          this.cdata.title = query[0].split('：')[1]
          this.cdata.receive = query[1].split('：')[1]
          this.cdata.detail = query[2].split('：')[1]
          if (item.url) {
            this.fileList = [{
              name: '文件',
              url: item.url
            }]
          } else {
            this.fileList = null
          }
          this.iswrite = true;
          this.writetype = false
          this.resultdatalist = item.content
        }
      }

    },
    getloglist() {
      const params = this.stype == '领导讲话' ? `source=领导讲话` : `source=公文&type=${this.level1txt}-${this.stype}`
      getlist(params).then(res => {
        this.loglist = res.data
      })
    },
    typeclick(e) {
      console.log(this.levellist[e.type][0]);
      this.type = e.type
      this.level1txt = e.text
    },
    stypeclick(e, be) {
      console.log(e, be)
      this.type = be.type
      this.level1txt = be.text
      this.stype = e
      setTimeout(() => {
        this.reset('公文')
        this.getloglist()
        this.godetail(e)
      }, 200);
    },
    customRender(token, idx) {
      // 检查令牌是否是段落开始
      if (token.type === 'paragraph_open') {
        // 返回一个新的令牌，包含首行缩进的样式
        return `<p style="text-indent: 2em;">`
      }
      // 对其他令牌使用默认渲染
      return this.$options.methods.render.call(this, token, idx)
    },
    dellog(id) {
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delwritelog(id).then(res => {

          console.log(res);
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getloglist()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败!请稍后重试'
            });
          }
        });

      }).catch(() => { });

    },
    createwrite() {
      if (this.cdata.title == '') {
        this.$message.error('请输入标题'); return;
      } else if (this.cdata.detail == '') {
        this.$message.error('请输入事由'); return;
      }
      this.iswrite = true;
      this.isloading = true;
      this.listinfo = null
      const that = this;
      that.resultdatalist = '';
      let url = ''
      if (this.stype == '通知') {
        url = `${baseUrl}/queapi/robot_writing/writings?type=${this.level1txt}-${that.stype}&type2=${this.stype}&title=${that.cdata.title}&subject=${that.cdata.detail}&source=公文`

      } else {
        url = url = `${baseUrl}/queapi/robot_writing/writings?type=${this.level1txt}-${that.stype}&type2=${this.stype}&title=${that.cdata.title}&subject=${that.cdata.detail}&source=公文&code=${that.cdata.code}&issuing_unit=${that.cdata.send}&main_organ=${that.cdata.receive}&date=${that.cdata.date}`

      }
      // const url = this.fileList && this.fileList.length > 0 ?  : `${baseUrl}/queapi/robot_writing/writings?type=${this.level1txt}-${that.stype}&title=${that.cdata.title}&subject=${that.cdata.detail}&source=公文`
      that.eventSource = new EventSource(url)
      //&issuing_unit=${that.cdata.send}&main_organ=${that.cdata.receive}&date=${that.cdata.date}&year=${that.cdata.date.substr(0, 4)}&note=${that.cdata.note}&undertake=${that.cdata.undertake}&operator=${that.cdata.operator}&code=${that.cdata.code}
      that.eventSource.addEventListener('open', (event) => {
        that.isconn = true;
        console.log('连接已建立', JSON.stringify(event))

      })
      let isend = false;
      that.eventSource.onmessage = (event) => {
        if (event.data == '[DONE]' || isend) {
          console.log(event.data)
          this.talkid = event.data
          isend = true;
          return;
        }
        that.resultdatalist = that.resultdatalist + (event.data.replace(/&nbsp;/g, ' ').replace(/\\n/g, '\n\n'))

        if (that.resultdatalist.length % 10 == 0 || event.data.includes('，')) {
          that.$nextTick(() => {
            const element = document.getElementById('redfilebox')
            element.scrollTo({
              x: 0,
              top: Number.MAX_SAFE_INTEGER,
              behavior: 'smooth'
            })
          })
        }
      }
      that.eventSource.onerror = (event) => {
        console.log(that.resultdatalist)
        that.eventSource.close()
        this.isloading = false
        that.$nextTick(() => {
          const element = document.getElementById('resultbox')
          element.scrollTo({
            x: 0,
            top: Number.MAX_SAFE_INTEGER,
            behavior: 'smooth'
          })
          that.getloglist()
        })
      }

    },
    createfile() {
      this.downloadpdf()

    },
    downloadpdf() {
      // if (this.listinfo != null) {
      //   window.open(this.loglist[this.listinfo].word_url)
      // } else {
      if (this.listinfo != null) {
        this.talkid = this.loglist[this.listinfo].id
      }
      if (this.talkid) {
        let parms = {}
        if (this.stype == '通知') {
          parms = {
            id: this.talkid,
            type: this.level1txt + '-' + this.stype,
            type2: this.stype,
            title: this.cdata.title,
            subject: this.cdata.detail,
            issuing_unit: this.cdata.send,
            main_organ: this.cdata.receive,
            operator: this.cdata.operator,
            undertake: this.cdata.undertake,
            note: this.cdata.note,
            date: this.cdata.date,
            year: this.cdata.date.substr(0, 4),
            code: this.cdata.code,
            content: this.resultdatalist,

          }

        } else if (this.stype == '会议纪要') {
          parms = {
            type: this.level1txt + '-' + this.stype,
            id: this.talkid,
            type2: this.stype,
            title: this.cdata.title,
            subject: this.cdata.detail,
            issuing_unit: this.cdata.send,
            main_organ: this.cdata.receive,
            date: this.cdata.date,
            year: this.cdata.date.substr(0, 4),
            code: this.cdata.code,
            content: this.resultdatalist
          }
        } else if (this.stype == '领导讲话') {
          parms = {
            type: this.level1txt + '-' + this.stype,
            id: this.talkid,
            type2: this.stype,
            title: this.cdata.title,
            delivery: this.cdata.receive,
            content: this.resultdatalist,


          }
        }
        if (this.fileList && this.fileList.length > 0) {
          parms.url = this.fileList[0].url
        }
        this.$message.success('AI正在生成，请稍候')
        getwortebyid(qs.stringify(parms)).then(res => {
          window.open(res.data.word_url)
        })
      } else {
        this.$message.info('AI正在生成，请稍后再试吧')

      }

      // }


      // const dom = this.$refs.pdf // 要转换成图片的DOM元素
      // const opt = {
      //   allowTaint: true,
      //   dpi: window.devicePixelRatio * 4, // 提升导出文件的分辨率
      //   backgroundColor: 'white',
      //   useCORS: true,
      //   height: dom.scrollHeight,
      //   windowHeight: dom.scrollHeight + 500,
      // }
      // html2Canvas(dom, opt).then(
      //   (canvas) => {
      //     var pdf = new JsPDF('p', 'mm', 'a4') // A4纸，纵向
      //     var ctx = canvas.getContext('2d')
      //     var a4w = 190; var a4h = 274 // A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
      //     var imgHeight = Math.floor(a4h * canvas.width / a4w) // 按A4显示比例换算一页图像的像素高度
      //     var renderedHeight = 0
      //     while (renderedHeight < canvas.height) {
      //       var page = document.createElement('canvas')
      //       page.width = canvas.width
      //       page.height = Math.min(imgHeight, canvas.height - renderedHeight)// 可能内容不足一页
      //       // 用getImageData剪裁指定区域，并画到前面创建的canvas对象中
      //       page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0)
      //       pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 10, a4w, Math.min(a4h, a4w * page.height / page.width)) // 添加图像到页面，保留10mm边距
      //       renderedHeight += imgHeight
      //       if (renderedHeight < canvas.height) { pdf.addPage() }// 如果后面还有内容，添加一个空页
      //     }
      //     pdf.save(this.cdata.title + '.pdf')
      //   }
      // )


      // console.log(PDF)
      // PDF.save(this.cdata.title + '.pdf')

    },
    createwritexz() {

      // console.log(query)
      if (this.cdata.xzyqcl == '' || this.cdata.xzfkfs == '' || this.cdata.xzcfjd == '' || this.cdata.xzwfxw == '' || this.cdata.xzcf == '' || this.cdata.xzzjxs == '' || this.cdata.xzflyj == '' || this.cdata.xzms == '' || this.cdata.xzay == '' || this.cdata.xzname == '' || this.cdata.xznum == '') {
        this.$message.error('请完善信息'); return;
      }
      this.iswrite = true;
      this.isloading = true;
      const that = this;
      let query = '';
      this.xzcf.forEach(item => {
        query = query + `${item.name}: ${this.cdata[item.value] || ''};\n`
      })
      const url = this.fileList.length > 0 ? `${baseUrl}/queapi/robot_writing/writings?type=${this.level1txt}-${that.stype}&query=${query}&source=公文&url=${that.fileList[0].url}` : `${baseUrl}/queapi/robot_writing/writings?type=${this.level1txt}-${that.stype}&query=${query}&source=公文`
      that.eventSource = new EventSource(url)

      that.eventSource.addEventListener('open', (event) => {
        that.isconn = true;
        console.log('连接已建立', JSON.stringify(event))

      })
      let isend = false;
      that.eventSource.onmessage = (event) => {
        console.log(event)
        if (event.data == '[DONE]' || isend) {
          this.talkid = event.data
          isend = true;
          return;
        }
        that.resultdatalist = that.resultdatalist + (event.data.replace(/&nbsp;/g, ' ').replace(/\\n/g, '\n'))

        if (that.resultdatalist.length % 10 == 0 || event.data.includes('，')) {
          that.$nextTick(() => {
            const element = document.getElementById('resultbox')
            element.scrollTo({
              x: 0,
              top: Number.MAX_SAFE_INTEGER,
              behavior: 'smooth'
            })
          })
        }
      }
      that.eventSource.onerror = (event) => {
        console.log(event)
        that.eventSource.close()
        this.isloading = false
        that.$nextTick(() => {
          const element = document.getElementById('resultbox')
          element.scrollTo({
            x: 0,
            top: Number.MAX_SAFE_INTEGER,
            behavior: 'smooth'
          })
          that.getloglist()
        })
      }

    },
    createwrite2() {
      if (this.cdata.title == '') {
        this.$message.error('请输入标题'); return;
      }
      else if (this.cdata.detail == '') {
        this.$message.error('请输入发言概述'); return;
      }
      this.resultdatalist = [];
      this.iswrite = true;
      this.isloading = true;
      const that = this;

      const url = this.fileList && this.fileList.length > 0 ? `${baseUrl}/queapi/robot_writing/writings?type=${this.level1txt}-${that.stype}&source=${that.stype}&query=标题：${that.cdata.title};发言对象：${that.cdata.receive};发言概述：${that.cdata.detail}&url=${that.fileList[0].url}` : `${baseUrl}/queapi/robot_writing/writings?type=${this.level1txt}-${that.stype}&source=${that.stype}&query=标题：${that.cdata.title};发言对象：${that.cdata.receive};发言概述：${that.cdata.detail}`

      that.eventSource = new EventSource(url)

      that.eventSource.addEventListener('open', (event) => {
        that.isconn = true;
        console.log('连接已建立', JSON.stringify(event))

      })
      let isend = false;
      that.eventSource.onmessage = (event) => {
        console.log(event)
        if (event.data == '[DONE]' || isend) {
          this.talkid = event.data
          isend = true;
          return;
        }
        that.resultdatalist = that.resultdatalist + (event.data.replace(/&nbsp;/g, ' ').replace(/\\n/g, '\n'))

        if (that.resultdatalist.length % 10 == 0 || event.data.includes('\n')) {
          that.$nextTick(() => {
            const element = document.getElementById('resultbox')
            element.scrollTo({
              x: 0,
              top: Number.MAX_SAFE_INTEGER,
              behavior: 'smooth'
            })
          })
        }
      }
      that.eventSource.onerror = (event) => {
        // console.log(this.resultdatalist)
        that.eventSource.close()
        this.isloading = false
        that.$nextTick(() => {
          const element = document.getElementById('resultbox')
          element.scrollTo({
            x: 0,
            top: Number.MAX_SAFE_INTEGER,
            behavior: 'smooth'
          })
          that.getloglist()
        })
      }

    },

    filechange(e) {
      this.fileList = []
      if (!e) return; // 没有选择文件
      this.$message.success('上传中..');
      let xhr = new XMLHttpRequest();
      const formdata = new FormData();
      formdata.append('file', e)
      // formdata.append('files', file)
      xhr.open('POST', baseUrl + '/api/common/uploadFileToOSS', true);

      xhr.setRequestHeader('clientType', 'emergencyAIRobotClient')
      xhr.setRequestHeader('userPhone', '17826823305')
      xhr.send(formdata);
      const that = this;
      xhr.onreadystatechange = () => {
        console.log(xhr.status)
        if (xhr.readyState === 4 && xhr.status === 200) {

          const res = JSON.parse(xhr.responseText);

          if (res.code == 200) {
            that.fileList = [{
              name: e.name,
              url: res.data
            }]
            this.$message.success('上传成功');
          } else {
            that.$message.error(res.message)
          }
          // that.savefile(res.data, file.size, file.name)
          console.log(res)
        }
      }

    },
    outlineCreate(recreate) {
      if (!!recreate) {
        this.cdata.pptcontent = ''
        this.cdata.pptContentId = ''
      }
      if (this.cdata.ppttitle == '') {
        this.$message.error('请先输入标题');
        return;
      }
      this.isloading = true;
      let lastEnd = false
      const that = this;
      // 生成大纲
      const url = `${baseUrl}/queapi/robot_writing/writings?source=PPT大纲&query=${this.cdata.ppttitle}`
      that.eventSource = new EventSource(url)

      this.$message.success('正在生成大纲');
      that.eventSource.addEventListener('open', (event) => {
        that.isconn = true;
        console.log('连接已建立', JSON.stringify(event))

      })
      that.eventSource.onmessage = (event) => {
        console.log(event)
        if (event.data == '[DONE]') {
          lastEnd = true
          return;
        }
        if (lastEnd) {
          that.cdata.pptContentId = (event.data.replace(/&nbsp;/g, ' ').replace(/\\n/g, '\n').replace(/\*/g, '\n').replace(/\#/g, '\n').replace(/\`/g, '\n'))
          return
        }
        that.cdata.pptcontent = that.cdata.pptcontent + (event.data.replace(/&nbsp;/g, ' ').replace(/\\n/g, '\n').replace(/\*/g, '\n').replace(/\#/g, '\n').replace(/\`/g, '\n'))
        if (that.cdata.pptcontent.length % 10 == 0 || event.data.includes('\n')) {
          that.$nextTick(() => {
            const element = document.getElementById('dgid')
            element.scrollTo({
              x: 0,
              top: Number.MAX_SAFE_INTEGER,
              behavior: 'smooth'
            })
          })
        }
      }
      that.eventSource.onerror = (event) => {
        that.eventSource.close()
        this.isloading = false
        lastEnd = false
        that.$nextTick(() => {
          const element = document.getElementById('dgid')
          element.scrollTo({
            x: 0,
            top: Number.MAX_SAFE_INTEGER,
            behavior: 'smooth'
          })
        })
      }
    },
    createppt() {
      if (this.cdata.pptcontent == '') {
        this.$message.error('请先输入标题生成大纲');
        return;
      }
      const that = this;
      if (that.pptFileItem.id) {
        that.pptFileItem = {}
      }
      that.pptCreating = true
      getppt(qs.stringify({ id: that.cdata.pptContentId, content: that.cdata.pptcontent })).then(res => {
        that.iswrite = true;
        that.pptCreating = false
        if (res.code == 200 && res.success) {
          let pptitem = res.data //that.pptFileItem
          ppt2pdf(qs.stringify({ file: pptitem.ppt_url })).then(pdfres => {
            console.log('转换过的pdf', pdfres)
            if (pdfres.code == 200 && pdfres.success) {
              pptitem.ppturl = pdfres.data.pdf
              pptitem.ysppturl = pptitem.ppt_url
              that.pptFileItem = pptitem
            }
          })
        }
      }).catch(err => {
        that.pptCreating = false
      })
    },
    getformatTime(value) {
      const date = new Date(value);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}年${month}月${day}日`;
    },
    downloadFile(url) {
      window.open(url)
    }
  }
}
</script>
<style lang='less' scoped>
.writebox {
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  display: flex;

  .left {
    padding: 30px;
    height: 100%;
    width: calc(100% - 275px);
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    background-color: white;
    border-radius: 10px;
    overflow-y: auto;

    .wtopbox {
      width: 100%;
      padding: 20px;
      border-bottom: 1px solid #0057DB42;
      display: flex;

      .topitem {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 70px;
        font-size: 20px;
        color: #6D7490;
        cursor: pointer;

        img {
          width: 25px;
          height: 25px;
          margin-right: 12px;
        }
      }

      .ted {
        color: #0055D9;
        font-weight: bolder;
      }
    }

    .detailbox {
      width: 100%;
      height: calc(100% - 70px);

      padding-top: 0px;

      .level1box {
        width: 100%;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;

        p {
          width: 100%;
          font-size: 22px;
          margin-bottom: 20px;
        }
      }

      .vitem {
        width: 220px;
        height: 200px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 12px;
        font-size: 20px;
        color: #333333;
        margin-right: 40px;
        margin-bottom: 40px;
        position: relative;
        cursor: pointer;

        .vtop {
          margin: 20px;
          display: flex;
          align-items: center;

          img {
            width: 20px;
            margin-right: 12px;
          }
        }



        .gobox {
          position: absolute;
          bottom: 15px;
          left: 25px;

          img {
            width: 30px;
          }
        }
      }

      .ved {
        color: #fff;
      }
    }
  }

  .leftbox {
    width: calc(100% - 275px);
    display: flex;

    .left1 {
      width: 275px;
      height: 100%;
      background-color: white;
      border-radius: 10px;
      padding: 20px;

      .backbox {
        width: 100%;
        height: 48px;
        /* margin-bottom: 40px; */
        cursor: pointer;

        img {
          width: 100%;
        }
      }

      .mainbox {
        width: 100%;
        height: calc(100% - 50px);
        overflow-y: auto;
      }

      .topitem {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 70px;
        font-size: 18px;
        color: #6D7490;
        cursor: pointer;
        font-weight: bolder;

        img {
          width: 25px;
          height: 25px;
          margin-right: 12px;
        }
      }

      .ted {
        color: #0055D9;
      }

      .mitem {
        width: 100%;
        height: 48px;
        color: #6D7490;
        background: #E9F2FE;
        border-radius: 6px;
        font-size: 18px;
        line-height: 48px;
        padding-left: 20px;
        margin: 10px 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 20px;
          margin-right: 15px
        }
      }

      .med {
        color: #0055D9;
      }
    }

    .left2 {
      width: calc(100% - 295px);
      margin-left: 20px;
      height: 100%;
      background-color: white;
      border-radius: 10px;
      display: flex;

      .l2left {
        width: 300px;
        height: 100%;
        overflow-y: auto;
        padding: 0 20px;
        background-color: #FBFBFB;

        .wtopbox {
          width: 100%;
          padding: 20px;
          border-bottom: 1px solid #0057DB42;
          display: flex;
          justify-content: space-between;

          .topitem {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            color: #6D7490;
            cursor: pointer;

            img {
              width: 25px;
              height: 25px;
              margin-right: 12px;
            }
          }

          .ted {
            color: #0055D9;
          }
        }

        .xzcfbox {
          height: calc(100% - 100px);
          width: calc(100% + 10px);
          overflow-y: auto;
          padding-right: 10px;
        }

        .l2item {
          font-family: 'pingfang', 'Source Han Sans CN', Avenir, Helvetica, Arial, sans-serif !important;
          font-weight: 500;
          font-size: 20px;
          color: #333333;
          margin-top: 20px;
          width: 100%;
          position: relative;

          .itemtiitle {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
          }

          .reppt {
            position: absolute;
            right: 0px;
            right: 0;
          }

          /deep/ .el-input--prefix .el-input__inner {
            padding-left: 30px !important;
          }

        }

        .end {
          border-top: 1px solid #0159DC30;
          padding-top: 20px;
        }
      }

      .l2right {
        width: calc(100% - 300px);
        margin: 20px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-color: #fff;
        position: relative;
        overflow-y: auto;

        .resultbox {
          width: 100%;
          height: calc(100% - 50px);
          overflow-y: auto;
          background-color: #E9F2FE40;
        }

        .title {
          font-size: 22px;
          font-weight: bold;
          display: flex;
          margin: 20px 0;
          justify-content: center;
        }

        .pptbox {
          width: 100%;
          height: 100%;
          padding-bottom: 50px;

          iframe {
            width: 100%;
            height: 100%;
          }
        }

        .redfilebox {
          width: 710px;
          height: calc(100% - 66px);
          margin-top: 0px;
          padding: 20px;
          font-size: 21px;
          overflow-y: auto;
          background-color: white;
          font-family: 'Microsoft YaHei', '宋体', serif !important;

          .redtitlebox {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            color: #fa4006;

            padding-bottom: 30px;

            .rtleft {
              width: 100%;

              .rtt1 {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                font-size: 40px;
                justify-content: space-around;

                div {
                  font-family: 'Microsoft YaHei', '宋体', serif !important;
                  font-weight: bold;
                }
              }

            }

            .rtright {
              width: 130px;
              text-align: left;
              font-size: 40px;
              font-family: 'Microsoft YaHei', '宋体', serif !important;
              font-weight: bold;

              padding-left: 10px;

            }
          }

          .contentbottom {
            font-size: 18px;
            border-top: 1px solid #333;
            border-bottom: 1px solid #333;
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
            font-weight: bold;

          }

          .rednumbox {
            font-size: 20px;
            text-align: center;
            margin-bottom: 5px;
          }

          .redline {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .leftline,
            .rightline {
              width: calc(50% - 0px);
              height: 2px;
              background-color: #fa4006;
            }

            .ricon {
              width: 40px;
              height: 30px;
              background-image: url('../img/wujiao.jpg');
              background-size: cover;
              /*background-color: #fa4006;
              display: inline-block;
              clip-path: polygon(50% 0%,
                  61% 35%,
                  98% 35%,
                  68% 57%,
                  79% 91%,
                  50% 70%,
                  21% 91%,
                  32% 57%,
                  2% 35%,
                  39% 35%);*/
            }
          }

          .contenttitle {
            margin: 20px 0;
            font-family: 'Microsoft YaHei', '宋体', serif !important;
            font-weight: bold;
            font-size: 28px;
            text-align: center;
          }

          .contentsend {
            margin-top: 40px;
            padding-left: 20px;
            font-family: 'Microsoft YaHei', '宋体', serif !important;
            font-size: 21px;
            text-align: left;
            line-height: 40px;
          }
        }

        .custom-markdown {

          padding: 20px 30px;
          padding-top: 0;
          font-size: 20px;
        }


        .markdown2 {
          font-family: 'Microsoft YaHei', '宋体', serif !important;
          line-height: 40px;
          /* 设置行间距为1.5倍字体大小 */
          letter-spacing: 0.05em;
          font-size: 21px;

          /* 设置字间距为字体大小的5% */
          p,
          ol,
          li,
          div,
          span {
            font-family: 'Microsoft YaHei', '宋体', serif !important;

          }
        }

        .custom-markdown {
          /deep/ .table {
            border-collapse: collapse;
            border-spacing: 0;
          }

          /deep/ .table {
            border-top: 1px solid #333;
            border-left: 1px solid #333;
          }

          /deep/ .table tbody tr td,
          /deep/ .table thead tr th {
            border-right: 1px solid #333;
            border-bottom: 1px solid #333;
            padding: 6px;
          }
        }

        /deep/ .custom-markdown p,
        /deep/ .custom-markdown ol {
          margin-bottom: 18px;
        }

        .custom-markdown code,
        .custom-markdown p,
        .custom-markdown pre,
        .custom-markdown pre code {
          white-space: break-spaces;
          word-break: break-all;
          line-height: 1.5;
          font-size: 21px;
          font-family: 'Microsoft YaHei', '宋体', serif !important;
          /* 设置行间距为1.5倍字体大小 */
          letter-spacing: 0.05em;
          /* 设置字间距为字体大小的5% */
          /* 换行 */
          margin-top: 8px;
        }

        .pptdownloadbtn {
          position: absolute;
          right: 0px;
          bottom: 0px;
          width: 154px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          color: #fff;
          background: linear-gradient(150deg, #3a83ee 0%, #2253d1 100%);
          border-radius: 8px;
          cursor: pointer;
        }

        .wordcopybtn {
          position: absolute;
          right: 0px;
          bottom: 0px;
          width: 154px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          color: #333;
          background: white;
          border: 1px solid #333;
          border-radius: 8px;
          cursor: pointer;
        }

        .downloadpdf {
          position: absolute;
          right: 10px;
          bottom: 0px;
          width: 104px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          color: #333;
          background: white;
          border: 1px solid #333;
          border-radius: 8px;
          cursor: pointer;
        }

        .aitipbox {
          position: absolute;
          left: 16px;
          bottom: 4px;
          color: #7c7c7c;
          font-size: 14px;
        }

        .loadingbox {
          width: 234px;
          text-align: center;

          .createbtn {
            width: 234px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            color: #0155DA;
            background: linear-gradient(180deg, #0054D910 0%, #0052D810 100%);
            border-radius: 30px;
          }

          img {
            width: 200px;
          }
        }

        .pptloading {
          width: 80%;
          text-align: center;

          .createbtn {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            color: #0155DA;
            background: linear-gradient(180deg, #0054D910 0%, #0052D810 100%);
            border-radius: 30px;
          }

          img {
            width: 100%;
          }
        }

      }
    }
  }

  .right {
    padding: 30px 12px;
    width: 275px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    background-color: white;

    margin-left: 20px;
    border-radius: 10px;

    .rtop {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 40px;

      img {
        height: 30px;
      }
    }

    .listnodata {
      font-size: 14px;
      text-align: center;
      margin: 50px auto;
      color: #6D7490;
    }

    .rlistbox {
      height: calc(100% - 50px);
      width: 100%;
      overflow-y: auto;

      .ritem {
        width: 100%;
        height: 40px;
        border-radius: 8px;
        line-height: 40px;
        font-size: 18px;
        background: #E9F2FE;
        border-radius: 6px;
        border: 1px solid #026FE9;
        color: #026FE9;
        display: flex;
        padding-left: 10px;
        cursor: pointer;
        margin-bottom: 10px;

        color: #026FE9;


        .textbox {
          width: calc(100% - 20px);
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          white-space: nowrap;
        }

        .gobox {
          height: 100%;
          display: flex;
          align-items: center;

          img {
            height: 20px;
          }
        }
      }

      .red {
        background: linear-gradient(180deg, #0389F8 0%, #0052D8 100%);
        color: white;
      }
    }

  }

}

/deep/ .el-collapse-item__header {
  border-bottom: 0;
}

/deep/ .el-collapse-item {
  margin: 10px 0;
}

/deep/ .el-select {
  width: 100%;
}

/deep/ .el-button--primary {
  color: #FFF !important;
  background-color: #409EFF !important;
  border-color: #409EFF !important
}

/deep/ .el-button--mini {
  padding: 2px;
}

/deep/ .el-tooltip__popper.is-light {
  border: 0 !important;
}

.custom-markdown a {
  color: red;
  /* 修改a标签的颜色 */
}

// ::-webkit-scrollbar {
// display: none;


// }
::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #287df552;
  border-radius: 2px;
  border-top: 1px solid #287df552;
  border-bottom: 1px solid #287df552;
  border-left: 1px solid #287df552;
}
</style>
