<template>
  <div class="botmain">
    <div class="botcontent">
      <el-row style="height: 100%; width: 100%">
        <el-col style="height: 100%" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="bbleft">
            <!-- <div class="go1269" @click="go1269">
              {{
                aibox.appcode == 'jiaju'
                  ? '进入无极绿色家居产业大脑'
                  : '进入江西省1269产业大脑'
              }}
            </div> -->
            <!-- <span v-if="!fistalk">
              <div class="aibotbox">
                <div class="aibotimg" @click="backcj">
                  <img
                    :style="{ width: aibox.iszj ? '100px' : '140px', left: aibox.iszj ? '10px' : '-10px', top: aibox.iszj ? '20px' : '0px', borderRadius: aibox.iszj ? '50%' : '0', height: aibox.iszj ? '100px' : 'auto' }"
                    :src="aibox.gifimg" />
                </div>
                <div class="aibottxt">
                  <div class="txttit">
                    {{ aibox.hitxttitle }}111
                  </div>
                  <div class="txtcont">{{ aibox.hitxtcontent }}</div>
                </div>
              </div>
              <div class="bbvaluebox">
                <div class="bbitem" v-for="(item, i) in aiboxCjlist" :key="i">
                  <div class="bbileft" @click="gotk(item.title)">
                    <img :src="item.img" />
                  </div>
                  <div class="bbiright" @click="gotk(item.title)">
                    <p>{{ item.title }}</p>
                    <div class="queitem" v-if="si < 2" @click="gotalk(sitem, item)" :key="si"
                      v-for="(sitem, si) in item.qlist">
                      {{ si + 1 }}.{{ sitem }}
                    </div>
                  </div>
                </div>
              </div>
            </span> -->
            <div class="aitalkbox">
              <div class="talkcjlist" v-if="aiboxCjlist.length > 0">
                <div :class="cjed == item.title ? 'talkcjitem talked' : 'talkcjitem'
          " v-for="(item, i) in aiboxCjlist" :key="i" @click="cjclick(item)">
                  <img :src="item.png" alt="" />{{ item.title }}
                </div>
                <div class="qlist">
                  <div class="talkcjqlist" v-for="(item, i) in cjqlist" :key="i" @click="gotalk(item)">
                    {{ i + 1 }}.{{ item }}
                  </div>
                </div>
              </div>
              <div class="talkmainbox">
                <div class="historybox" v-if="hisflag">
                  <div class="ceratetalkbtn" @click="createtalk">创建对话</div>
                  <div class="closetalkbtn" @click="closehisbox">收起</div>
                  <div class="searchbox">
                    <div class="sinputbox">
                      <input type="text" v-model="schhistxt" placeholder="搜索历史记录" />
                    </div>
                    <div class="schbtn">
                      <svg t="1715584251836" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="1506" width="32" height="32">
                        <path
                          d="M797.525333 752.266667c62.069333-72.736 97.28-165.002667 97.28-262.186667C894.816 266.528 713.621333 85.333333 490.08 85.333333 266.538667 85.333333 85.333333 266.538667 85.333333 490.069333 85.333333 713.610667 266.538667 894.826667 490.069333 894.826667a404.693333 404.693333 0 0 0 118.208-17.546667 32 32 0 0 0-18.666666-61.216 340.693333 340.693333 0 0 1-99.541334 14.762667C301.888 830.816 149.333333 678.261333 149.333333 490.069333 149.333333 301.888 301.888 149.333333 490.069333 149.333333 678.261333 149.333333 830.826667 301.888 830.826667 490.069333c0 89.28-35.381333 173.696-97.141334 237.322667a36.992 36.992 0 0 0 0.384 51.925333l149.973334 149.973334a32 32 0 0 0 45.258666-45.248L797.525333 752.266667z"
                          fill="#ff009d" p-id="1507"></path>
                      </svg>
                    </div>
                  </div>
                  <div class="hiscontbox">
                    <div class="hisitem" v-for="(item, i) in historylist" :key="i">
                      <div class="time">{{ item.time }}</div>
                      <div v-if="sitem.title.includes(schhistxt)" :class="cided == sitem.id ? 'item itemed' : 'item'"
                        v-for="(
                          sitem, si
                        ) in item.robotQuestionAnswerHistoryList" :key="si"
                        @click="getdetaillist(sitem.id, sitem.content)">
                        {{ sitem.title }}
                        <div class="righttoptip">···</div>
                        <div class="sharediv">
                          <div class="share">分享</div>
                          <div class="share" @click="delthistalk(sitem.id)">
                            删除
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mmain" id="mmain" :style="{
          height:
            aibox.name == '晓知因' ? '100%' : 'calc(100% - 137px)',
          marginTop: aibox.name == '晓知因' ? '0' : '81px',
        }">
                  <span v-for="(item, i) in talklist" :key="i">
                    <div class="useritem">
                      <div class="userhead">
                        <img src="./img/morenhead.png" alt="" />
                      </div>
                      <div class="userright">
                        <div class="usertext">{{ item.message }}</div>
                      </div>
                    </div>
                    <div class="aiitem">
                      <div class="aihead">
                        <img :src="aibox.gifimg" alt="" />
                      </div>
                      <div class="airight">
                        <div class="airesultimg" v-if="item.reply_content &&
          (item.reply_content.imagereplys ||
            item.reply_content.imagereply)
          ">
                          <!-- <img v-for="imgitem in item.reply_content.resultimgs" :key="imgitem" :src="imgitem" /> -->
                          <el-image v-for="imgitem in item.reply_content
          .imagereplys || [item.reply_content.imagereply]" :key="imgitem" :src="imgitem.replace(
          'http://47.96.179.72:11454/',
          '/klgapi/'
        )
          " :preview-src-list="srcList">
                          </el-image>
                        </div>

                        <div class="aitext">
                          <div class="loadinggif" v-if="item.reply_content.result == '正在思考...'">
                            <img src="./img/loading.gif" />
                          </div>
                          <vue-markdown v-else :source="item.reply_content.result"
                            class="custom-markdown"></vue-markdown>
                          <div class="stopans" v-if="isstop && i == talklist.length - 1" @click="stopans">
                            停止生成
                          </div>
                        </div>
                        <div class="zhuitalk" v-if="isshowcw &&
          item.reply_content.relatedquestion &&
          item.reply_content.relatedquestion.length > 0
          ">
                          <div class="zhuititle">猜你想问：</div>
                          <div class="zhuiitem" v-for="(zitem, zi) in item.reply_content
          .relatedquestion" @click="zhuiitemclick(zitem)" :key="zi">
                            {{ zitem }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="bottombox">
              <div :class="hisflag ? 'hisbtn hisbig' : 'hisbtn'" @click="openhis" v-if="aibox.name == '晓知因'">
                <img src="./img/history.png" v-if="!hisflag" alt="" />
              </div>
              <div style="width: 100%">
                <div class="inputbox" v-if="!isaudio" :style="{ height: height }">
                  <img src="@/assets/images/yy.png" alt="" @click="audioclick" />
                  <!-- <img src="../assets/img/yy.png" alt="" @click="stopRecording"> -->
                  <div class="center">
                    <textarea id="qmess" :style="{ height: height }" v-model="newinputtext" v-on:keydown="keydown"
                      ref="textarea" outline="none" placeholder="点我开始智问…" />
                  </div>
                  <button id="sendbtn" @click="keydown(13)">
                    <img src="@/assets/images/zqzs/send.png" alt="" />
                  </button>
                </div>
                <div class="inputbox" v-else>
                  <input id="qmess" outline="none" type="hidden" placeholder="点我开始智问…" />
                  <img src="@/assets/images/yy.png" alt="" @click="audioclick" />
                  <div class="audiotip" v-if="!islu" @click="handleStart">
                    轻触开始语音输入
                  </div>
                  <div class="audiotiping" v-else @click="handleStop">
                    已录制{{ recorder.duration.toFixed(2) }}秒，轻触结束
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import VueMarkdown from 'vue-markdown'
import Recorder from 'js-audio-recorder'
import { createConversation, delConversation, getHisList, sendQuestion } from '@/api/gpt'
import calcTextareaHeight from '@/assets/calcTextareaHeight';
import router from '@/router'
import { baseUrl } from '@/axios/baseUrl'
import { formatTime } from '@/utils'
import { getMessageList } from '@/api/gpt'
// import { SpeechRecognition } from 'speech-recognition';
// 创建一个新的AudioContext对象
// const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
// let mediaRecorder = null; // 存储录音器对象
export default {
  name: '',
  components: { VueMarkdown },
  data(props) {
    return {
      fistalk: false,
      aitype: '英鸣产业大模型',
      talklist: props.basetalklist || [],
      hotlists: [],
      conversation_id: null,
      anslist: '',
      aibox: props.aiboxitem || [],
      cjqlist: [],
      recorder: null,
      isstop: false,
      isaudio: false,
      islu: false,
      recording: false, // 是否正在录音
      audioSrc: null, // 存储录音的URL
      isconn: false,
      isshowcw: false,
      eventSource: null,
      cjed: '',
      srcList: [],
      cided: 0,
      hisflag: false,
      talkguanlianid: 0,
      schhistxt: '',
      newinputtext: '',
      newinputtext: '',
      historylist: [],
      aiboxCjlist: [{ title: 'AI+监测', png: require('./img/ai1.png'), qlist: ['昨日省份空气质量如何?', '城市空气质量如何？', '国控点空气质量数据如何？', '省/市污染物和气象分布图', '区域PM2.5分布图', '省气象预警和雷达图'] },
      { title: 'AI+排名', png: require('./img/ai2.png'), qlist: ['全国省份排名？', '省内综合指数排名如何？', '省今年污染天排名如何？', '空气质量排名比省（市）好/差的有哪些省份（城市）？', '这些省份（城市）主要在什么时间段超过/差于省（城市）？'] },
      { title: 'AI+分析', png: require('./img/ai3.png'), qlist: ['城市火点分布图？', '省份昨日火点分布？', '区域6月份火点分布图？', '5月份赣州市空气质量变化趋势？', '赣州市最新空气质量历史曲线？', '赣州市风玫瑰统计图', '赣州市5月份PM2.5污染玫瑰图', '赣州市4月1日-4月15日臭氧污染玫瑰图', '赣州市昨日空气质量和气象关联度', '赣州市5月份空气质量和气象相关性分析'] },
      { title: 'AI+预测', png: require('./img/ai1.png'), qlist: ['赣州市未来几天的空气质量如何？', '江西省AQI空气质量预报？', '赣州市空气质量和气象预测？', '省/赣州市今年的年累计综合指数预测？', '赣州市昨日传输来源预测（后向轨迹图）？', '赣州市垂直气象预测图（探空图）？'] },
      { title: 'AI+调度', png: require('./img/ai5.png'), qlist: ['赣州市臭氧高值调度', '江西省PM2.5高值调度', '今日赣州市AQI达标控制', '今日赣州市AQI能达标吗？'] },
      { title: 'AI+预警', png: require('./img/ai6.png'), qlist: [''] },
      { title: 'AI+报告', png: require('./img/ai7.png'), qlist: ['赣州市昨日空气质量日报', '赣州市5月空气质量月报'] },
      { title: 'AI+值守', png: require('./img/ai8.png'), qlist: [''] },
      { title: 'AI+情报', png: require('./img/ai9.png'), qlist: ['最新热门的生态环保新闻资讯？', '最近环保督察的资讯？', '最近生态环保行业的政策资讯？', '查一下最近生态环保的新技术资讯？'] }],
      height: '30px'
      // modetype:
    }
  },
  props: {
    aiboxitem: { type: Object, default: () => [] },
    talklistchange: { type: Function },
    basetalklist: { type: Object, },
    newinput: { type: Object, }
  },
  mounted() {
    // console.log(this.aibox)
    this.recorder = new Recorder()
    console.log(this.newinput, 'newinpunewinputnewinputnewinputnewinputnewinputnewinputt');
    this.newinputtext = this.newinput
    const that = this
    setTimeout(() => {
      this.createtalk()
      if (that.aibox.name == '晓知因') {
        return
      }

      that.cjqlist = that.aiboxCjlist.length == 0 ? [] : this.aiboxCjlist[0].qlist
      that.cjed = that.aiboxCjlist[0].title
      // this.talklist = this.props.basetalklist

    }, 100);



    // console.log(this.props.basetalklist, '999')
    // this.talklist = this.props.basetalklist
  },
  watch: {
    aiboxitem(n) {
      console.log(n, 'data')
      this.cjqlist = n.cjlist.length == 0 ? [] : n.cjlist[0].qlist
      this.cjed = n.cjlist.length == 0 ? [] : n.cjlist[0].title
      this.aibox = n;
      setTimeout(() => {
        this.talklist = [];
      }, 800);
    },
    newinputtext() {
      if (this.newinput) {
        this.$emit('clear', '')
      }
      this.getHeight();
    }
  },
  beforeDestroy() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null
    }
  },
  created() {
    let _this = this;
    window.zhuiitemclick = _this.zhuiitemclick;
  },
  methods: {
    openhis() {
      this.hisflag = true
      this.fistalk = true;
      console.log(this.historylist)
      if (this.historylist.length == 0) {
        this.fetchhistorydata()
      }

    },
    getHeight() {
      console.log('扣1送地狱火');
      setTimeout(() => {
        this.height = calcTextareaHeight(this.$refs.textarea, 1, null).height;
      }, 10)

    },

    createtalk() {
      const that = this;
      // const aa = { token: that.aibox.token, app_code: that.aibox.appcode == 'jiaju' ? 'industry' : that.aibox.appcode }
      // createConversation(aa).then(res => {
      //   console.log(res);
      //   that.conversation_id = res.data.conversation_id;
      that.talklist = []
      that.cided = 0;
      that.fistalk = false
      that.hisflag = false
      // })
    },
    closehisbox() {
      this.hisflag = false
    },
    delthistalk(id) {
      const that = this;
      this.$confirm('此操作将永久删除该对话, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delConversation({ id: id }).then(res => {
          console.log(res);
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            that.talklist = []
            that.fetchhistorydata()
          } else {
            this.$message({
              type: 'error',
              message: '删除失败!请稍后重试'
            });
          }
        });

      }).catch(() => { });

    },
    getdetaillist(id, item) {
      this.cided = id
      const that = this;
      console.log(item.length)
      that.srcList = []
      const newtklist = []
      for (let i = 0; i < item.length; i += 2) {

        newtklist.push({
          message: item[i].content,
          reply_content: {
            imagereplys: item[i + 1].imagereplys || [],
            imagereply: item[i + 1].imagereply && item[i + 1].imagereply.replace('http://47.96.179.72:11454/', 'https://gpt-all.web.guanyingis.com/klgapi/'),
            result: item[i + 1].content,
            relatedquestion: item[i + 1].questionList || [],
          }
        })
      }
      that.talklist = newtklist
      // item.forEach(item => {
      //   console.log(!item.reply_content)
      //   if (item.reply_content && (item.reply_content.imagereplys || item.reply_content.imagereply)) {
      //     item.reply_content.imagereply = item.reply_content.imagereply.replace('http://47.96.179.72:11454/', '/klgapi/')
      that.srcList.push(item[1].imagereplys ? item[1].imagereplys : item[1].imagereply)
      //   }

      //   if (!item.reply_content) {
      //     item.reply_content = { result: '返回异常' }
      //   }

      // })

      console.log(that.talklist, that.srcList)
    },
    fetchhistorydata() {
      this.historylist = []
      const that = this;
      getHisList({ robotId: this.aibox.botid }).then(res => {
        console.log(this.aibox, 'this.aibox');
        // const data = res.data.list;
        // const newdata = { 0: [], 1: [], 2: [], 3: [] };
        // data.forEach(item => {
        //   const diff = parseInt((new Date().getTime() - item.update_time * 1000) / 86400000)
        //   if (diff == 0) {
        //     newdata[0].push(item)
        //   } else if (diff > 0 && diff <= 7) {
        //     newdata[1].push(item)
        //   } else if (diff > 7 && diff <= 30) {
        //     newdata[2].push(item)
        //   } else {
        //     newdata[3].push(item)
        //   }
        //   // console.log(formatTime(new Date(), 'YYYY-MM-DD'), formatTime(new Date(item.create_time * 1000), 'YYYY-MM-DD'), )
        // });
        // console.log(newdata)
        that.historylist = res.data
      })
    },
    keydown(event) {
      // console.log(event)
      if (event.keyCode === 13 && event.shiftKey) {
        // console.log("按t下了回车键+shift");
        // document.getElementById('qmess').value = document.getElementById('qmess').value + '<br />'
        // event.preventDefault(); // 阻止默认的换行行为

      } else if (event.keyCode === 13) {
        // console.log("按t下了回车键");
        setTimeout(() => {
          this.sendmessage()
          this.newinputtext = ''
        }, 100)
      }
      else if (event === 13) {
        setTimeout(() => {
          this.sendmessage()
          this.newinputtext = ''
        }, 100)
      }
    },
    audioclick() {
      this.isaudio = !this.isaudio
    },
    aitypeclick(e) {
      this.aitype = e
    },

    cjclick(item) {
      console.log(item);

      // this.fistalk = true;
      this.cjqlist = item.qlist || [];
      this.cjed = item.title
    },
    // 开始录音
    handleStart() {
      this.recorder = new Recorder()
      Recorder.getPermission().then(() => {
        console.log('开始录音')
        this.islu = true;
        this.recorder.start() // 开始录音
      }, (error) => {
        this.$message({
          message: '请先允许该网页使用麦克风',
          type: 'info'
        })
        console.log(`${error.name} : ${error.message}`)
      })
    },

    handleStop() {
      console.log('停止录音')
      this.islu = false;
      this.recorder.stop() // 停止录音
      setTimeout(() => {
        this.uploadRecord()
      }, 200);
    },

    uploadRecord() {
      if (this.recorder == null || this.recorder.duration === 0) {
        this.$message({
          message: '请先录音',
          type: 'error'
        })
        return false
      }
      this.recorder.pause() // 暂停录音
      this.timer = null
      console.log('上传录音')// 上传录音
      let xhr = new XMLHttpRequest();
      xhr.open('POST', baseUrl + '/gptapi/audioapi/asr', true);
      const formData = new FormData()
      const blob = this.recorder.getWAVBlob()// 获取wav格式音频数据
      // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
      const newbolb = new Blob([blob], { type: 'audio/wav' })
      const fileOfBlob = new File([newbolb], new Date().getTime() + '.wav')
      formData.append('file', fileOfBlob)
      // const url = window.URL.createObjectURL(fileOfBlob)
      // this.src = url
      xhr.send(formData);
      const that = this;
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          const res = JSON.parse(xhr.responseText);
          console.log(res)
          setTimeout(() => {
            document.getElementById('qmess').value = res.text;
            that.sendmessage()
          }, 100);
        }
      }

    },
    go1269() {
      window.open(this.aibox.appcode == 'jiaju' ? 'http://lead.entra.guanyingis.com/login' : 'http://industry-ai.web.shuzhiren.com/#/industrialBrain/jiangxisheng')
    },
    backcj() {
      this.fistalk = false
      const element = document.getElementById('mmain')
      element.scrollTo({
        x: 0,
        top: 0,
        behavior: 'smooth'
      })
    },
    gotalk(e, item) {
      console.log(e, item)
      if (item) this.cjed = item.title;
      setTimeout(() => {
        document.getElementById('qmess').value = e;
        this.sendmessage()
        this.cjclick(item)
      }, 500);
      this.fistalk = true;
      this.$emit('changeaiflag', true)

    },
    gotk(e) {
      this.cjed = e
      this.fistalk = true;
      this.$emit('changeaiflag', true)
    },
    changegpttype(e) {
      // console.log(e)
      this.aitype = e

    },
    gofurpage() {
      // router.push({ name: 'Furniture' })
    },
    async connectSever(input) {
      // const eventSource = new EventSource(`http://47.96.179.72:11454/chat/gygpt_chat?query=${input}&model_name=${this.aitype || '英鸣产业大模型'}&temperature=0.7&mode=绿色食品GPT`, {
      //   headers: {
      //     'Accept': 'text/event-stream '
      //   }
      // })
      const that = this;
      that.talklist[that.talklist.length - 1].reply_content.result = '正在思考...'
      console.log('进来了几次')

      if (this.talkguanlianid == 0) {
        that.eventSource = new EventSource(`${baseUrl}/api/industryWisdomAsk/askByStream?robotId=${this.aibox.botid}&query=${input}&accessToken=${localStorage.getItem("token")}`)
      } else {
        that.eventSource = new EventSource(`${baseUrl}/api/industryWisdomAsk/askByStream?robotId=${this.aibox.botid}&query=${input}&robotQAHistoryId=${this.talkguanlianid}&accessToken=${localStorage.getItem("token")}`)
      }

      that.eventSource.addEventListener('open', (event) => {
        that.isconn = true;
        console.log('连接已建立', JSON.stringify(event))

      })
      that.eventSource.onmessage = (event) => {
        that.isstop = true;
        // console.log(event.data)
        const data = JSON.parse(event.data)
        console.log(data)
        if (!data) {
          that.talklist[that.talklist.length - 1].reply_content.result = '正在思考...'
          return
        }
        if (that.talklist[that.talklist.length - 1].reply_content.result == '正在思考...') {

          that.talklist[that.talklist.length - 1].reply_content.result = ''
        }
        that.isshowcw = true
        if (data.answer == '[DONE]') {
          that.talklist[that.talklist.length - 1].reply_content.relatedquestion = data.relatedquestion;
          return;
        }
        if (data.id) {
          this.talkguanlianid = data.id
          return;
        }

        // that.anslist = that.anslist + data.answer

        that.talklist[that.talklist.length - 1].reply_content.imagereplys = data.imagereplys ? data.imagereplys : data.imagereply ? [data.imagereply] : null
        that.talklist[that.talklist.length - 1].reply_content.result = that.talklist[that.talklist.length - 1].reply_content.result + data.answer


        if (data.answer.includes('\n') || that.talklist[that.talklist.length - 1].reply_content.result.length / 40 == 0 || data.answer.includes('图') || data.answer.includes('，')) {
          that.$nextTick(() => {
            const element = document.getElementById('mmain')
            element.scrollTo({
              x: 0,
              top: Number.MAX_SAFE_INTEGER,
              behavior: 'smooth'
            })
          })
        }


      }
      that.eventSource.addEventListener('eventName', (event) => {
        console.log('Received event:', event.data);
      });
      that.eventSource.onerror = (event) => {
        that.isconn = false;
        console.log('连接已断开')
        console.log(that.srcList)
        that.isstop = false;
        that.isshowcw = false;
        that.$nextTick(() => {
          that.isshowcw = true;
          setTimeout(() => {
            const element = document.getElementById('mmain')
            element.scrollTo({
              x: 0,
              top: Number.MAX_SAFE_INTEGER,
              behavior: 'smooth'
            })
          }, 300);


        })
        // setTimeout(() => {

        //   that.isshowcw = false
        // }, 1000);    .replace('http://47.96.179.72:11454/','/klgapi/')
        console.log(that.talklist[that.talklist.length - 1].reply_content)
        if (that.talklist[that.talklist.length - 1].reply_content.imagereplys) {
          that.talklist[that.talklist.length - 1].reply_content.imagereplys.forEach(item => {
            that.srcList.push(item.replace('http://47.96.179.72:11454/', 'https://gpt-all.web.guanyingis.com/klgapi/'))
          })
          // that.srcList.push(...that.talklist[that.talklist.length - 1].reply_content.resultimgs)
        }
        console.log(that.srcList)
        that.eventSource.close()
      }
    },
    stopans() {
      // this.eventSource.stop()
      this.eventSource.close()
      this.isstop = false
      this.isshowcw = false;
      this.isconn = false;
    },
    zhuiitemclick(e) {
      console.log(e)
      this.gotalk(e)
    },
    sendmessage() {
      // document.getElementsByClassName('aibotbox')[0].style.display = 'none'
      // document.getElementsByClassName('aitalkbox')[0].style.height = 'calc(100% - 20px)'
      // document.getElementsByClassName('aihotbox')[0].style.marginTop = '150px'
      if (this.isconn) {
        return;
      }
      if (!this.fistalk) {
        this.fistalk = true
      }
      console.log('first')
      const message = document.getElementById('qmess').value
      if (message == '') return;
      this.talklist.push({
        message: message,
        reply_content: {
          result: ''
        }
      })
      const element = document.getElementById('mmain')
      setTimeout(() => {
        element.scrollTo({
          x: 0,
          top: element.scrollHeight,
          behavior: 'smooth'
        })

        document.getElementById('qmess').value = ''
      }, 300)
      this.$emit('talklistchange', this.talklist)
      this.connectSever(message);

    },
  }
}
</script>
<style lang='less' scoped>
.botmain {
  height: 100%;
  width: 100%;
  position: relative;

  .bottop {
    width: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;

    .botleft {
      width: calc(100% - 100px);
      display: flex;
      flex-wrap: wrap;
      padding: 10px 20px;
      background-color: white;

      border-radius: 8px;

      .typeitem {
        width: 180px;
        height: 60px;
        display: flex;
        color: #fff;
        font-size: 20px;
        text-align: center;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          height: 25px;
          margin-right: 10px;
        }
      }

      .typeed {
        color: white;
        background-color: #263754;
        border-radius: 10px;
      }
    }

    .gpttypebox {
      width: 100%;
      display: none;
    }

    .bottopright {
      width: 80px;
      height: 80px;
      background-color: white;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 32px;
      }
    }

    @media (max-width: 998px) {
      .gpttypebox {
        width: 100%;
        display: flex;
        width: calc(100% - 100px);
        height: 80px;
        display: flex;
        flex-wrap: wrap;
        padding: 10px 20px;
        background-color: white;

        border-radius: 8px;

        /deep/ .el-dropdown {
          width: 100%;
        }

        /deep/ .el-button--primary {
          height: 100%;
          width: 100%;
          color: white;
          background-color: #263754;
          font-size: 20px;
        }
      }

      .botleft {
        display: none;
      }
    }
  }

  .aitalkbox {
    width: calc(100% - 0px);
    height: calc(100% - 50px);
    padding: 20px 0;
    border-radius: 8px;
    margin-bottom: 20px;
    transition: height 0.3s;
    position: absolute;
    bottom: 50px;
    left: 0;

    .talkcjlist {
      box-sizing: border-box;
      padding: 10px;
      width: 100%;
      height: 70px;
      background: linear-gradient(275deg, #181b40 0%, #181a37 100%);
      box-shadow: -1px -1px 2px #1e213b;
      border-radius: 5px;
      display: flex;
      flex-wrap: wrap;
      // justify-content: center;
      align-items: center;

      /* 垂直居中 */
      img {
        display: inline-block;
        vertical-align: middle;
        width: 25px;
      }

      .qlist {
        width: 100%;
        height: 71px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        /* 垂直居中 */
        margin-top: 10px;
        // align-content: center;
        // align-items: center;
        overflow-y: auto;

        .talkcjqlist {
          background-color: #2d3269;
          margin-left: 20px;
          height: 30px;
          margin-top: 5px;
          color: #d0d0ff;
          font-size: 14px;
          cursor: pointer;
          padding: 5px 28px;
          border-radius: 28px;
        }

        .talkcjqlist:hover {
          color: #ff009d;
          border: 1px solid #ff009d;
        }
      }

      .talkcjitem {
        height: 70%;
        line-height: 50px;
        height: 50px;
        color: #fff;
        font-size: 18px;
        width: 150px;
        text-align: center;
        cursor: pointer;
        font-weight: bolder;
      }

      .talked {
        background: linear-gradient(270deg, #9953ff 0%, #53a9ff 100%);
        border-radius: 10px;
      }
    }

    .talkmainbox {
      height: 100%;
      width: 100%;
      display: flex;

      .mmain {
        width: 100%;
        height: calc(100% - 107px);
        margin-top: 56px;
        overflow-y: auto;
        margin-left: 20px;
      }

      .historybox {
        width: 300px;
        margin-left: -20px;
        margin-top: -20px;
        height: calc(100% + 130px);
        border-radius: 20px 0 0 0;
        background-color: #212340;
        padding: 20px;
        position: relative;

        .closetalkbtn {
          position: absolute;
          bottom: 10px;
          left: 10px;
          border: 1px solid #ff009d;
          color: #ff009d;
          width: calc(100% - 20px);
          text-align: center;
          font-size: 18px;
          line-height: 40px;
          border-radius: 10px;
          height: 40px;
          letter-spacing: 10px;
          cursor: pointer;
          z-index: 1;
        }

        .ceratetalkbtn {
          background-color: #ff009d;
          color: #fff;
          width: 100%;
          text-align: center;
          font-size: 18px;
          line-height: 50px;
          border-radius: 10px;
          height: 50px;
        }

        .searchbox {
          width: 100%;
          margin: 20px 0;
          height: 50px;
          border-radius: 20px;
          background-color: #141732;
          display: flex;

          .sinputbox {
            width: calc(100% - 10px);

            input {
              height: 40px;
              margin: 5px;
              background-color: transparent;
              color: white;
              font-size: 18px;
              padding-left: 15px;
              border: none;
              width: 100%;
              border-radius: 20px;
            }

            input:focus {
              outline: none;
              box-shadow: none;

              border: none;
            }
          }

          .schbtn {
            width: 30px;
            margin: 10px;
            cursor: pointer;
          }
        }

        .hiscontbox {
          width: calc(100% + 10px);
          height: calc(100% - 200px);
          overflow-y: auto;

          .hisitem {
            width: 100%;
            margin-bottom: 10px;

            .time {
              color: #ff009d;
              font-size: 16px;
              margin-bottom: 10px;
            }

            .item {
              border-radius: 10px;
              cursor: pointer;
              background-color: #141732;
              padding: 10px;
              color: #ffffff;
              font-size: 16px;
              position: relative;
              margin-bottom: 10px;

              .righttoptip {
                position: absolute;
                right: 10px;
                top: 10px;
                font-size: 14px;
                font-weight: bolder;
                color: #ff009d;
                cursor: pointer;
              }

              .righttoptip:hover+.sharediv {
                display: block;
              }

              .sharediv:hover {
                display: block;
              }

              .sharediv {
                position: absolute;
                right: 10px;
                top: 30px;
                font-size: 14px;
                cursor: pointer;
                border-radius: 4px;
                background-color: #151730;
                border: 1px solid #d22fa3;
                border-bottom: 0;
                color: #ff009d;
                display: none;
                z-index: 2;

                .share {
                  padding: 8px 15px;
                  border-bottom: 1px solid #d22fa3;
                }

                .share:hover {
                  background-color: #ff009d;
                  color: white;
                }
              }
            }

            .itemed {
              background-color: #ff009d;
              color: #fff;

              .righttoptip {
                color: white;
              }
            }
          }
        }
      }
    }

    .endline {
      border-bottom: 1px solid #c6ccc6;
      height: 20px;
      text-align: center;
      margin-bottom: 20px;
    }

    .bg1 {
      background-color: #f6683d10 !important;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }

  // /*水平的整个滚动条*/

  // ::-webkit-scrollbar:horizontal {
  //   height: 24px;
  //   background-color: transparent;
  // }

  /*滚动条轨道*/

  /*竖直的滑块*/
  ::-webkit-scrollbar-thumb {
    background-color: rgb(20, 61, 107);
    border-radius: 2px;
    border-top: 1px solid rgb(20, 61, 107);
    border-bottom: 1px solid rgb(20, 61, 107);
    border-left: 1px solid rgb(20, 61, 107);
  }

  .aiitem {
    display: flex;
    width: 100%;

    .aihead {
      width: 80px;
      height: 80px;
      padding-left: 10px;
      margin-right: 10px;
      font-size: 18px;

      img {
        width: 80px;
        margin-left: -10px;
        border-radius: 50%;
      }
    }

    .airight {
      min-width: 180px;
      max-width: calc(100% - 180px);
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-left: 10px;
      text-align: left;
      margin-bottom: 20px;
      // padding-bottom: 20px;
      background: linear-gradient(275deg, #181b40 0%, #181a37 100%);
      box-shadow: 0px 0px 6px #1e213b;
      border-radius: 0px 12px 12px 12px;
      position: relative;

      .airesultimg {
        width: 400px;
        margin: 20px;
        margin-bottom: 0;

        img {
          height: 300px;
        }
      }

      .name {
        font-size: 18px;
        color: #fff;
        margin-bottom: 10px;

        span {
          font-size: 12px;
          background-color: #ebf0f3;
          border-radius: 6px;
          padding: 4px 8px;
          margin-left: 10px;
        }
      }

      .aitext {
        // width: 500px;
        width: 100%;
        color: #fff;
        padding: 10px 20px;
        font-size: 18px;

        .loadinggif {
          height: 52px;

          img {
            height: 52px;
          }
        }

        .stopans {
          font-size: 14px;
          height: 50px;
          line-height: 50px;
          cursor: pointer;
          color: #ff009d;
        }

        .custom-markdown a {
          color: red;
          /* 修改a标签的颜色 */
        }

        .custom-markdown code,
        .custom-markdown pre,
        .custom-markdown pre code {
          white-space: break-spaces;
          word-break: break-all;
          /* 换行 */
        }

        .talkhotitem {
          min-width: 550px;
          height: 40px;
          background-color: white;
          border: 1px solid #2b3f60;
          margin: 5px 20px;
          font-size: 20px;
          text-align: center;
          line-height: 40px;
          cursor: pointer;
        }

        .talkhotitem:hover {
          background-color: #2b3f60;
          color: white;
        }
      }

      .zhuitalk {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .zhuititle {
          width: 100%;
          color: #ff009d;
          font-size: 18px;
          padding: 10px 20px;
        }

        .zhuiitem {
          max-width: calc(33% - 20px);
          background: #2d3269;
          border-radius: 19px;
          font-family: Alibaba PuHuiTi;
          font-weight: 500;
          font-size: 14px;
          color: #d0d0ff;
          text-align: center;
          padding: 10px 20px;
          margin: 10px 10px;
          overflow: hidden;
          /* 确保文本超出容器时会被裁剪 */
          white-space: nowrap;
          /* 确保文本在一行内显示 */
          text-overflow: ellipsis;
          /* 使用省略号表示文本溢出 */
          cursor: pointer;
        }
      }
    }
  }

  .useritem {
    color: white;
    width: calc(100% - 40px);
    margin: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;

    .userhead {
      width: 80px;
      height: 80px;
      padding-left: 10px;
      margin-right: 10px;
      font-size: 18px;
      letter-spacing: 2px;

      img {
        width: 80px;
        margin-left: -10px;
        border-radius: 50%;
        padding: 10px;
      }
    }

    .userright {
      // background: #FF009D;
      padding-right: 10px;
      text-align: left;
      float: right;
      margin: 10px 0;
      max-width: 800px;

      .usertext {
        background: #ff009d;
        color: white;
        box-shadow: 0px 0px 6px #1e213b;
        border-radius: 12px 0px 12px 12px;
        font-size: 16px;
        padding: 10px 20px;
        white-space: pre-wrap;
      }
    }
  }
}

.botcontent {
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 0px);

  .bbleft {
    width: calc(100% - 0px);
    margin: 0 auto;
    height: calc(100% - 0px);
    position: relative;

    .go1269 {
      position: absolute;
      cursor: pointer;
      right: 0px;
      top: 20px;
      color: #0988f6;
      font-size: 20px;
    }

    .bbtitle {
      font-size: 32px;
      color: #fff;
      font-weight: bold;
      padding: 10px 20px;
    }

    .bbvaluebox {
      display: flex;

      flex-wrap: wrap;
      margin-top: 30px;
      width: 100%;

      .bbitem {
        width: calc(50% - 24px);
        display: flex;
        background: linear-gradient(275deg, #181b40 0%, #181a37 100%);
        box-shadow: -1px -1px 2px #1e213b;
        opacity: 1;
        border-radius: 8px;
        border: 1px solid #1e213b;
        margin: 12px;
        padding: 20px;
        cursor: pointer;

        .bbileft {
          width: 150px;

          img {
            width: 100%;
            border: 1px solid #ff009d80;
            border-radius: 8px;
            padding: 10px;
          }
        }

        .bbiright {
          width: calc(100% - 150px);
          padding: 0 20px;
          padding-top: 0;
          font-size: 16px;
          color: #fff;
          text-align: left;
          margin: 0 auto;

          p {
            font-size: 24px;
            font-weight: 600;
            color: #ff009d;
            margin: 10px 0;
            text-align: left;
            margin-bottom: 20px;
          }

          .queitem {
            color: #fff;
            margin-top: 10px;
            cursor: pointer;
          }

          .queitem:hover {
            color: #ff009d;
            text-decoration: underline;
          }
        }
      }
    }

    .bbright {
      width: 100%;
      border-radius: 8px;
      display: flex;
      flex-wrap: wrap;

      .hotitem {
        background-color: #1c223608;
        color: #fff;
        font-size: 18px;
        margin: 10px;
        border-radius: 20px;
        padding: 10px 5px;
        padding-left: 60px;
        width: calc(33% - 18px);
        justify-content: space-between;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        cursor: pointer;

        .hoticon {
          width: 25px;
          height: 25px;
          line-height: 25px;
          text-align: center;
          border-radius: 50%;
          font-size: 16px;
          background-color: orange;
          color: white;
          position: absolute;
          left: 15px;
          bottom: 10px;
        }

        .h1 {
          background-color: red;
        }

        .h2 {
          background-color: rgb(134, 134, 134);
        }
      }

      .bg1 {
        background-color: #f6683d10 !important;
      }
    }

    .bottombox {
      width: 100%;
      height: 80px;
      display: flex;
      position: absolute;
      left: 0;
      bottom: 0;

      .hisbtn {
        height: 80px;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        background: #181b39;
        box-shadow: 0px 0px 2px rgba(124, 130, 180, 0.13);
        border-radius: 8px;
        cursor: pointer;

        img {
          width: 30px;
          height: 30px;
        }
      }

      .hisbig {
        height: 80px;
        width: 300px;
        background: transparent;
        box-shadow: none;
        z-index: -1;
      }

      .inputbox {
        position: absolute;
        bottom: 0;
        width: calc(100% - 101px);
        min-height: 80px;
        display: flex;
        padding: 10px 20px;
        background: linear-gradient(275deg, #181b40 0%, #181a37 100%);
        box-shadow: 1px 0px 6px #1e213b;
        border-radius: 12px;
        justify-content: center;
        align-items: center;

        img {
          width: 45px;
          height: 45px;
          margin: 0 10px;
          cursor: pointer;
        }

        button {
          background: transparent;
          border: 0;
        }

        .audiotip {
          width: calc(100% - 30px);
          background-color: #f2f2f2;
          color: #444;
          height: 70%;
          border-radius: 20px;
          text-align: center;
          line-height: 40px;
          font-size: 14px;
          letter-spacing: 5px;
        }

        .audiotiping {
          width: calc(100% - 30px);
          background-color: #f2f2f2;
          color: #444;
          height: 70%;
          border-radius: 20px;
          text-align: center;
          line-height: 40px;
          font-size: 14px;
        }

        .center {
          width: calc(100% - 110px);

          textarea {
            width: 100%;
            height: 100%;
            border: 0;
            outline: none;
            color: #fff;
            font-size: 20px;
            font-family: Alibaba PuHuiTi;
            resize: none;
            background-color: transparent;
            white-space: pre-wrap;
            white-space: pre-wrap;
          }

          input:focus {
            outline: none;
          }

          input::placeholder {
            color: #fff;
            font-weight: 100;
          }
        }
      }
    }

    .aibotbox {
      width: 100%;
      display: flex;
      padding: 20px;

      .aibotimg {
        cursor: pointer;
        width: 130px;
        height: 130px;
        position: relative;

        img {
          position: absolute;
          left: -10px;
          top: -20px;
          width: 140px;
        }
      }

      .aibottxt {
        padding: 10px 50px;
        width: calc(100% - 200px);

        .txttit {
          font-size: 32px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #ff009d;
          line-height: 45px;
        }

        .txtcont {
          font-size: 18px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #fff;
          line-height: 25px;
          margin-top: 20px;
        }
      }
    }
  }

  @media (max-width: 998px) {
    .bbleft {
      width: 100%;

      .bbtitle {
        font-size: 32px;
        color: #fff;
        font-weight: bold;
        padding: 10px 20px;
      }

      .bbvaluebox {
        display: flex;
        flex-wrap: wrap;

        .bbitem {
          width: 100%;
          display: flex;
          background: #ffffff;
          box-shadow: 3px 0px 15px rgba(0, 0, 0, 0.1);
          opacity: 1;
          border-radius: 8px;
          margin: 15px;
          padding: 20px;
          cursor: pointer;

          .bbileft {
            width: 180px;
            height: 140px;

            img {
              width: 100%;
            }
          }

          .bbiright {
            padding: 20px;
            padding-top: 0;
            font-size: 16px;
            color: #666;

            p {
              font-size: 26px;
              font-weight: 400;
              color: black;
              margin: 10px 0;
            }
          }
        }
      }
    }

    .bbright {
      width: 100%;
      margin: 0 20px;
      margin-left: 20px;
    }
  }
}

@media (max-width: 998px) {
  .botmain {
    margin-top: 20px;

    .inputbox {
      margin-top: 15px;
    }

    .botcontent {
      margin: 15px 0;
    }

    .aitalkbox {
      .talkmainbox {
        .mmain {
          max-height: 500px;

          .aiitem {
            margin-top: 20px;
          }

          .useritem .userright {
            margin-top: 0;
            margin-bottom: 10px;
          }

          .aiitem .airight .aitext {
            max-width: 100%;
          }

          .useritem .headimg,
          .aiitem .headimg {
            width: 30px;
            height: 30px;

            img {
              width: 80%;
              margin: 10%;
            }
          }
        }
      }
    }
  }
}

/deep/ a {
  color: #ff009d !important;
}

/deep/ pre {
  white-space: break-spaces;
  word-break: break-all;
}
</style>
