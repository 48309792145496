// 接口
import axios from '@/axios'
import { baseUrl } from '@/axios/baseUrl'
// 获取通知
export const getRank = (data) => axios.get(baseUrl + '/queapi/aqi/rank?' + data)
// 获取列表
export const getlist = (data) => axios.get(baseUrl + '/queapi/robot_writing?' + data)

// 获取ppt
export const getppt = (data) => axios.post(baseUrl + '/queapi/robot_writing/ppt_outline?' + data)
// 转换格式
export const ppt2pdf = (data) => axios.post(baseUrl + '/queapi/file/file_to_pdf?' + data)
//删除记录
export const delwritelog = (data) => axios.delete(baseUrl + '/queapi/robot_writing/' + data)
//获取单条记录
export const getwortebyid = (data) => axios.put(baseUrl + '/queapi/robot_writing/download/word', data)
