import { render, staticRenderFns } from "./myaibot.vue?vue&type=template&id=043987c2&scoped=true&"
import script from "./myaibot.vue?vue&type=script&lang=js&"
export * from "./myaibot.vue?vue&type=script&lang=js&"
import style0 from "./myaibot.vue?vue&type=style&index=0&id=043987c2&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "043987c2",
  null
  
)

export default component.exports