<template>
  <div class="klgmain" id="xcenter">
    <div class="bottop" @click="updownclick">
      <div :class="type == 'first' ? 'menuitem med' : 'menuitem'">
        <div>知识库 {{ klglist.length }}个</div>
        <img :style="{ transform: `rotate(${updownflag ? '0deg' : '180deg'})` }" src="./img/down.png" />
      </div>
      <!-- <div :class="type == 'second' ? 'menuitem med' : 'menuitem'" @click="typeclick('second')">发布</div> -->
    </div>
    <div class="klgcontent">
      <!-- <div class="uploadbox" style="display: none;">

        <input id="inputfile" type="file" />
        <div class="tip">上传知识文件</div>
      </div> -->
      <div class="detailbox" v-show="updownflag">
        <div class="detail">
          <div class="detailitem">
            <div class="dname">知识库总数</div>
            <div class="dvalue">{{ allklgcountdata.knowledgeCount || 0 }}<span>个</span></div>
          </div>
          <div class="detailitem">
            <div class="dname">训练完成</div>
            <div class="dvalue">{{ allklgcountdata.finishTrainingCount || 0 }}<span>个</span></div>
          </div>
          <div class="detailitem">
            <div class="dname">问答集</div>
            <div class="dvalue">{{ allklgcountdata.questionAnswerCount || 0 }}<span>个</span></div>
          </div>
          <div class="detailitem">
            <div class="dname">问答集自行上传</div>
            <div class="dvalue">{{ allklgcountdata.questionAnswerHandCount || 0 }}<span>个</span></div>
          </div>
          <div class="detailitem">
            <div class="dname">AI生成</div>
            <div class="dvalue">{{ allklgcountdata.questionAnswerAICount || 0 }}<span>个</span></div>
          </div>
          <div class="detailitem">
            <div class="dname">文档集</div>
            <div class="dvalue">{{ allklgcountdata.documentCount || 0 }}<span>个</span></div>
          </div>
          <div class="detailitem">
            <div class="dname">文档集AI采集</div>
            <div class="dvalue">{{ allklgcountdata.documentAICount || 0 }}<span>个</span></div>
          </div>
          <div class="detailitem">
            <div class="dname">文档集自行上传</div>
            <div class="dvalue">{{ allklgcountdata.documentHandCount || 0 }}<span>个</span></div>
          </div>
        </div>
        <div class="tcont">
          <div :class="level1 == item ? 'zskitem zname' : 'zskitem'" v-for="(item, i) in showlevel1list" :key="i"
            @click="level1click(item)">{{ item }}
            <span>{{ showklglist[item].length }}</span> 个
          </div>
        </div>
        <div class="tcont">
          <draggable class="drawbox" v-if="level1 == '自建知识库'" v-model="zjshowlist" @start="drag = true" @end="drawend">

            <div v-for="(item, i) in zjshowlist" :key="i"
              :class="klged == item.split('|')[1] ? 'zskitem zname' : 'zskitem'" @click="klgchange(item)">
              <el-tooltip effect="dark" content="知识库可自由拖动排序" placement="top">
                <span>{{ item.split('|')[0] }}</span>
              </el-tooltip>

              <div class="righttoptip" v-if="klged == item.split('|')[1]">···</div>
              <div class="sharediv" v-if="klged == item.split('|')[1]">
                <div class="share edit" @click="toeditklg(item.split('|')[1])">编辑</div>
                <div class="share" @click="godel(item.split('|')[1])">删除</div>
              </div>
            </div>

          </draggable>
          <div class="drawbox" v-else>
            <div v-for="(item, i) in showklglist[level1]" :key="i"
              :class="klged == item.split('|')[1] ? 'zskitem zname' : 'zskitem'" @click="klgchange(item)">
              {{
      item.split('|')[0] }}
            </div>
          </div>
          <div class="addklg" v-if="level1 == '自建知识库'" @click="opencreate">+</div>
        </div>
        <div class="detail">
          <div class="detailitem">
            <div class="dname">训练完成</div>
            <div class="dvalue">{{ klgdetaildata.finishTrainingCount || 0 }}<span>个</span></div>
          </div>
          <div class="detailitem">
            <div class="dname">问答集</div>
            <div class="dvalue">{{ klgdetaildata.questionAnswerCount || 0 }}<span>个</span></div>
          </div>
          <div class="detailitem">
            <div class="dname">问答集自行上传</div>
            <div class="dvalue">{{ klgdetaildata.questionAnswerHandCount || 0 }}<span>个</span></div>
          </div>
          <div class="detailitem">
            <div class="dname">AI生成</div>
            <div class="dvalue">{{ klgdetaildata.questionAnswerAICount || 0 }}<span>个</span></div>
          </div>
          <div class="detailitem">
            <div class="dname">文档集</div>
            <div class="dvalue">{{ klgdetaildata.documentCount || 0 }}<span>个</span></div>
          </div>
          <div class="detailitem">
            <div class="dname">文档集AI采集</div>
            <div class="dvalue">{{ klgdetaildata.documentAICount || 0 }}<span>个</span></div>
          </div>
          <div class="detailitem">
            <div class="dname">文档集自行上传</div>
            <div class="dvalue">{{ klgdetaildata.documentHandCount || 0 }}<span>个</span></div>
          </div>
        </div>
      </div>
      <div class="fbbox">
        <div class="fbtop">
          <div :class="smenu == '问答集' ? 'fbitem checked' : 'fbitem'" @click="smenuclick('问答集')">问答集</div>
          <div :class="smenu == '文档集' ? 'fbitem checked' : 'fbitem'" @click="smenuclick('文档集')">文档集</div>
        </div>
      </div>

      <Que :klgid="klged" :level="level1" :updownflag="updownflag" v-if="smenu == '问答集'" />
      <File :klgid="klged" :level="level1" :updownflag="updownflag" :klgname="klgname" v-else />

      <el-dialog :title="editklgid == 0 ? '创建知识库' : '编辑'" :append-to-body="true" :visible="dialogVisible" width="600px"
        :before-close="handleClose">
        <div class="mydialog">
          <p>知识库名称：</p>
          <el-input placeholder="请输入知识库名称" v-model="botname" />
        </div>
        <div class="mydialog">
          <p>是否公开：</p>
          <el-radio-group v-model="isopen">
            <el-radio :label="true">公开</el-radio>
            <el-radio :label="false">私密</el-radio>
          </el-radio-group>
        </div>
        <div class="mydialog" v-if="!isopen">
          <p>关联机器人：</p>
          <el-select popper-class="myselect" v-model="glbot" multiple placeholder="请选择关联机器人">
            <el-option v-for="item in aibotlist" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false">取 消</el-button>
          <el-button size="small" type="primary" @click="klgsubmit">提 交</el-button>
        </span>
      </el-dialog>
      <el-dialog title="删除知识库" :append-to-body="true" :visible="delVisible" width="500px" :before-close="delClose">
        <div class="delmydialog">
          该操作将永久删除该知识库下的所有文件与信息且无法恢复。请确保你以做好数据备份。
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="delVisible = false">取 消</el-button>
          <el-button size="small" type="primary" @click="todelklg">提 交</el-button>
        </span>
      </el-dialog>
    </div>

  </div>
</template>
<script>
import { getglBotList } from '@/api/bot'
import CYFile from './klg/cyfile.vue'
import CYQue from './klg/cyque.vue'
import File from './klg/file.vue'
import Que from './klg/que.vue'
import draggable from 'vuedraggable';
import { getKlfLists, getAllKlgCount, createKlg, getKlgCountbyname, editKlg, delKlg, saveuserklgsort } from '@/api/klg'

export default {
  name: '',
  components: {
    File,
    Que,
    CYFile,
    CYQue,
    draggable
  },
  data(props) {
    return {
      type: 'first',
      aibot: props.aiboxitem,
      smenu: '问答集',
      allklgcountdata: [],
      klgdetaildata: [],
      dialogVisible: false,
      delVisible: false,
      klglist: [],
      aibotlist: [],
      botname: '',
      isopen: false,
      glbot: [],
      delid: 0,
      klged: 0,
      klgname: '',
      draw: false,
      level1: '',
      editklgid: 0,
      editklginfo: [],
      indklglist: {
        产业知识库: ['晓知因', '产业大脑', '石化化工', '稀土金属', '铜基新材', '现代家具', '玩具产业', '绿色食品', '电子信息', '心理咨询'],
        园区管理知识库: ['冠英数字园区', '会昌氟盐', '襄城化工园区'],
        企业管理知识库: ['园区管理', '冠英方案中心', '冠英营销中心'],
        公共服务知识库: ['生态环保', '赣州人才']
      },
      indklgnamelist: {
        晓知因: '通用知识库',
        稀土金属: '稀土有色',
        心理咨询: '医学心理',
        冠英数字园区: '冠英数字未来园区',
        会昌氟盐: '会昌氟盐化工园区',
        襄城化工园区: '襄城碳硅化工园区',
        园区管理: '冠英生产管理知识库',
        冠英方案中心: '冠英方案中心知识库',
        冠英营销中心: '冠英营销中心知识库',
        赣州人才: '赣州人才政策',
      },
      showlevel1list: [],
      showklglist: {},
      updownflag: true,
      zjshowlist: []
      // 目前:产业知识库、园区知识库、企业管理知识库、公共服务知识库
      // ●规则:只有购买了相关的机器人&方案，所属的一级目录才会在训推中心中显示二级目录(括号内为后端知识库名称，需改成括号外名称)
      // 目前:
      // 通用知识库(晓知因)、石化化工(石化化工)、稀土有色(稀土金属)、铜基新材(铜基新材)
      // 现代家具(现代家具)、玩具产业(玩具产业)、绿色食品(绿色视频) 电子信息(电子信息)
      // 医学心理(心理咨询
      // 冠英数字未来园区(冠英数据园区)、会昌氟盐化工园区(会昌氟盐))、襄城碳硅化工园区(想襄城化工园区)
      // 冠英生产管理知识库(园区管理)、冠英方案中心知识库(冠英方案中心)、冠英营销中心知识库(冠英方案中心)
      // 生态环保、赣州人才政策(赣州人才)、自建知识库
    }
  },
  props: {
    aiboxitem: { type: Object, default: () => [] },
  },
  mounted() {
    setTimeout(() => {

      this.getklglist()
      this.countallklg()
    }, 500);

  },
  watch: {
    aiboxitem(n) {
      this.aibot = n;
    }
  },
  methods: {
    fetchbotlist() {
      getglBotList().then(res => {
        console.log('机器人列表', res)
        this.aibotlist = res.data
      })
    },
    updownclick() {
      this.updownflag = !this.updownflag
    },
    delClose() {
      this.delVisible = false
    },
    getklglist() {
      console.log(this.aibot, '9999')
      const param = {
        id: this.aibot.botid
      }
      getKlfLists(param).then(res => {
        console.log('所有知识库列表', res)
        // const newdata = {}
        // const zjlist = []
        const level1list = []
        res.data.forEach(item => {
          level1list.push(item.topCategoryName)
        })
        console.log('改装的一级', level1list)
        this.showlevel1list = level1list
        const newdata = []
        const all2data = []
        const nullklgname = []
        res.data.forEach(item => {
          if (!item.secondaryList || item.secondaryList.length == 0) {
            nullklgname.push(item.topCategoryName)
          } else {
            item.secondaryList.forEach(sitem => {
              all2data.push(sitem)
              if (newdata[item.topCategoryName]) {
                newdata[item.topCategoryName].push(sitem.range != 2 ? (sitem.knowledgeBaseName + '|' + sitem.knowledgeBaseId + '|' + sitem.randomNumber) : (sitem.knowledgeBaseAlias + '|' + sitem.knowledgeBaseId))
              } else {
                newdata[item.topCategoryName] = [sitem.range != 2 ? (sitem.knowledgeBaseName + '|' + sitem.knowledgeBaseId + '|' + sitem.randomNumber) : (sitem.knowledgeBaseAlias + '|' + sitem.knowledgeBaseId)]
              }
            })
          }




        })
        console.log('改装的2级', nullklgname)
        nullklgname.forEach(item => {
          newdata[item] = []
        })
        //   newdata['自建知识库'] = []
        //   if (item.range == 2) {
        //     Object.keys(this.indklglist).forEach(sitem => {
        //       if (this.indklglist[sitem].includes(item.name)) {
        //         if (!newdata[sitem]) {
        //           newdata[sitem] = []

        //         }
        //         newdata[sitem].push((this.indklgnamelist[item.name] || item.name) + '|' + item.id)
        //       }

        //     })
        //   } else {
        //     zjlist.push(item.name + '|' + item.id + '|' + item.randomNumber)
        //   }

        // })
        // newdata['自建知识库'] = zjlist
        console.log('改装过的klglist', newdata)
        this.showklglist = newdata;
        this.zjshowlist = newdata['自建知识库']
        if (this.level1 == '') {
          this.level1 = Object.keys(newdata)[0]
        }

        this.klglist = all2data;
        if (this.klged == 0) {
          this.klged = newdata[Object.keys(newdata)[0]][0].split('|')[1]
          this.klgname = newdata[Object.keys(newdata)[0]][0].split('|')[2] || newdata[Object.keys(newdata)[0]][0].split('|')[0]
          this.getklgcountdetail()
        }
        console.log(this.klged, this.klgname)

      })
    },
    drawend() {
      this.drag = false
      console.log('end', this.zjshowlist)
      const param = []
      this.zjshowlist.forEach((item, i) => {
        const it = item.split('|')
        param.push({
          kbId: it[1],
          sort: i + 1
        })
      })
      saveuserklgsort(param).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '序列已保存'
          });
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      })
    },
    level1click(e) {
      if (this.showklglist[e][0]) {
        this.level1 = e
        this.klged = this.showklglist[e][0].split('|')[1]
        this.klgname = this.showklglist[e][0].split('|')[2] || this.showklglist[e][0].split('|')[0]
        this.getklgcountdetail()
      } else {
        this.level1 = e
        this.$message.warning('请先创建自己的知识库')
        this.klged = 0
        this.klgname = ''
        this.getklgcountdetail()
      }

    },
    toeditklg(id) {
      this.editklgid = id;
      const klginfo = this.klglist.filter(item => { return item.knowledgeBaseId == id })[0]
      this.botname = klginfo.knowledgeBaseName
      this.isopen = klginfo.range == 1
      this.glbot = klginfo.robotIds || []
      console.log(id, klginfo)
      this.dialogVisible = true;
      this.fetchbotlist()

    },
    klgchange(e) {
      if (this.klged == e.split('|')[1]) return
      console.log(e)
      this.klged = e.split('|')[1]
      this.klgname = e.split('|')[2] || e.split('|')[0]
      this.getklgcountdetail()
    },
    getklgcountdetail() {
      getKlgCountbyname({ id: this.klged }).then(res => {
        console.log('单个知识库统计', res)
        this.klgdetaildata = res.data
      })
    },
    opencreate() {
      this.botname = ''
      this.isopen = false
      this.glbot = []
      this.dialogVisible = true;
      this.fetchbotlist()
    },
    handleClose() {
      this.dialogVisible = false
    },
    countallklg() {
      getAllKlgCount({ id: this.aibot.botid }).then(res => {
        console.log('所有知识库统计', res)
        this.allklgcountdata = res.data
      })
    },
    smenuclick(e) {
      this.smenu = e;
    },
    back() {
      this.$emit('changetype', 'ai')
    },
    klgsubmit() {
      if (this.editklgid == 0) {
        this.createklg()
      } else {
        this.editklg()
      }

    },
    createklg() {
      const params = {
        name: this.botname,
        range: this.isopen ? 1 : 0,
        robotIds: this.glbot,
        pertainRobotId: this.aibot.botid
      }
      createKlg(params).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '创建成功'
          });
          this.dialogVisible = false
          setTimeout(() => {
            this.getklglist()
          }, 200);
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }

      })
    },
    editklg() {
      const params = {
        id: this.editklgid,
        name: this.botname,
        range: this.isopen ? 1 : 0,
        robotIds: this.glbot
      }
      editKlg(params).then(res => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.dialogVisible = false
          this.editklgid = 0;
          setTimeout(() => {
            this.getklglist()
          }, 200);
        } else {
          this.$message.error(res.msg);
        }

      })
    },
    godel(id) {
      this.delid = id
      this.delVisible = true
    },
    todelklg() {
      delKlg({ id: this.delid }).then(res => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.delVisible = false
          setTimeout(() => {
            this.getklglist()
          }, 200);
        } else {
          this.$message.error(res.msg);
        }

      })
    }
  }
}
</script>
<style lang='less' scoped>
.klgmain {
  height: calc(100%-10px);
  width: 100%;
  border-radius: 10px 0 0 10px;
  padding: 20px;
  font-family: Alibaba PuHuiTi;

  ::-webkit-scrollbar {
    width: 5px;
    background-color: #ffffff;
  }


  /*竖直的滑块*/
  ::-webkit-scrollbar-thumb {
    background-color: #7daade;
    border-radius: 2px;
    border-top: 1px solid #7daade;
    border-bottom: 1px solid #7daade;
    border-left: 1px solid #7daade;
  }

  .bottop {
    width: 100%;
    height: 80px;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    padding: 20px 30px;
    font-size: 20px;

    .menuitem {
      width: 300px;
      height: 40px;
      cursor: pointer;
      background: linear-gradient(275deg, #22254d 0%, #272a57 100%);
      border-radius: 12px;
      color: white;
      text-align: center;
      line-height: 40px;
      margin-right: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;

    }

    .med {
      background: #0389F8;
    }

    /deep/ .el-tabs__item {
      font-size: 18px;
      text-decoration: 10px;
      letter-spacing: 2px;
    }

    .back {
      position: absolute;
      left: 20px;
      top: 15px;
      cursor: pointer;

      .icon {
        width: 50px;
      }
    }

    .botleft {
      width: calc(100% - 100px);
      height: 80px;
      padding: 10px 20px;
      background-color: white;
      font-size: 32px;
      text-align: center;
      font-weight: bold;
      border-radius: 8px;
      line-height: 60px;

    }

    .bottopright {
      width: 80px;
      height: 80px;
      background-color: white;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 32px;
      }
    }
  }


  .klgcontent {
    border-radius: 10px;
    height: calc(100% - 60px);

    .fbbox {
      position: relative;
      height: 50px;
      width: 50%;

      .fbtop {
        height: 40px;
        background: rgba(28, 34, 54, 0.06);
        border-radius: 6px;
        display: flex;
        width: fit-content;
        position: absolute;
        bottom: -2px;
        left: 20px;

        .fbitem {
          height: 32px;
          width: 100px;
          border-radius: 5px;
          line-height: 32px;
          text-align: center;
          cursor: pointer;
          margin: 4px;
          font-size: 16px;
          background: #f1f6ff;
          color: #333;
        }

        .checked {
          background: #0389F8;
          color: #fff;
        }
      }
    }


    .detailbox {
      padding: 0 20px;

      .detail {
        font-size: 24px;
        color: #fff;
        font-weight: bold;
        display: flex;
        margin-top: 10px;

        .detailitem {
          text-align: center;
          margin: 0 20px;

          .dname {
            color: #33333390;
            font-size: 16px;
          }

          .dvalue {
            font-size: 20px;
            background: linear-gradient(to right, #9953FF, #53A9FF);
            /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/
            -webkit-background-clip: text;
            /*将设置的背景颜色限制在文字中*/
            -webkit-text-fill-color: transparent;

            span {
              font-size: 14px
            }
          }
        }
      }

      .tcont {
        font-size: 16px;
        display: flex;
        flex-wrap: wrap;
        background-color: #fff;
        border: 1px solid #999;
        border-radius: 12px;
        margin-top: 10px;

        .drawbox {
          display: flex;
          flex-wrap: wrap;
        }

        .addklg {
          width: 30px;
          height: 30px;
          border: 2px solid #0389F8;
          color: #0389F8;
          background-color: #181B3C;
          font-size: 34px;
          padding-bottom: 6px;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin: 10px
        }

        .zskitem {
          color: #7A7A9D;
          margin: 10px 20px;
          height: 30px;
          line-height: 30px;
          cursor: pointer;
          position: relative;

          .righttoptip {
            font-size: 14px;
            font-weight: bolder;
            color: #FF009D;
            margin-left: 10px;
          }

          .righttoptip:hover+.sharediv {
            display: block;
          }

          .sharediv:hover {
            display: block;
          }

          .sharediv {
            position: absolute;
            font-size: 14px;
            right: 0px;
            top: 30px;
            border-radius: 4px;
            background-color: #151730;
            border: 1px solid #d22fa3;
            border-bottom: 0;
            color: #FF009D;
            display: none;

            .share {
              padding: 2px 5px;
              border-bottom: 1px solid #d22fa3;
            }

            .share:hover {
              background-color: #FF009D;
              color: white;
            }
          }


        }

        .zname {
          font-size: 18px;
          color: #0389F8;
          display: flex;
        }
      }
    }






  }

  .btnbox {
    display: flex;
    justify-content: center;

    .btnitem {
      width: 240px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: 20px;
      background-color: #263754;
      color: white;
      font-weight: bold;
      border-radius: 8px;
      margin: 20px;
      cursor: pointer;
    }
  }

  .mydialog {
    z-index: 2001;

    p {
      margin-top: 20px;
      margin-bottom: 10px;
      padding-left: 5px;
      font-size: 18px
    }


  }


}

/deep/ .el-radio__label,
/deep/ .el-input__inner {
  font-size: 18px
}

/deep/ .el-radio {
  padding: 10px !important
}

/deep/ .el-tag.el-tag--info {
  font-size: 16px
}

/deep/ .el-button--small {
  font-size: 14px;
}

.nodata {
  font-size: 13px;
  text-align: center;
  color: #fff;
}

.upwebtip {
  font-size: 15px;
  text-align: left;
  margin-bottom: 15px;
  color: #4E5E83;
}

.aidiv {
  width: 25px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  font-size: 13px;
  margin: 0 auto;
}

.wxitem {
  width: 100%;
  display: flex;

  .wxitenleft {
    width: 100px;
    text-align: left;
    line-height: 35px;
  }
}

.wxselectname {
  display: flex;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

/deep/ .el-table tr {
  background-color: #fff !important;
  color: #333 !important;
}

/deep/ .el-table th.el-table__cell {
  background-color: #fff !important;

  color: #FF009D;
}

/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf,
/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table--border {
  border: 1px solid #d0d0d0;
  border-right: 1px solid #d0d0d0;
  border-bottom: 1px solid #d0d0d0;
  color: #333 !important;
}


/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #fff;
}

/deep/ .el-pagination span {
  font-size: 15px
}


.el-table .el-table__body td,
.el-table .el-table__header th {
  border-right: none;
  border-bottom: none;
}

.el-table .el-table__body td:last-child,
.el-table .el-table__header th:last-child {
  border-right: 1px solid #dcdfe6;
  /* 根据需要可以添加或去掉这一行代码 */
}

.el-table .el-table__footer td,
.el-table .el-table__header th {
  border-bottom: none;
}

/deep/ .el-popover {
  position: absolute;
  background: #fff;
  min-width: 150px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  padding: 12px;
  z-index: 2000;
  color: #fff;
  line-height: 1.4;
  text-align: justify;
  font-size: 14px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  word-break: break-all;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active,
/deep/ .el-pagination.is-background .btn-next,
/deep/ .el-pagination.is-background .btn-prev,
/deep/ .el-pagination.is-background .el-pager li {
  background-color: #0988f6 !important;
  color: #fff;
}


/deep/ .el-pagination__jump,
/deep/ .el-pagination__total {
  color: #333;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #FF009D;
}

/deep/ .el-upload-dragger {
  background: linear-gradient(275deg, #181B40 0%, #181A37 100%);
  box-shadow: -2px -2px 2px #1E213B;
  border-radius: 5px;
  border-color: #1c2044;

}

/deep/ .el-upload-dragger .el-upload__text {
  color: white;
}

/deep/ .el-upload-dragger .el-upload__text em {
  color: #FF009D;
}

//去除右侧白线
.el-table--border::after {
  width: 0px;
}

.el-table__header-wrapper {
  background: none;
}

.el-table::before {
  background: none
}






/* 取消el-table鼠标移入高亮 */
.el-table__body tr:hover>td {
  background-color: transparent !important;
}

/deep/ .el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover,
/deep/ .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  background-color: #ff009d4f !important;
}

/deep/ .el-dialog {
  background: linear-gradient(180deg, #0e1341 0%, #181A37 100%);
  box-shadow: 3px 3px 3px rgba(17, 19, 38, 0.82);
  border-radius: 13px;

  .mydialog {
    color: #FFF;
    font-size: 18px;
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    p {
      width: 150px;
    }

    .el-input {
      width: 300px;
    }






  }

  .delmydialog {
    z-index: 2001;
    font-size: 16px;
    color: #ff009d;
    background-color: #121441;
    padding: 20px;
  }

}



/deep/ .el-tag.el-tag--info,
/deep/ .el-select .el-tag__close.el-icon-close::before {
  background-color: #181A37;
  border: 1px solid #FF009D;
  color: #FF009D;

}

/deep/ .el-select .el-tag__close.el-icon-close::before {
  border-radius: 50%;
}

/deep/ .el-select-dropdown {
  background-color: #181A37 !important;
}


/deep/ .el-input__inner,
/deep/ .el-textarea__inner {
  color: #333;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #fff;
}

/deep/ .el-select .el-input.is-focus .el-input__inner {
  border-color: r#fff !important;
}

/deep/ .el-button--default {
  border-radius: 3px;
  border: 1px solid #A30202;
  background: #1E213B;
  color: #A30202;

}


/deep/ .el-tabs__item {
  color: #F2F2F3;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-tabs__active-bar {
  background-color: #FF009D;
}

/deep/ .el-table__body-wrapper {
  background: #fff;
}

/deep/ .el-tabs__item.is-active {
  color: #FF009D;
}


/deep/ .el-dialog__title,
/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #FF009D !important;
  font-size: 22px
}
</style>
