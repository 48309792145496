<template>
  <div class="fxmainbox">
    <!-- @click="triggerUpload" -->
    <div v-if="!fileitem.url" style="text-align:right" class="upfilebtnbox">
      <el-button type="primary" icon="el-icon-circle-plus-outline" @click="triggerUpload">打开文件</el-button>

      <input type="file" style="display: none" ref="fxfileInput" @change="handleFileChange" />
      <div class="tabletitle">文件列表</div>
      <el-table :data="lenfilelist" v-loading="uploading" element-loading-text="努力上传中"
        element-loading-background="#ffffffe6" height="610" border style="width: 100%;margin-top: 20px;">
        <el-table-column prop="name" align="left" label="文件名称">
        </el-table-column>
        <el-table-column prop="createTime" label="上传时间" align="center" width="180" />
        <el-table-column prop="format" label="文件格式" align="center" width="120" />
        <el-table-column label="是否学习" align="center" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.isStudy == 0">未学习</span>
            <span v-else style="color: #038AFC;">已学习</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="220" align="center" v-if="roleid != 3">
          <template slot-scope="scope">
            <div style="display: flex;justify-content: center;align-items: center;">
              <div class="fxbtn" @click="gofx(scope.row, scope.row.id)">
                <img src="@/assets/images/klg/fx.png" alt=""> 分析
              </div>
              &nbsp;
              <el-popconfirm title="确定删除选中文件吗？" @confirm="dellenitem([scope.row.id])">
                <el-button slot="reference" size="large" type="text" v-if="roleid == 1"
                  style="font-size:14px">删除</el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="No" label="状态" width="180"> -->
        <!-- </el-table-column> -->
      </el-table>
      <el-pagination background style="margin-top: 10px;" page-size="10" :total="wdcount" @current-change="pagechange">
      </el-pagination>
    </div>
    <div v-else class="fxbox">
      <div class="backbtn" @click="backklg"><el-button type="primary" icon="el-icon-arrow-left">返回列表页</el-button></div>


      <div class="fxleft">
        <iframe class="myiframe" v-if="fileitem.url" :src="fileitem.url" frameborder="0"></iframe>
        <el-button type="text" v-else icon="el-icon-loading">文件加载中..</el-button>
      </div>
      <div class="fxright">
        <div class="rtop">
          <div class="rtopbox">
            <div :class="menu == item ? 'rtopitem rted' : 'rtopitem'" v-for="(item, i) in menulist" :key="i"
              @click="fxmenuclick(item, 0)">{{ item }}
            </div>
          </div>
          <div class="pptright" @click="downloadppt"><img src="../img/ppt.png" /></div>
        </div>
        <div class="contentbox">
          <div class="contenttop">
            <div class="title">{{ menu }}</div>
            <div class="clickbox" v-if="fxid != '' && menu != '阅读十问'">
              <div class="clickitem" @click="copy"><el-button size="small" type="text"
                  icon="el-icon-document-copy">复制</el-button></div>
              <div class="clickitem" @click="download"><el-button size="small" type="text"
                  icon="el-icon-download">下载</el-button></div>
              <div class="clickitem" @click="fxmenuclick(menu, 1)"><el-button size="small" type="text"
                  icon="el-icon-refresh">重新生成</el-button></div>

            </div>
          </div>
          <div class="content" id="resultbox">
            <div class="xmindbox" v-if="menu == '思维脑图'">
              <el-button type="text" v-if="xmindurl == ''" icon="el-icon-loading">脑图生成中..</el-button>
              <el-image v-else :src="xmindurl" :preview-src-list="srcList">
              </el-image>
            </div>
            <div class="zhuibox" v-else-if="menu == '阅读十问'">
              <div class="zhuiitem" v-for="(item, i) in zhuilist" :key="i" @click="sendmes(item)">
                <img src="../img/zhuiwenicon.png" />{{ item }}
              </div>
              <span v-if="i > 0" v-for="(item, i) in resultdatalist" :key="i">
                <div class="useritem" v-if="item.message.length > 0">
                  <div class="userright">
                    <div class="usertext">{{ item.message }}</div>
                  </div>
                </div>
                <div class="aiitem">
                  <div class="airight">
                    <div class="aitext">
                      <el-button type="text" v-if="item.reply_content.result == ''" icon="el-icon-loading">{{ menu ==
      '中英互译'
      ? 'loading..' : '生成中..' }}</el-button>
                      <vue-markdown v-else :source="item.reply_content.result" class="custom-markdown"></vue-markdown>
                    </div>
                  </div>
                </div>
              </span>
            </div>
            <span v-else>
              <span v-for="(item, i) in resultdatalist" :key="i">
                <div class="useritem" v-if="item.message.length > 0">
                  <div class="userright">
                    <div class="usertext">{{ item.message }}</div>
                  </div>
                </div>
                <div class="aiitem">
                  <div class="airight">
                    <div class="aitext">
                      <el-button type="text" v-if="item.reply_content.result == ''" icon="el-icon-loading">{{ menu ==
      '中英互译'
      ? 'loading..' : '生成中..' }}</el-button>
                      <vue-markdown v-else :source="item.reply_content.result" class="custom-markdown"></vue-markdown>
                    </div>
                  </div>
                </div>
              </span>
            </span>

          </div>
          <div class="sendbtn">
            <el-button type="danger" icon="el-icon-video-pause" @click="stopconn" v-if="isconn">停止生成</el-button>
            <div class="tipbox">内容由AI生成，仅供参考</div>
            <input type="text" id="qmess" class="sendinput" placeholder="点我开始智问" />
            <div class="mysenbtn" @click="senmessage"><img src="../img/send.png" alt=""></div>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import storage from '@/utils/localStorage'
import qs from 'qs'
import VueMarkdown from 'vue-markdown'
import { downloadfxfile, getxmind, getzwlist, getppt, getlearnlist, dellearnlist, getxmindbyurl } from '@/api/klg'
import { ppt2pdf } from '@/api/write'
import { baseUrl } from '@/axios/baseUrl'
export default {
  name: '',
  components: {
    VueMarkdown
  },
  data(props) {
    return {
      menulist: ['文章摘要', '中心思想', '心得感悟', '总结概述', '阅读十问', '思维脑图', '中英互译'], //'简介', '重点',
      menu: '文章摘要',
      conntype: '8',
      roleid: storage.getItem('userInfo'),
      fileitem: { url: null },
      eventSource: null,
      resultdatalist: [],
      lenfilelist: [],
      fxid: '',
      fyid: 0,
      copytxt: '',
      srcList: [],
      xmindurl: '',
      uploading: false,
      isconn: false,
      page: 1,
      zhuilist: [],
      fxitem: [],
      fxstatus: false
    }
  },
  props: {
  },
  mounted() {
    this.getlealist()
  },
  watch: {

  },
  methods: {
    pagechange(e) {
      console.log(e)
      this.page = e;
      this.getlealist()
    },
    dellenitem(id) {
      dellearnlist(id).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          setTimeout(() => {
            this.getlealist()
          }, 200);
        } else {
          this.$message({
            type: 'error',
            message: '删除失败!请稍后重试'
          });
        }
      });
    },

    getlealist() {
      getlearnlist(`page=${this.page}&size=100&robot_id=46`).then(res => {
        console.log(res);
        this.lenfilelist = res.data.records
        this.wdcount = res.data.totalRow
      })
    },
    fxmenuclick(item, t) {
      const dbz = { '中英互译': 9, '总结概述': 8 }
      // if (item == '中英互译' || item == '总结概述') {
      if (this.isconn) {
        this.$message.warning('AI正在分析，请先停止或者稍后再试吧~')
        return;
      }
      // }
      this.menu = item;
      if (item == '思维脑图') {
        this.getxminddata(t)
      } else if (item == '中英互译') {
        setTimeout(() => {
          this.getfxdatalist(t, 9)
        }, 200);
      } else if (item == '阅读十问') {
        setTimeout(() => {
          this.getzhuilist()
        }, 200);
      } else {
        setTimeout(() => {
          this.getfxdatalist(t, 8)
        }, 200);
      }
    },
    sendmes(msg) {
      document.getElementById('qmess').value = msg
      setTimeout(() => {
        this.senmessage()
      }, 200);
    },
    triggerUpload() {
      this.$refs.fxfileInput.click(); // 触发input的点击事件
    },
    getzhuilist() {
      getzwlist({ report_content: this.copytxt, question_num: 10 }).then(res => {
        console.log(res);
        this.zhuilist = res.related_questions
      })
    },
    senmessage() {
      const message = document.getElementById('qmess').value
      if (message == '') return;
      this.resultdatalist.push({
        message: message,
        reply_content: {
          result: ''
        }
      })
      const element = document.getElementById('resultbox')
      setTimeout(() => {
        element.scrollTo({
          x: 0,
          top: element.scrollHeight,
          behavior: 'smooth'
        })

        document.getElementById('qmess').value = ''
      }, 300)
      this.connectSever(message);
    },
    stopconn() {
      this.eventSource.close()
      this.isconn = false;
    },
    handleFileChange(event) {
      this.$message.success('文件上传中')
      this.uploading = true;
      const file = event.target.files[0]; // 获取选中的文件
      let xhr = new XMLHttpRequest();
      const formdata = new FormData();
      formdata.append('file', file)
      formdata.append('robot_id', 46)

      // xhr.open('POST', baseUrl + '/api/common/uploadFileToOSS', true);
      xhr.open('POST', baseUrl + '/queapi/file/oss', true);
      xhr.setRequestHeader('clientType', 'emergencyAIRobotClient')
      xhr.setRequestHeader('userPhone', '15070216300')
      xhr.send(formdata);
      const that = this;
      xhr.onreadystatechange = () => {
        console.log(xhr.status)
        if (xhr.readyState === 4 && xhr.status === 200) {

          const res = JSON.parse(xhr.responseText);
          if (res.code == 200) {
            this.uploading = false;
            this.$message.success('上传成功，正在进行文件分析')
            this.getlealist()
            if (res.message.includes('.pdf') || res.message.includes('.txt')) {
              this.fileitem.url = res.message
              setTimeout(() => {
                this.getfxdatalist()
              }, 200);
            } else {
              this.typechange(res.message)
            }

          } else {
            that.$message.error(res.message)
          }
        }
      }
    },
    typechange(url) {
      console.log(this.fileitem)
      ppt2pdf(qs.stringify({ file: url })).then(pdfres => {
        console.log('转换过的pdf', pdfres)
        if (pdfres.code == 200 && pdfres.success) {
          this.fileitem.url = pdfres.data.pdf
          setTimeout(() => {
            this.getfxdatalist()
          }, 200);
        }
      })
    },
    gofx(item, id) {
      if (item.url.includes('.pdf') || item.url.includes('.txt')) {
        this.fileitem = item
        this.getfxdatalist()
      } else {
        this.typechange(item.url)
      }

      // this.dalatenew(id)
    },
    connectSever(message) {
      const that = this;
      const url = `${baseUrl}/queapi/robot_writing/writings?source=问答&url=${this.fileitem.url}&query=${message}`
      that.eventSource = new EventSource(url)
      that.eventSource.addEventListener('open', (event) => {
        console.log('连接已建立', JSON.stringify(event))

      })
      let endflag = false;
      const lnum = that.resultdatalist.length - 1
      that.eventSource.onmessage = (event) => {


        if (event.data == '[DONE]' || endflag) {
          endflag = true;
          return;
        }

        that.resultdatalist[lnum].reply_content.result = that.resultdatalist[lnum].reply_content.result + (event.data.replace(/&nbsp;/g, ' ').replace(/\\n/g, '\n'))
        that.copytxt = that.copytxt + event.data.replace(/\\n/g, `
        `).replace(/\*/g, '').replace(/\#/g, '').replace(/&nbsp;/g, ' ')


        if (that.resultdatalist[lnum].reply_content.result.length % 10 == 0 || event.data.includes('，')) {
          that.$nextTick(() => {
            const element = document.getElementById('resultbox')
            element.scrollTo({
              x: 0,
              top: Number.MAX_SAFE_INTEGER,
              behavior: 'smooth'
            })
          })
        }
      }
      that.eventSource.onerror = (event) => {
        console.log(event)
        that.eventSource.close()
        that.$nextTick(() => {
          const element = document.getElementById('resultbox')
          element.scrollTo({
            x: 0,
            top: Number.MAX_SAFE_INTEGER,
            behavior: 'smooth'
          })
        })
      }
    },
    getfxdatalist(t, k) {
      if (!this.isconn) {
        this.resultdatalist = [];
        this.copytxt = ''
      } else {
        return
      }
      this.conntype = k
      const that = this;
      let url = '';
      if (t == 1) {
        url = this.menu == '中英互译' ? `${baseUrl}/queapi/robot_writing/translate?source=文章摘要&id=${this.fyid}&is_new=1` : `${baseUrl}/queapi/robot_writing/writings?source=${this.menu}&url=${this.fileitem.url}&id=${this.fxid}`
      } else {
        url = this.menu == '中英互译' ? `${baseUrl}/queapi/robot_writing/translate?source=文章摘要&id=${this.fyid}` : `${baseUrl}/queapi/robot_writing/writings?source=${this.menu}&url=${this.fileitem.url}`
      }

      that.eventSource = new EventSource(url)
      that.resultdatalist.push({
        message: '',
        reply_content: {
          result: ''
        }
      })

      that.isconn = true;
      that.eventSource.addEventListener('open', (event) => {
        that.isconn = true;
        console.log('连接已建立', JSON.stringify(event))

      })
      const lnum = that.resultdatalist.length - 1
      let endflag = false
      that.eventSource.onmessage = (event) => {
        if (event.data == '[DONE]' || endflag) {
          that.isconn = false;
          endflag = true
          that.fxid = event.data
          if (that.fyid != 0 && that.fyid != '[DONE]') {
            return
          }
          that.fyid = event.data
        } else {
          that.resultdatalist[lnum].reply_content.result = that.resultdatalist[lnum].reply_content.result + (event.data.replace(/&nbsp;/g, ' ').replace(/\\n/g, '\n'))
          that.copytxt = that.copytxt + event.data.replace(/\\n/g, `
          `).replace(/\*/g, '').replace(/\#/g, '').replace(/&nbsp;/g, ' ')
        }

        if (that.resultdatalist[lnum].reply_content.result.length % 10 == 0 || event.data.includes('，')) {
          that.$nextTick(() => {
            const element = document.getElementById('resultbox')
            element.scrollTo({
              x: 0,
              top: Number.MAX_SAFE_INTEGER,
              behavior: 'smooth'
            })
          })
        }
      }
      that.eventSource.onerror = (event) => {
        console.log(that.resultdatalist)
        that.isconn = false;
        that.eventSource.close()
        that.$nextTick(() => {
          const element = document.getElementById('resultbox')
          element.scrollTo({
            x: 0,
            top: Number.MAX_SAFE_INTEGER,
            behavior: 'smooth'
          })
        })
      }
    },
    async copy() {
      if (this.menu == '思维脑图') {
        try {
          const response = await fetch(this.xmindurl);
          const blob = await response.blob();
          await navigator.clipboard.write([
            new ClipboardItem({
              [blob.type]: blob,
            }),
          ]);
          this.$message.success('图片复制成功')
        } catch (error) {
          this.$message.error('复制错误' + error);
        }
      } else {
        const textArea = document.createElement('textarea');
        setTimeout(() => {
          textArea.value = this.copytxt;
          document.body.appendChild(textArea);
          textArea.select();

          try {
            const successful = document.execCommand('copy');
            if (successful) {
              this.$message.success('复制成功');
            } else {
              this.$message.error('复制失败');
            }
          } catch (err) {
            this.$message.error('复制出现错误', err);
          }

          document.body.removeChild(textArea);
        }, 300);
      }

    },
    backklg() {
      this.fileitem.url = null
    },
    download() {
      if (this.menu == '思维脑图') {
        window.open(this.xmindurl)
      } else {
        this.$message.success('正在生成下载链接');
        downloadfxfile(this.fxid).then(res => {

          window.open(res.data.pdf_url)
        })
      }
    },
    getxminddata(t) {
      this.$message.success('正在生成脑图');
      this.srcList = []
      this.xmindurl = ''
      getxmindbyurl(qs.stringify({ is_new: t, url: this.fileitem.url })).then(res => {
        this.srcList = [res.data.view_image_url]
        this.xmindurl = res.data.view_image_url
      })
    },
    downloadppt() {
      this.$message.success('正在生成PPT');
      getppt(this.fxid).then(res => {
        window.open(res.data.ppt_url)
      })
    },

  }
}
</script>
<style lang='less' scoped>
.fxmainbox {
  width: 100%;
  border-radius: 10px 0 0 10px;
  font-family: 'pingfang', 'Source Han Sans CN', Avenir, Helvetica, Arial, sans-serif !important;

  height: calc(100% - 20px);
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  padding: 10px;
  margin-top: 10px;

  .fxbox {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .upfilebtnbox {
    .fxbtn {
      display: flex;
      align-items: center;
      width: 70px;
      color: #038AFC;
      cursor: pointer;

      img {
        width: 20px;
      }
    }

    .tabletitle {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding-left: 30px;
      position: relative;
      font-size: 20px;
      color: #333;

      &::before {
        position: absolute;
        content: "";
        width: 16px;
        height: 16px;
        background: linear-gradient(90deg, #038AFC 0%, #0053D7 100%);
        border-radius: 50%;
        left: 0;
        top: 6px;
      }
    }

    /deep/ .el-button--primary {
      background: linear-gradient(270deg, #0389F8 0%, #0052D8 100%);
      font-size: 18px;
    }
  }

  .backbtn {
    width: 160px;
    height: 50px;
    position: fixed;
    right: 4px;
    top: 10px;
    cursor: pointer;
    z-index: 3;

    /deep/ .el-button--primary {
      background: linear-gradient(270deg, #0389F8 0%, #0052D8 100%);
      font-size: 18px;
    }
  }

  .fxleft {
    width: 50%;
    height: 100%;
    padding: 20px;
    background-color: #fff;

    .myiframe {
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  .fxright {
    margin-left: 20px;
    width: calc(50% - 20px);
    background-color: #fff;

    .rtop {
      display: flex;
      justify-content: space-between;
      background: #FFFFFF;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
      padding: 10px;

      .rtopbox {
        width: 100%;

        display: flex;
        padding: 0;


        .rtopitem {
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 15px;
          cursor: pointer;
        }

        .rted {
          color: #0064E2;
          border-bottom: 2px solid #0064E2;
        }
      }

      .pptright {
        border-left: 1px solid #EAEBED;
        padding-left: 20px;

        img {
          width: 40px;
        }
      }
    }

    .contentbox {
      width: 100%;
      height: calc(100% - 80px);

      padding: 20px;
      position: relative;

      .contenttop {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          font-size: 20px;
          font-weight: bold;
        }

        .clickbox {
          display: flex;

          .clickitem {
            margin: 0 10px;
          }
        }
      }

      .content {
        width: 100%;
        height: calc(100% - 130px);
        overflow-y: auto;

        .custom-markdown {
          width: 100%;
          height: 100%;
          font-size: 18px;
          overflow-y: auto;
          word-break: break-all;
        }

        .custom-markdown {
          /deep/ .table {
            border-collapse: collapse;
            border-spacing: 0;
          }

          /deep/ .table {
            border-top: 1px solid #333;
            border-left: 1px solid #333;
          }

          /deep/ .table tbody tr td,
          /deep/ .table thead tr th {
            border-right: 1px solid #333;
            border-bottom: 1px solid #333;
            padding: 6px;
          }
        }

        .xmindbox {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;

          img {
            width: 100%;
          }
        }

        .zhuibox {
          .zhuiitem {
            display: flex;
            align-items: center;
            padding: 10px;
            margin: 10px 0;
            background-color: #1C223610;
            font-size: 18px;
            border-radius: 10px;
            cursor: pointer;

            img {
              width: 20px;
              margin-right: 12px;
            }
          }
        }

        .aiitem {
          display: flex;
          width: calc(100% + 20px);
          position: relative;
          margin: 20px 0;


          .airight {
            min-width: 180px;
            max-width: calc(100% - 20px);
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding-left: 10px;
            text-align: left;
            margin-bottom: 10px;
            background: #F8F8F9;
            border-radius: 0px 12px 12px 12px;
            position: relative;



            .name {
              font-size: 18px;
              color: #333333;
              margin-bottom: 10px;

              span {
                font-size: 12px;
                background-color: #ebf0f3;
                border-radius: 6px;
                padding: 4px 8px;
                margin-left: 10px;
              }
            }

            .aitext {
              // width: 500px;
              width: 100%;
              color: #333333;
              padding: 10px 20px;
              font-size: 18px;

              .loadinggif {
                height: 52px;

                img {
                  height: 52px;
                }
              }


              .custom-markdown code,
              .custom-markdown pre,
              .custom-markdown pre code {
                white-space: break-spaces;
                word-break: break-all;
                /* 换行 */
              }


            }


          }
        }

        .useritem {
          position: relative;
          color: white;
          width: calc(100% - 10px);
          display: flex;
          justify-content: flex-start;
          flex-direction: row-reverse;



          .userright {
            // background: #FF009D;
            padding-right: 10px;
            text-align: left;
            float: right;
            margin: 10px 0;
            max-width: 800px;
            margin-left: 40px;

            .usertext {
              background: #005EDE;
              color: white;
              border-radius: 12px 0px 12px 12px;
              font-size: 16px;
              padding: 10px 20px;
              white-space: pre-wrap;
            }
          }
        }


      }

      .sendbtn {
        width: calc(100% - 40px);
        position: absolute;
        bottom: 0px;
        left: 20px;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        padding-left: 10px;

        .sendinput {
          width: calc(100% - 155px);
          height: 100%;
          font-size: 16px;
          padding-left: 16px;
          border: 0;
          outline: none;
          font-family: 'pingfang', 'Source Han Sans CN', Avenir, Helvetica, Arial, sans-serif !important;
          resize: none;
          background-color: transparent;
          white-space: pre-wrap;
          white-space: pre-wrap;
        }

        .tipbox {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: rgba(111, 114, 133, 0.5);
          position: absolute;
          right: 0;
          top: -35px;
        }

        .mysenbtn {
          width: 50px;
          height: 50px;
          background: linear-gradient(90deg, #038AFC 0%, #0053D7 100%);
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          img {
            width: 27px;
          }
        }
      }
    }

  }

}

.custom-markdown code,
.custom-markdown p,
.custom-markdown pre,
.custom-markdown pre code {
  white-space: break-spaces;
  word-break: break-all;
  /* 换行 */
}

/deep/ .el-table th.el-table__cell {
  background-color: #F2F2F3;
}

/deep/ .custom-markdown p,
/deep/ .custom-markdown ol,
/deep/ .custom-markdown li {
  margin-bottom: 18px;
}
</style>
