<template>
  <div class="aimain">
    <div class="createmain" v-if="!isadd">
      <div class="citem createbtn">
        <div class="addicon">
          <img src="./img/wt.png" />
        </div>
        <div class="aicontent" @click="goadd">
          <p class="btn">点击创建机器人</p>
          <div class="content cet">剩余可创建 {{ accnum }}/{{ allnum }}</div>
        </div>
      </div>
      <div class="citem" v-for="(item, i) in botlist" :key="i" v-if="!item.industry">
        <div class="righttoptip">···</div>
        <div class="sharediv">
          <div class="share edit" @click="edit(item)">修改</div>
          <div class="share" @click="share(item)">分享</div>
          <div class="share" @click="opendel(item.id)">删除</div>
        </div>
        <div class="aihead">
          <img :src="item.profilePicture == 'default.png'
      ? require('./img/morenhead.png')
      : item.profilePicture
      " alt="" />
        </div>
        <div class="aicontent">
          <p class="name">{{ item.name }}</p>
          <div class="content">
            {{ item.introduce }}
          </div>
        </div>
        <div class="witembtm">
          <div class="creator">
            <img src="./img/myicon.png" /><span>创建者</span>{{ item.creatorName || '官方' }}
          </div>
          <div class="creatime">
            <span>创建时间</span>{{ item.updateTime.slice(0, 10) || '' }}
          </div>
          <div class="wbtnitem" @click="gotalk(item)">
            <img src="./img/jinru.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <CreateBot v-else @back="back" @goxt="goxt" :botdetail="botdetail" />

    <el-dialog title="分享中心" :modal-append-to-body="false" :visible="shareflag" :before-close="handleClose"
      width="1000px">
      <div class="sharebox">
        <div class="shareitem" @click="opencopylink">
          <div class="stitle">网页</div>
          <div class="icon"><img src="./img/mybot/web.png" /></div>
          <div class="scontent">用户访问链接可以直接和您的机器人对话</div>
          <div class="sclickbox">复制链接</div>
        </div>
        <div class="shareitem" @click="openhaibao">
          <div class="stitle">朋友圈</div>
          <div class="icon"><img src="./img/mybot/wx.png" /></div>
          <div class="scontent">用户扫码后可直接与您的机器人对话</div>
          <div class="sclickbox">生成海报</div>
        </div>
        <!-- <div class="shareitem">
          <div class="stitle">JS文件</div>
          <div class="icon"><img src="./img/mybot/js.png" /></div>
          <div class="scontent">可添加到网页任何为止，将次机器人嵌入到您的网页中</div>
          <div class="sclickbox">查看代码</div>
        </div> -->
      </div>
    </el-dialog>
    <el-dialog title="复制链接" :modal-append-to-body="false" :visible="copylinkflag" :before-close="linkhandleClose"
      width="600px">
      <div class="copulinkbox">
        <div class="linkbox">
          <div class="link">{{ copylinktxt }}</div>
          <div class="copybtn" @click="copyText">
            <img src="./img/copybtn.png" />复制链接
          </div>
        </div>
        <div class="qrcodebox">
          <img id="myqrcode" style="height: 150px; margin-top: 20px" :src="qrcodesrc" />
        </div>
        <div class="qrcodebox">
          <div class="copybtn" @click="copyImageToClipboard">
            <img src="./img/copybtn.png" />点击复制
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="朋友圈海报" :modal-append-to-body="false" :visible="openhaibaoflag" :before-close="closehaibao"
      width="458px">
      <div class="copulinkbox">
        <canvas id="myCanvas" width="416" height="561"></canvas>
        <div class="qrcodebox">
          <div class="copybtn">
            <img src="./img/copybtn.png" />点击右键复制海报~
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="删除" :modal-append-to-body="false" :visible="delflag" :before-close="handleClose" width="550px">
      <div class="textbox">
        该机器人名下存在知识库文件，删除后文件同 步删除，是否继续？
      </div>
      <div class="btnbox">
        <div class="btnitem goxt" @click="goxt">前往知识库</div>
        <div class="btnitem goxt" @click="cancel">取消</div>
        <div class="btnitem skip" @click="del">确认删除</div>
      </div>
    </el-dialog>
    <el-dialog title="创建新的机器人" :modal-append-to-body="false" :visible="payVisible" :before-close="handlePayClose"
      width="861px">
      <div class="pay-content">
        <div class="selection-wrapper">
          <div class="selection-item robot">
            <div class="title">可创建机器人数</div>
            <img src="./img/pay/robot_num.png" alt="机器人" class="img" />
            <el-input-number v-model="orderData.robotNum" :precision="0" :step="1" :min="0" :max="5"></el-input-number>
          </div>
          <div class="selection-item elec">
            <div class="title">电力值</div>
            <img src="./img/pay/elec_num.png" alt="机器人" class="img" />
            <el-input-number v-model="orderData.elecNum" :precision="0" :step="1" :min="0"
              :max="2000"></el-input-number>
          </div>
        </div>
        <div class="pay-detail">
          <div class="qrcode-wrapper">
            <div class="pay-type-item wxpay" :class="[payType == 'wx' && 'active']" @click="payTypeChange('wx')">
              <span class="pay-icon"></span>
              <div class="text">微信支付</div>
            </div>
            <div class="pay-type-item alipay" :class="[payType == 'ali' && 'active']" @click="payTypeChange('ali')">
              <span class="pay-icon"></span>
              <div class="text">支付宝支付</div>
            </div>
          </div>
          <div class="qrcode-detail">
            <div class="left">
              <div class="title">应付金额</div>
              <div class="money-num">￥{{ getTotal }}</div>
              <div class="tip">
                支付即视为您同意《晓知因服务协议》和《晓知因授权许可协议》
              </div>
            </div>
            <div class="right">
              <div class="top-tip"><i class="el-icon-warning"></i>购买须知</div>
              <div class="pay-button" @click="handlePay()">立即支付</div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import myft from './font/优设标题黑.ttf'
import myfc from './font/Alibaba-PuHuiTi-Regular.ttf'
import { Base64 } from 'js-base64';
import QRCode from 'qrcode'
import CreateBot from './createBot.vue'
import { getBotList, robootDel, getbotqrcode } from '@/api/bot'
import { robootPriceInfo, aliPayPage, wxPay, getWxPayStatus } from '@/api/pay'
export default {
  name: '',
  components: { CreateBot },
  data() {
    return {
      isadd: false,
      shareflag: false,
      botlist: [],
      accnum: 0,
      allnum: 0,
      botid: 0,
      botdetail: [],
      delflag: false,
      payVisible: false,
      orderData: {
        robotId: null,
        robotPrice: 1,
        robotNum: 2,
        elecPrice: null,
        elecPrice: 1,
        elecNum: 1000
      },
      productList: [],
      qrcodesrc: '',
      payType: 'wx',
      payingOrderNo: null,
      timer: null,
      copylinkflag: false,
      openhaibaoflag: false,
      copylinktxt: '',
      boted: [],
      userInfo: JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  mounted() {
    this.fetchbotlist()
  },
  props: {
    mybototalk: { type: Function }
  },
  computed: {
    getTotal() {
      const sum = this.orderData.robotNum * this.orderData.robotPrice + this.orderData.elecNum * this.orderData.elecPrice
      return String((sum / 100)).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  watch: {

  },
  methods: {
    fetchbotlist() {
      getBotList().then(res => {
        console.log('botlist', res)
        this.accnum = res.data.accessRobotCount
        this.allnum = res.data.purchaseRobotCount
        this.botlist = res.data.robotList
      })
    },
    linkhandleClose() {
      this.copylinkflag = false

    },
    opencopylink() {
      this.copylinkflag = true
      getbotqrcode({ robotId: this.boted.id }).then(res => {
        console.log(res, Base64.decode('MTM='))

        this.qrcodesrc = res.data.replace('https://www.airer.com/hcQybOssFile', 'https://gpt-all.web.guanyingis.com/qrcode')
      })

    },

    closehaibao() {
      this.openhaibaoflag = false
    },
    openhaibao() {
      this.openhaibaoflag = true;


      getbotqrcode({ robotId: this.boted.id }).then(res => {
        console.log(res)
        setTimeout(() => {
          var c = document.getElementById("myCanvas");
          const that = this;
          console.log(c)
          var ctx = c.getContext("2d");
          let botcontent = that.boted.synopsis || that.boted.introduce
          botcontent = botcontent.length > 80 ? (botcontent.substr(0, 80) + '...') : botcontent
          var img1 = new Image();
          img1.src = require("./img/hbbg.png"); //背景图
          img1.onload = function () {

            new FontFace('myfc', 'url(' + myfc + ')')
            ctx.drawImage(img1, 0, 0, 416, 561)
            var img2 = new Image();
            img2.src = res.data
            img2.onload = function () {
              ctx.drawImage(img2, 40, 390, 130, 130)

              const mft = new FontFace('myft', 'url(' + myft + ')')
              mft.load().then(font => {
                document.fonts.add(font);
                ctx.fillStyle = '#f2f2f2';
                ctx.textAlign = 'left';
                ctx.font = 'normal 22px myft';
                ctx.fillText(that.boted.fullName || 'AI机器人', 140, 90);
              })

              const mfc = new FontFace('myfc', 'url(' + myfc + ')')
              mfc.load().then(font => {
                document.fonts.add(font);
                ctx.fillStyle = '#ffffff';
                ctx.textAlign = 'left';
                ctx.font = 'normal 22px myfc';
                ctx.fillText(that.boted.name, 140, 50);
                ctx.fillStyle = '#ffffff';
                ctx.font = 'normal 16px myfc';
                that.drawtext(ctx, botcontent, 50, 205, 288)
                ctx.font = 'normal 20px myfc';
                ctx.fillText(that.userInfo.nickname || (that.userInfo.phone.substr(0, 3) + '****' + that.userInfo.phone.substr(7, 4)), 130, 190);
              })




              var img4 = new Image();
              img4.src = require("./img/myvip.png");
              img4.onload = function () {
                ctx.drawImage(img4, 260, 172, 40, 20);
              }

              var img3 = new Image();
              img3.src = that.boted.profilePicture.includes('http') ? that.boted.profilePicture : that.boted.profilePicture == 'default.png' ? require('./img/morenhead.png') : that.boted.profilePicture;
              img3.onload = function () {
                if (that.boted.industry) {
                  ctx.drawImage(img3, 20, 10, 100, 120);
                } else {
                  ctx.drawImage(img3, 20, 10, 100, 100);
                }

              }

              var img5 = new Image();
              img5.src = require('./img/morenhead.png');
              img5.onload = function () {
                ctx.drawImage(img5, 50, 152, 50, 50);
              }

            }
          }
        }, 200);
      })



      // function saveAsPNG(canvas) {
      // 	return canvas.toDataURL("image/png");
      // }

      // function downLoad(url) {
      // 	var oA = document.createElement("a");
      // 	oA.download = ''; // 设置下载的文件名，默认是'下载'
      // 	oA.href = url;
      // 	document.body.appendChild(oA);
      // 	oA.click();
      // 	oA.remove(); // 下载之后把创建的元素删除
      // }

      // function saveAsLocalImage() {
      // 	downLoad(saveAsPNG(myCanvas));
      // }

    },
    // 文字换行
    drawtext(ctx, t, x, y, w) {
      //参数说明
      //ctx：canvas的 2d 对象，t：绘制的文字，x,y:文字坐标，w：文字最大宽度
      let chr = t.split("")
      let temp = ""
      let row = []
      CanvasRenderingContext2D.prototype.fillTextWithSpacing =
        function (text, x, y, spacing = 0) {
          // 如果间距为0，则不用对每个字符单独渲染以节省性能
          if (spacing === 0) {
            this.fillText(text, x, y);
            return;
          }

          let totalWidth = x; // 已渲染字符所占用的宽度
          // 对每个字符单独渲染
          for (let i = 0; i < text.length; i++) {
            this.fillText(text[i], totalWidth, y);
            //累计已占用宽度
            totalWidth += ctx.measureText(text[i]).width + spacing;
          }
        }

      for (let a = 0; a < chr.length; a++) {
        if (ctx.measureText(temp).width < w && ctx.measureText(temp + (chr[a])).width <= w) {
          temp += chr[a];
        } else {
          row.push(temp);
          temp = chr[a];
        }
      }
      row.push(temp)
      for (let b = 0; b < row.length; b++) {
        ctx.fillTextWithSpacing(row[b], x, y + (b + 1) * 28, 2);//每行字体y坐标间隔20
      }

    },

    copyText() {
      const textArea = document.createElement('textarea');
      setTimeout(() => {
        textArea.value = this.copylinktxt;
        document.body.appendChild(textArea);
        textArea.select();

        try {
          const successful = document.execCommand('copy');
          if (successful) {
            this.$message.success('链接复制成功');
          } else {
            this.$message.error('链接复制失败');
          }
        } catch (err) {
          this.$message.error('链接复制出现错误', err);
        }

        document.body.removeChild(textArea);
      }, 300);

    },
    async copyImageToClipboard() {
      try {
        const response = await fetch(this.qrcodesrc);
        const blob = await response.blob();
        await navigator.clipboard.write([
          new ClipboardItem({
            [blob.type]: blob,
          }),
        ]);
        this.$message.success('图片复制成功')
      } catch (error) {
        this.$message.error('复制错误', error);
      }
    },
    edit(e) {
      console.log(e)
      this.isadd = true
      this.botdetail = e
    },
    gotalk(item) {
      console.log(item)
      this.$emit('mybototalk', item)
    },
    back() {
      this.isadd = false
      this.fetchbotlist()
    },
    async goadd() {
      if (this.accnum <= 0) {
        // 去支付 获取机器人数量
        const res = await robootPriceInfo("晓知因-自建机器人")
        if (res.code === 200) {
          const robotData = res.data
          robotData.forEach(element => {
            if (element.id === 1) {
              this.orderData.robotId = element.id
              this.orderData.robotPrice = element.price
            } else if (element.id === 2) {
              this.orderData.elecId = element.id
              this.orderData.elecPrice = element.price
            }
          });
        }
        this.payVisible = true
      } else {
        this.isadd = true
      }
      // this.isadd = true
    },
    cancel() {
      this.delflag = false
    },
    share(item) {
      console.log(item)
      this.shareflag = true;
      this.boted = item;
      this.copylinktxt = 'https://gpt-all.web.guanyingis.com/#/experience?s=' + Base64.encode(item.id + '|' + this.userInfo.phone)
    },
    handleClose() {
      this.shareflag = false;
      this.delflag = false;
    },
    opendel(id) {
      this.botid = id;
      this.delflag = true
    },
    del() {
      const params = {
        id: this.botid
      }
      console.log(params)
      robootDel(params).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '删除机器人成功'
          });
          this.delflag = false
          setTimeout(() => {
            this.fetchbotlist()
          }, 200);
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      })
    },
    goxt() {
      this.$emit('goxt', '')
    },
    handlePayClose() {
      this.payVisible = false;
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      if (this.payingOrderNo) this.payingOrderNo = null
    },
    payTypeChange(type) {
      this.payType = type;
    },
    async handlePay() {
      const dz_url = window.location.href.split('#')[0]
      const products = `${this.orderData.robotId},${this.orderData.robotNum};${this.orderData.elecId},${this.orderData.elecNum}`
      if (this.payType === 'wx') {
        const res = await wxPay({ products: products })
        if (res.code === 200) {
          this.payingOrderNo = res.data.orderNo
          const orderNo = res.data.orderNo
          const qrUrl = res.data.codeUrl
          if (this.payingOrderNo) this.getPayStatus()
          window.open(`${dz_url}#/wxpay?orderNo=${orderNo}&amount=${this.getTotal}&qrUrl=${encodeURIComponent(qrUrl)}`)
        }

      } else {
        let params = {
          products: products,
          return_url: `${dz_url}#/payResult?payType=ali`
        }
        aliPayPage(params).then(res => {
          console.log(res)
          if (res.code === 200) {
            const { data } = res
            this.payingOrderNo = data.orderNo
            if (this.payingOrderNo) this.getPayStatus()
            // document.write(data.formStr)
            let divForm = document.getElementsByTagName('divform')
            if (divForm.length) {
              document.body.removeChild(divForm[0])
            }
            const div = document.createElement('divform');
            div.innerHTML = data.formStr;
            document.body.appendChild(div);
            document.forms[0].setAttribute('target', '_blank')
            document.forms[0].submit();
          }
        })
      }
    },
    getPayStatus() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.getOrderStatus()
      }, 3 * 1000)
    },
    async getOrderStatus() {
      const res = await getWxPayStatus(this.payingOrderNo)
      if (res.code === 200 && res.success) {
        this.$message({
          type: 'success',
          message: '支付成功!'
        });
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        this.handlePayClose()
      } else {
        this.getPayStatus()
      }
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
      this.payingOrderNo = null
    }
  }
}
</script>
<style lang='less' scoped>
.aimain {
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  display: flex;

  .createmain {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 40px;

    .citem {
      width: calc(33% - 40px);
      height: 140px;
      margin: 20px;
      background: #fff;
      border-radius: 9px;
      cursor: pointer;
      text-align: center;
      padding: 20px;
      border-radius: 8px;
      position: relative;
      display: flex;
      flex-wrap: wrap;

      .righttoptip {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 18px;
        font-weight: bolder;
        color: #5b9dff;
      }

      .righttoptip:hover+.sharediv {
        display: block;
      }

      .sharediv:hover {
        display: block;
      }

      .witembtm {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 0px 0 10px;
        padding-top: 10px;

        .creatime,
        .creator {
          color: #979bcb;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;

          img {
            width: 18px;
            height: 18px;
          }
        }

        span {
          color: #333;
          padding: 0 6px;
        }

        .wbtnitem {
          display: flex;
          font-size: 20px;
          color: #a3a4a8;
          cursor: pointer;
          // border: 1px solid #FF009D;
          border-radius: 10px;
          padding: 4px 0px 4px 50px;
          color: #ff009d;
          // background-color: #0e1341;
          height: 30px;

          img {
            height: 25px;
          }
        }
      }

      .sharediv {
        position: absolute;
        right: 10px;
        top: 30px;
        border-radius: 4px;
        background-color: #fff;
        border: 1px solid #5b9dff;
        border-bottom: 0;
        color: #5b9dff;
        display: none;
        z-index: 1;

        .share {
          padding: 8px 15px;
          border-bottom: 1px solid #5b9dff;
        }

        .share:hover {
          background-color: #5b9dff;
          color: white;
        }
      }

      .aicontent {
        width: calc(100% - 150px);
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 2px;
        height: 35px;
        margin-left: 20px;
        text-align: left;
        margin-top: -12px;

        .name {
          font-size: 24px;
          color: #5b9dff;
          background: linear-gradient(90deg, #3a02c8 0%, #5ea7ff 100%);
          -webkit-background-clip: text;
          /*将设置的背景颜色限制在文字中*/
          -webkit-text-fill-color: transparent;
          /*给文字设置成透明*/
          margin-top: 5px;
        }

        .btn {
          font-size: 16px;
          text-align: center;
          border-radius: 6px;
          line-height: 40px;
          margin-bottom: 10px;
          margin-top: 10px;
          width: calc(100% - 10px);
          height: 40px;
          background: linear-gradient(180deg, #9c60ff 0%, #298eff 100%);
          letter-spacing: 2px;
          color: white;
        }

        .cet {
          text-align: center;
        }

        .content {
          color: #616161;
          overflow: hidden;
          text-overflow: ellipsis;
          /* 将对象作为弹性伸缩盒子模型显示 */
          display: -webkit-box;
          /* 限制在一个块元素显示的文本的行数 */
          /* -webkit-line-clamp 其实是一个不规范属性，使用了WebKit的CSS扩展属性，该方法适用于WebKit浏览器及移动端；*/
          -webkit-line-clamp: 2;
          /* 设置或检索伸缩盒对象的子元素的排列方式 */
          -webkit-box-orient: vertical;
        }
      }

      .aihead {
        width: 70px;
        height: 70px;
        padding-left: 10px;
        margin: 0 auto;
        font-size: 18px;
        border-radius: 8px;
        letter-spacing: 2px;

        img {
          width: 70px;
          height: 70px;
          margin-left: -18px;
          border-radius: 8px;
        }
      }
    }

    .createbtn {
      .addicon {
        width: 180px;
        height: 100px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        img {
          color: #abafdb;
          width: 180px;
          height: 100px;
        }
      }

      .aicontent {
        width: 50%;
        height: 100px;
        padding: 20px 20px;
      }

      .addtxt {
        height: 35px;
        font-size: 17px;
        color: deepskyblue;
        font-weight: 300;
        letter-spacing: 2px;
        margin-top: 20px;
      }
    }

    .citem:hover {
      transform: translateY(-2px);
    }
  }
}

.clearfix {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
}

/deep/ .el-dialog {
  background: #fff;
  border-radius: 13px;

  .sharebox {
    display: flex;
    color: #333;
    justify-content: space-between;

    .shareitem {
      width: 500px;
      margin: 0 20px;
      background: #fff;
      border-radius: 10px;
      padding: 20px;

      .stitle {
        font-weight: bold;
        font-size: 22px;
        text-align: center;
      }

      .icon {
        width: 140px;
        margin: 30px auto;
        display: flex;
        justify-content: center;

        img {
          height: 140px;
        }
      }

      .scontent {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        width: 100%;
        margin: 20px auto;
      }

      .sclickbox {
        width: 121px;
        height: 40px;
        background: #fff;
        border-radius: 4px;
        line-height: 40px;
        margin: 0 auto;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .copulinkbox {
    display: flex;
    flex-wrap: wrap;
    color: #ff009d;
    justify-content: space-between;

    .linkbox {
      display: flex;

      .link {
        background: #fff;
        border-radius: 5px;
        padding: 10px;
        font-size: 14px;
        width: 430px;
      }

      .copybtn {
        display: flex;
        align-items: center;
        margin-left: 20px;
        background: #fff;
        border-radius: 2px;
        padding: 10px;
        cursor: pointer;

        img {
          width: 15px;
          margin-right: 10px;
        }
      }
    }

    .qrcodebox {
      width: 100%;
      padding: 10px;
      display: flex;
      justify-content: center;

      .copybtn {
        display: flex;
        align-items: center;
        margin-left: 20px;
        background: #fff;
        border-radius: 2px;
        padding: 10px;
        cursor: pointer;

        img {
          width: 15px;
          margin-right: 10px;
        }
      }
    }
  }

  .textbox {
    display: flex;
    height: 100px;
    align-items: center;
    font-size: 16px;
    color: #0389f8;
    background-color: #fff;
    justify-content: space-between;
    text-align: center;
  }

  .btnbox {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    .btnitem {
      width: 100px;
      height: 35px;
      color: #0389f8;
      border: 1px solid #0389f8;
      font-size: 14px;
      text-align: center;
      line-height: 32px;
      background: #fff;
      border-radius: 3px;
      margin-left: 20px;
      cursor: pointer;
    }

    .skip {
      background-color: #0389f8;
      color: #fff;
      border: 1px solid #0389f8;
    }
  }
}

/deep/ .el-dialog__title,
/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #333 !important;
  font-size: 22px;
}

.pay-content {
  width: 100%;
  height: 100%;
  padding: 0 40px;

  .selection-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 40px;
    margin-bottom: 40px;

    .selection-item {
      width: 340px;
      height: 340px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: linear-gradient(275deg, #181b40 0%, #181a37 100%);
      box-shadow: -1px -1px 2px #1e213b, 3px 3px 3px #13162d;
      border-radius: 5px;
      gap: 40px;

      .title {
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        font-size: 26px;
        color: #ffffff;
      }

      .img {
        width: 135px;
        height: 135px;
      }

      /deep/ .el-input-number {
        width: 284px;
        height: 57px;
        line-height: 57px;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .el-input-number__decrease,
        .el-input-number__increase {
          width: 57px;
          height: 57px;
          background: linear-gradient(275deg, #181b40 0%, #181a37 100%);
          box-shadow: -3px -3px 3px #1e213b, 3px 3px 3px #13162d;
          border-radius: 10px;
          color: #ff009d;
          font-weight: bold;
          font-size: 28px;
          border: none;
          position: relative;
          z-index: 2;
        }

        .el-input {
          width: 120px;
          height: 57px;
          order: 2;
          border-radius: 10px;
          position: relative;

          .el-input__inner {
            border: none;
            background: linear-gradient(275deg, #181b40 0%, #181a37 100%);
            box-shadow: -3px -3px 3px #1e213b, 3px 3px 3px #13162d;
            height: 100%;
            font-family: Alibaba PuHuiTi;
            font-weight: 500;
            font-size: 22px;
            color: #ffffff;
            padding: 0;
          }
        }

        .el-input-number__decrease {
          order: 1;
        }

        .el-input-number__increase {
          order: 3;
        }
      }
    }
  }

  .pay-detail {
    width: 100%;
    height: 200px;
    background: linear-gradient(275deg, #181b40 0%, #181a37 100%);
    box-shadow: -1px -1px 2px #1e213b, 3px 3px 3px #13162d;
    border-radius: 5px;
    padding: 20px 16px;
    display: flex;
    align-items: center;

    .qrcode-wrapper {
      flex-shrink: 0;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;

      .pay-type-item {
        width: 170px;
        height: 64px;
        padding: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: -1px -1px 2px #1e213b, 3px 3px 3px #13162d;
        cursor: pointer;
        border-radius: 10px;

        .pay-icon {
          width: 38px;
          height: 38px;
          flex-shrink: 0;
          margin-right: 10px;
        }

        .text {
          display: inline-block;
          width: 98px;
          flex-shrink: 0;
          text-align: center;
          font-family: Alibaba PuHuiTi;
          font-weight: 500;
          font-size: 20px;
          color: #898db6;
          white-space: nowrap;
        }

        &.active {
          border: 1px solid #fc009b;

          .text {
            color: #fc009b;
          }
        }

        &.wxpay {
          .pay-icon {
            background: url('./img/pay/wx.png') no-repeat center/cover;
          }
        }

        &.alipay {
          .pay-icon {
            background: url('./img/pay/alipay.png') no-repeat center/cover;
          }
        }
      }
    }

    .qrcode-detail {
      position: relative;
      width: calc(100% - 137px - 20px);
      height: 100%;
      display: flex;
      justify-content: space-between;

      .left {
        width: calc(100% - 180px);
        position: relative;

        .tip {
          font-family: Alibaba PuHuiTi;
          font-size: 16px;
          color: #3e426c;
        }
      }

      .right {
        position: relative;
        width: 160px;
        height: 100%;
        margin-left: 20px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .top-tip {
          width: 100%;
          height: 20px;
          text-align: right;
          font-family: Alibaba PuHuiTi;
          font-size: 16px;
          color: #3e426c;
          position: absolute;
          top: 0;
          right: 0px;
        }

        .pay-button {
          width: 160px;
          height: 54px;
          background: linear-gradient(275deg, #ae0ef0 0%, #f81b9a 100%);
          box-shadow: -3px -3px 3px #1e213b;
          border-radius: 4px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: Alibaba PuHuiTi;
          font-weight: 500;
          font-size: 18px;
          color: #ffffff;
        }
      }

      .title {
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        font-size: 18px;
        color: #898db6;
        margin-bottom: 10px;
      }

      .money-num {
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        font-size: 44px;
        color: #ff009d;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
